import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import App, { store } from './App';
import { InitFirebase } from '@/components/push-notification';
import * as serviceWorker from '@/components/Global/serviceWorker';
import 'animate.css/animate.min.css';
import { Provider } from 'react-redux';
import { PostHogProvider } from 'posthog-js/react';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

// const root = ReactDOM.createRoot(document.getElementById('root'));

ReactDOM.render(
  <Provider store={store}>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}>
      <App />
    </PostHogProvider>

    {/* <App /> */}
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
InitFirebase();
