import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  listContainer: {
    overflow: 'auto',
  },
  cityListContainer: {
    border: '1px solid',
    padding: 2,
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'visible',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    '& h3': {
      whiteSpace: 'nowrap',
      marginRight: '10px',
    },
    [BP.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  list: {
    margin: '10px 0 10px 0',
    width: '100%',
    minWidth: 400,
    border: '1px solid #707070',
    '& th': {
      border: '1px solid #707070',
    },
    '& td': {
      border: '1px solid #707070',
      textAlign: 'center',
      fontSize: 15,
      '& input': {
        width: 150,
        fontSize: 14,
      },
    },
  },

  tableRow: {
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 61,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    margin: '10px 0',
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    color: '#43424b',
    textAlign: 'center',

    [BP.up('lg')]: {
      fontSize: 14,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },
  thead: {
    display: 'flex',
    fontFamily: 'Averta Bold',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.2,
    color: '#43424b',
    textAlign: 'center',
    alignItems: 'center',
    [BP.up('lg')]: {
      fontSize: 15,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },
  tbody: {
    // display: 'flex',
    // flexDirection: 'column',
    // width:'fit-content'
  },
  textInput: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #707070',
    marginTop: 5,
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '15px',
  },
  loader: {
    width: '25 !important',
    height: '25 !important',
    color: '#f8b100',
  },
  select: {
    width: '100%',
    marginBottom: '10px',
    fontFamily: 'Averta Light',
    '& > div': {
      border: 'solid 1px #707070',
    },
    '& > div:first-child > div': {
      padding: '0 0 0 10px',
    },
    '& > div:first-child > div > div': {
      padding: '8px 4px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  addRowBtn: {
    width: '100%',
    border: '1px solid',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: 15,
    color: '#ffffff',
    background: '#f8b100',
    borderRadius: 4,
    padding: '10px 15px',
    '&:hover': {
      color: '#ffffff',
      background: '#f8b100',
    },
  },
  cityTable: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    marginBottom: 10,
  },
  loc: {
    '& svg': {
      fill: '#f8b100',
    },

    fontFamily: 'Averta Light',
    width: '-webkit-fill-available',
    // marginLeft: 15,
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& > div': {
      border: 'solid 1px #707070',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '@media (max-width:900px)': {
      marginLeft: 0,
    },
  },
}));
