import React, { useEffect, useState, useRef } from 'react';
import { Button, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { CircularProgress } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import { Icons } from '@/assets';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { setUserSettings } from '@/actions';
import { useNotify, Loading } from 'react-admin';

export default ({
  title,
  DATA = [],
  options = {},
  setOptions,
  children,
  tableTheadTitle,
  tableType,
}) => {
  const cls = styles();
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();
  const optionsRef = useRef(null);
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setOptions({ ...options, open: false });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 35,
      height: 18,
      padding: 0,
      overflow: 'visible',
      margin: theme.spacing(0.8),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 16,
      height: 16,
    },
    track: {
      borderRadius: 26,
      // border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: '#d5d5d5',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const saveUserSettings = (data) => {
    if (!changedList || changedList.length === 0) {
      const req = {
        key: tableType,
        data: null,
      };

      setUserSettings(req)
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          console.log('posterr', err);
          notify(err, 'warning');
          setLoading(false);
        });

      return; // Exit the function if there are no changes
    }

    // Check if changedList is empty or unchanged
    if (!changedList || changedList.length === 0) {
      notify('No Changes...');
      setLoading(false);

      return; // Exit the function if there are no changes
    }

    const req = {
      key: tableType,
      data: changedList,
    };

    setUserSettings(req)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log('posterr', err);
        notify(err, 'warning');
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (options.open) $('.options-card').slideDown();
    else $('.options-card').slideUp();
  }, [options.open]);

  const Close = () => setOptions({ ...options, open: false });
  const Toggle = () => setOptions({ ...options, open: !options.open });
  const [items, setItems] = useState([]);
  const [changedList, setChangedList] = useState([...items]);
  const [draggedItem, setDraggedItem] = useState(null);
  useEffect(() => {
    setItems(tableTheadTitle);
  }, [tableTheadTitle]);

  const handleDragStart = (index) => {
    setDraggedItem(index);
  };

  const handleDragOver = (index) => {
    if (draggedItem === index) return;

    const newItems = [...items];
    const item = newItems[draggedItem];
    newItems.splice(draggedItem, 1);
    newItems.splice(index, 0, item);

    setDraggedItem(index);
    setItems(newItems);
    setChangedList(newItems);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  const toggleShow = (index) => {
    const newItems = items.map((item, i) =>
      i === index ? { ...item, show: !item.show } : item
    );
    setItems(newItems);
    setChangedList(newItems);
  };

  useEffect(() => {}, [changedList, items]);

  return (
    <div className={cls.filterContainer}>
      <div className={cls.headerBar}>
        <span className={cls.title}>{title}</span>

        <div className={cls.rocw3}>
          {children}
          <div className={cls.filterRow} onClick={Toggle}>
            <span className={cls.title}></span>
            <ReactSVG src={Icons.options} className={cls.filter} />
          </div>
        </div>
      </div>
      <div
        ref={optionsRef}
        className={clsx(cls.card, 'options-card')}
        style={{ display: 'none' }}>
        {/* <Tooltip arrow title="Close" placement="top">
          <CloseRounded className={cls.close} onClick={Close} />
        </Tooltip> */}
        <Grid className={cls.optionsBody} container spacing={2}>
          <p className={cls.cardTitle}>Configure Columns</p>
          {/* <div className={cls.searchBox}>
            <BaseIcon src={memberIcons.search} className={cls.searchIcon} />
            <input
              className={cls.searchInput}
              type="text"
              placeholder="Serach by item..."
            />
          </div> */}
          <p className={cls.itemsTopTitle}>Shown attribute</p>
          <span className={cls.listSpan}>
            {items.map((header, index) => (
              <span
                key={index}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={() => handleDragOver(index)}
                onDragEnd={handleDragEnd}
                className={cls.actionsSpan}>
                <span className={cls.dragdropIconTitle}>
                  <ReactSVG src={Icons.dragdrop} className={cls.dragdropIcon} />
                  {header?.label}
                </span>
                <IOSSwitch
                  checked={header?.show}
                  onChange={(ev) => toggleShow(index)}
                  name="checkedShow"
                />
              </span>
            ))}
          </span>
          {/* <p className={cls.itemsTopTitle}>Hidden attribute</p>

          <span className={cls.actionsSpan}>
            <span className={cls.dragdropIconTitle}>
              <ReactSVG src={Icons.dragdrop} className={cls.dragdropIcon} />
              Chassis#
            </span>
            <CustomSwitch
              // className={
              //   foundObject?.relation_user && foundObject?.relation_pass
              //     ? cls.Icons
              //     : cls.hiddenIcons
              // }
              checked={true}
              // onChange={(ev) =>
              //   changeFormData('enable', ev.target.checked, index, item.id)
              // }
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </span> */}
        </Grid>

        <div className={cls.row2}>
          {loading === true ? (
            <div className={cls.loading}>
              <CircularProgress />
            </div>
          ) : (
            <span className={cls.btnsSpan}>
              <Button
                className={cls.but}
                onClick={() => {
                  setLoading(true);

                  saveUserSettings();
                }}>
                Save
              </Button>
              <Button
                className={cls.but}
                onClick={() => {
                  setLoading(true);
                  saveUserSettings('null');
                }}>
                Restore Default
              </Button>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
