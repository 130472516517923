import { _get, genSize, fDate2 } from '@/actions/Helper';

export const ParseRequest = (data = []) =>
  data.map((i) =>
    i.type === 'manual'
      ? {
          id: _get(i, 'id', ''),
          type: _get(i, 'type', 'manual'),
          mode: _get(i, 'mode', ''),
          num: _get(i, 'requested_count', 0),
          date: _get(i, 'created_at', ''),
          loc: _get(i, 'inventory.address.city', ''),
          size: genSize(_get(i, 'inventory.cabin.specifications', {})),
          ssl: _get(i, 'inventory.cabin.company.name', ''),
          in: _get(i, 'inprogress_count', 0),
          acc: _get(i, 'accepted_count', 0),
          stat: _get(i, 'status_string', ''),
          user: _get(i, 'inventory.user.name', ''),
          company: _get(i, 'inventory.user.company', ''),
          avatar: _get(i, 'inventory.user.image', ''),
        }
      : {
          id: _get(i, 'id', ''),
          mode: _get(i, 'mode', ''),
          type: _get(i, 'type', 'smart'),
          num: _get(i, 'count', 0),
          date: _get(i, 'created_at', ''),
          loc: _get(i, 'address.city', ''),
          size: genSize(_get(i, 'cabin.specifications', {})),
          ssl: _get(i, 'cabin.company.name', ''),
          in: _get(i, 'inprogress_count', 0),
          acc: _get(i, 'accepted_count', 0),
          stat: _get(i, 'status_string', ''),
        }
  );

export const ParseRequestInComplete = (data = []) =>
  data.map((i) =>
    i.type === 'manual'
      ? {
          id: _get(i, 'id', ''),
          stat: _get(i, 'status_string', ''),
          mode: _get(i, 'mode', ''),
          count: _get(i, 'requested_count', 0),
          date: _get(i, 'created_at', ''),
          loc: _get(i, 'inventory.address.city', ''),
          size: genSize(_get(i, 'inventory.cabin.specifications', {})),
          ssl: _get(i, 'inventory.cabin.company.name', ''),
          num: _get(i, 'accepted_count', 0),
          user: _get(i, 'inventory.user.name', ''),
          company: _get(i, 'inventory.user.company', ''),
          avatar: _get(i, 'inventory.user.image', ''),
        }
      : {
          id: _get(i, 'id', ''),
          stat: _get(i, 'status_string', ''),
          count: _get(i, 'count', 0),
          date: _get(i, 'created_at', ''),
          loc: _get(i, 'address.city', ''),
          size: genSize(_get(i, 'cabin.specifications', {})),
          ssl: _get(i, 'cabin.company.name', ''),
          num: _get(i, 'accepted_count', 0),
          user: _get(i, 'inventory.user.name', ''),
          company: _get(i, 'inventory.user.company', ''),
          avatar: _get(i, 'inventory.user.image', ''),
        }
  );

export const ParseRequestComplete = (data = []) =>
  data.map((i) => ({
    id: _get(i, 'id', ''),
    stat: _get(i, 'status_string', ''),
    mode: _get(i, 'mode', ''),
    req: _get(i, 'requested_count', 0),
    date: _get(i, 'created_at', ''),
    loc: _get(i, 'inventory.address.city', ''),
    size: genSize(_get(i, 'inventory.cabin.specifications', {})),
    ssl: _get(i, 'inventory.cabin.company.name', ''),
    num: _get(i, 'accepted_count', 0),
    user: _get(i, 'inventory.user.name', ''),
    company: _get(i, 'inventory.user.company', ''),
    avatar: _get(i, 'inventory.user.image', ''),
    seller_approve: _get(i, 'seller_approve', ''),
    buyer_approve: _get(i, 'buyer_approve', ''),
    buyer_confirm: _get(i, 'buyer_confirm', ''),
    seller_confirm: _get(i, 'seller_confirm', ''),
  }));

export const ParseOrder = (data = []) =>
  data.map((i) => ({
    id: _get(i, 'id', ''),
    mode: _get(i, 'mode', ''),
    size: genSize(_get(i, 'inventory_with_out_user.cabin.specifications', {})),
    ssl: _get(i, 'inventory_with_out_user.cabin.company.name', ''),
    date: _get(i, 'inventory_with_out_user.available_at', ''),
    loc: _get(i, 'inventory_with_out_user.address.city', ''),
    num: _get(i, 'accepted_count', 0),
    req: _get(i, 'requested_count', 0),
    stat: _get(i, 'status_string', 0),
    update: _get(i, 'updated_at', ''),
    user: _get(i, 'user.name', ''),
    company: _get(i, 'user.company', ''),
    avatar: _get(i, 'user.image', ''),
    own_chases_count: _get(i, 'inventory_with_out_user.own_chases_count', ''),
    pool_chases_count: _get(i, 'inventory_with_out_user.pool_chases_count', ''),
    pool_available: _get(i, 'inventory_with_out_user.pool_available', ''),
    own_available: _get(i, 'inventory_with_out_user.own_available', ''),
    requested_count: _get(i, 'requested_count', ''),
    count_available: _get(i, 'inventory_with_out_user.count_available', ''),
    chase_type: _get(i, 'chase_type', ''),
  }));

export const ParseOrderComplete = (data = []) =>
  data.map((i) => ({
    id: _get(i, 'id', ''),
    req: _get(i, 'requested_count', 0),
    mode: _get(i, 'mode', 0),
    date: _get(i, 'created_at', ''),
    loc: _get(i, 'inventory_with_out_user.address.city', ''),
    size: genSize(_get(i, 'inventory_with_out_user.cabin.specifications', {})),
    stat: _get(i, 'status_string', ''),
    ssl: _get(i, 'inventory_with_out_user.cabin.company.name', ''),
    num: _get(i, 'inprogress_count', 0),
    acc: _get(i, 'accepted_count', 0),
    user: _get(i, 'user.name', ''),
    company: _get(i, 'user.company', ''),
    avatar: _get(i, 'user.image', ''),
    update: _get(i, 'updated_at', ''),
    seller_approve: _get(i, 'seller_approve', ''),
    buyer_approve: _get(i, 'buyer_approve', ''),
    buyer_confirm: _get(i, 'buyer_confirm', ''),
    seller_confirm: _get(i, 'seller_confirm', ''),
  }));

export const ParseOrderInComplete = (data = []) =>
  data.map((i) => ({
    id: _get(i, 'id', ''),
    count: _get(i, 'requested_count', 0),
    mode: _get(i, 'mode', 0),
    date: _get(i, 'created_at', ''),
    loc: _get(i, 'inventory_with_out_user.address.city', ''),
    size: genSize(_get(i, 'inventory_with_out_user.cabin.specifications', {})),
    stat: _get(i, 'status_string', ''),
    ssl: _get(i, 'inventory_with_out_user.cabin.company.name', ''),
    num: _get(i, 'inprogress_count', 0),
    chase_type: _get(i, 'chase_type', 0),
    user: _get(i, 'user.name', ''),
    company: _get(i, 'user.company', ''),
    avatar: _get(i, 'user.image', ''),
    update: _get(i, 'updated_at', ''),
    acc: _get(i, 'accepted_count', 0),
  }));

export const ParseMarketPlace = (data = []) =>
  data.map((i) => ({
    id: _get(i, 'id', ''),
    size: genSize(_get(i, 'cabin.specifications', {})),
    ssl: _get(i, 'cabin.company.name', ''),
    date: _get(i, 'updated_at', ''),
    loc: _get(i, 'address.city', ''),
    num: _get(i, 'count_available', 0),
    max_num: _get(i, 'count_available', 0),
    has_containers: _get(i, 'has_containers', ''),
    container_numbers: _get(i, 'container_numbers', ''),
    count_available: _get(i, 'count_available', ''),
    count: _get(i, 'count', ''),
    own_chases_count: _get(i, 'own_chases_count', ''),
    pool_chases_count: _get(i, 'pool_chases_count', ''),
    pool_available: _get(i, 'pool_available', ''),
    own_available: _get(i, 'own_available', ''),
    flip_available: _get(i, 'flip_available', ''),
  }));

export const ParseMarketPlaceExport = (data = []) =>
  data.map((i) => ({
    id: _get(i, 'id', ''),
    size: genSize(_get(i, 'cabin.specifications', {})),
    ssl: _get(i, 'cabin.company.name', ''),
    date: _get(i, 'updated_at', ''),
    loc: _get(i, 'address.city', ''),
    num: _get(i, 'count', 0),
    max_num: _get(i, 'count_available', 0),
    has_containers: _get(i, 'has_containers', ''),
    container_numbers: _get(i, 'container_numbers', ''),
    count_available: _get(i, 'count_available', ''),
    count: _get(i, 'count', ''),
    wanna_own: _get(i, 'wanna_own', ''),
    wanna_pool: _get(i, 'wanna_pool', ''),
    pool_available: _get(i, 'pool_available', ''),
    own_available: _get(i, 'own_available', ''),
    flip_available: _get(i, 'flip_available', ''),
  }));

export const ParseMarketPlaceForm = (form = {}) => {
  let reqForm = {
    count: _get(form, 'count', ''),
    count_available: form.count_available
      ? _get(form, 'count_available', '')
      : _get(form, 'count', ''),
    date: fDate2(form.date),
    pre: _get(form, 'prefrence', ''),
    lat: _get(form, 'loc.value.lat', ''),
    long: _get(form, 'loc.value.lng', ''),
    address: _get(form, 'loc.value.address', ''),
    city: _get(form, 'loc.value.city', ''),
    size: _get(form, 'specification_id.value', ''),
    ssl: _get(form, 'company_id.value', ''),
  };
  if (form.chassis.own && !form.chassis.pool) {
    reqForm.chases = 'own';
  }
  if (!form.chassis.own && form.chassis.pool) {
    reqForm.chases = 'pool';
  }
  return reqForm;
};

export const ParseInventoryList = (data = []) =>
  data.map((i) => ({
    id: _get(i, 'id', 0),
    address: _get(i, 'address', 0),
    size: genSize(_get(i, 'cabin.specifications', {})),
    ssl: _get(i, 'cabin.company.name', ''),
    active: _get(i, 'count', 0),
    count_all: _get(i, 'count_all', 0),
    containers: _get(i, 'containers', 0),
    loc: _get(i, 'address.city', ''),
    date: _get(i, 'available_at', ''),
    up: _get(i, 'updated_at', ''),
    created: _get(i, 'created_at', ''),
    stat: _get(i, 'status', ''),
    company: _get(i, 'user_company.company', ''),
    own_chases_count: _get(i, 'own_chases_count', ''),
    pool_chases_count: _get(i, 'pool_chases_count', ''),
    flip_available: _get(i, 'flip_available', ''),
    count_available: _get(i, 'count_available', ''),
    chases: _get(i, 'chases', ''),
    status: _get(i, 'status', ''),
  }));

export const ParseExportInventoryList = (data = []) =>
  data.map((i) => ({
    id: _get(i, 'id', 0),
    address: _get(i, 'address', 0),
    size: genSize(_get(i, 'cabin.specifications', {})),
    ssl: _get(i, 'cabin.company.name', ''),
    active: _get(i, 'count', 0),
    count_all: _get(i, 'count_all', 0),
    bookings: _get(i, 'bookings', 0),
    loc: _get(i, 'address.city', ''),
    date: _get(i, 'wanna_till', ''),
    up: _get(i, 'updated_at', ''),
    created: _get(i, 'created_at', ''),
    stat: _get(i, 'status', ''),
    company: _get(i, 'user_company.company', ''),
    own_chases_count: _get(i, 'wanna_own', ''),
    pool_chases_count: _get(i, 'wanna_pool', ''),
    flip_available: _get(i, 'flip_available', ''),
    count_available: _get(i, 'count_available', ''),
    chases: _get(i, 'chases', ''),
    status: _get(i, 'status', ''),
  }));

// export const ParseSmartMatchList = (data = []) =>
//   data.map((i) => ({
//     id: _get(i, 'id', 0),
//     // address: _get(i, 'address', 0),
//     size: genSize(_get(i, 'cabin.specifications', {})),
//     ssl: _get(i, 'cabin.company.name', ''),
//     active: _get(i, 'count', 0),
//     count_all: _get(i, 'count_all', 0),
//     containers: _get(i, 'containers', 0),
//     loc: _get(i, 'address.city', ''),
//     date: _get(i, 'available_at', ''),
//     up: _get(i, 'updated_at', ''),
//     created: _get(i, 'created_at', ''),
//     stat: _get(i, 'status', ''),
//     company: _get(i, 'user_company.company', ''),
//     own_chases_count: _get(i, 'own_chases_count', ''),
//     pool_chases_count: _get(i, 'pool_chases_count', ''),
//     flip_available: _get(i, 'flip_available', ''),
//     count_available: _get(i, 'count_available', ''),
//     chases: _get(i, 'chases', ''),
//     status: _get(i, 'status', ''),
//   }));
export const ParseCompletedInventoryList = (data = []) =>
  data.map((i) => ({
    id: _get(i, 'id', 0),
    address: _get(i, 'inventory.address', 0),
    size: genSize(_get(i, 'inventory.cabin.specifications', {})),
    ssl: _get(i, 'inventory.cabin.company.name', ''),
    active: _get(i, 'accepted_count', 0),
    count_all: _get(i, 'inventory.count_all', 0),
    containers: _get(i, 'inventory.containers', 0),
    loc: _get(i, 'inventory.address.city', ''),
    date: _get(i, 'inventory.created_at', ''),
    up: _get(i, 'updated_at', ''),
    stat: _get(i, 'inventory.status', ''),
    company: _get(i, 'inventory.user_company.company', ''),
    sellerCompany: _get(i, 'company_in', ''),
    buyerCompany: _get(i, 'company_out', ''),
  }));

export const ParseConfirmedInventoryList = (data = []) =>
  data.map((i) => ({
    id: _get(i, 'id', 0),
    address: _get(i, 'inventory.address', 0),
    size: genSize(_get(i, 'inventory.cabin.specifications', {})),
    ssl: _get(i, 'inventory.cabin.company.name', ''),
    active: _get(i, 'accepted_count', 0),
    count_all: _get(i, 'inventory.count_all', 0),
    containers: _get(i, 'inventory.containers', 0),
    loc: _get(i, 'inventory.address.city', ''),
    date: _get(i, 'inventory.created_at', ''),
    up: _get(i, 'updated_at', ''),
    stat: _get(i, 'inventory.status', ''),
    company: _get(i, 'inventory.user_company.company', ''),
    sellerCompany: _get(i, 'company_in', ''),
    buyerCompany: _get(i, 'company_out', ''),
  }));

export const ParseRequestDetail = (i = {}) => ({
  id: _get(i, 'id', ''),
  req: _get(i, 'requested_count', 0),
  date: _get(i, 'created_at', ''),
  loc: _get(i, 'inventory.address.city', ''),
  size: genSize(_get(i, 'inventory.cabin.specifications', {})),
  ssl: _get(i, 'inventory.cabin.company.name', ''),
  acc: _get(i, 'accepted_count', 0),
  in: _get(i, 'inprogress_count', 0),
  com: _get(i, 'completed_count', 0),
});

export const ParseAdminMembers = (i = {}) => ({
  id: _get(i, 'id', ''),
  image: _get(i, 'image', ''),
  name: _get(i, 'name', ''),
  email: _get(i, 'email', ''),
  phone: _get(i, 'phone', ''),
  client: _get(i, 'client', 'no_data'),
  password: _get(i, 'password', 'no_data'),
  status: _get(i, 'status', 'no_data'),
});
