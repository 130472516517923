import { BaseIcon } from '@/Utils';
import React from 'react';
import styles from './styles';
import { MemberIcons as memberIcons } from '@/assets';
import { CircularProgress } from '@material-ui/core';
import AIListCard from '@/components/AI/AIListCard';

export default ({
  state,
  handleSelectUser,
  searchingChat,
  selectedUser,
  setSearchingChat,
}) => {
  const cls = styles();
  return !state.loading ? (
    <div className={cls.listContainer}>
      <div className={cls.searchBox}>
        <BaseIcon src={memberIcons.search} className={cls.searchIcon} />
        <input
          className={cls.searchInput}
          type="text"
          placeholder="Search..."
          onChange={(e) => setSearchingChat(e.target.value)}
        />
      </div>
      <div>
        {state.DATA.map((i, idx) => {
          if (
            !searchingChat ||
            (searchingChat &&
              i.from_name.toUpperCase().includes(searchingChat.toUpperCase()))
          ) {
            return (
              <AIListCard
                DATA={i}
                key={idx}
                selectedUser={selectedUser}
                Chat={handleSelectUser}
              />
            );
          }
        })}
      </div>
    </div>
  ) : (
    <div className={cls.loading}>
      <CircularProgress />
    </div>
  );
};
