import React, { useState } from 'react';
import styles from './styles';
import Select from 'react-select';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { useOutsideAlerter } from '@/hocs';
import { Modal, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import closeIcon from '../../assets/img/closeIcon.svg';

export default ({
  companies,
  children,
  Close,
  selectedCompany,
  setSelectedCompany,
  showCompanies = true,
}) => {
  const cls = styles();

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    Close();
  });

  return (
    <>
    <div>
    <Modal >
      <div
        id="app_modal"
        className={cls.modal}
        onClick={(e) =>
          e.target.id === 'app_modal' && Close
        }
        style={{ width: '100%', height: '100%' }}>
        <Box className={cls.modalBox}>
          <div
            onClick={Close}
            className={cls.closeModal}>
            <img alt="close_icon" src={closeIcon} />
          </div>
          <h3 className={cls.modalTitle}>Container Availability</h3>
          <div className={cls.containersTableContainer}>

          </div>
          <button
            className={cls.updateContainersBtn}
            onClick={Close}>
            Close
          </button>
        </Box>
      </div>
    </Modal>
  </div>

    {/* <div className={cls.popup}>
      <div ref={wrapperRef} className={cls.popUpCard}>
        <Tooltip arrow title="Close" placement="top">
          <CloseRounded className={cls.close} onClick={Close} />
        </Tooltip>
        {showCompanies && (
          <Select
            placeholder="Company"
            className={clsx(cls.select, cls.ml15)}
            value={selectedCompany}
            // onChange={(item) => setSelectedCompany(item)}
            // options={companies}
          />
        )}
        {children}
      </div>
    </div> */}
    </>
  );
};
