// in src/App.js
import React, { useEffect, useState } from 'react';
import { createHashHistory } from 'history';
import { useHistory } from 'react-router-dom';

import { Admin, Resource } from 'react-admin';
import { useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import SC from '@/screens';
import { Layout, LogOutButton } from '@/components/Layout';
import { authProvider, dataProvider, customRoutes, theme } from '@/Providers';
import createAdminStore from '@/actions/store';
import { checkUpdate, getInit, updateSamrtApp } from '@/actions';
import ReactGA from 'react-ga';
import RouteChangeTracker from './components/RouteChangeTracker';
import styles from './styles';
import UpdatePopup from './components/UpdatePopup';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_CODE; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

ReactGA.initialize(TRACKING_ID, {
  // debug: true,
  // testMode: true,
  titleCase: true,
  gaOptions: {
    userId: localStorage.userID,
    clientId: localStorage.userID,
  },
});

ReactGA.send('pageview');

getInit();
const history = createHashHistory();

export const store = createAdminStore({
  authProvider,
  dataProvider,
  history,
});

const AdminResources = [
  <Resource name="dashboard" list={SC.Dashboard} />,
  <Resource name="members" list={SC.Members} />,
  <Resource name="content" list={SC.AdminInventory} />,
  <Resource name="update" list={SC.Update} />,
  // <Resource name="content/inventory" list={SC.AdminInventory} />,
  // <Resource name="content/completed" list={SC.AdminCompleted} />,
  <Resource name="chat" list={SC.Chat} />,
  <Resource name="finance" list={SC.Finance} />,
  <Resource name="integration" list={SC.Integration} />,
  <Resource name="logs" list={SC.Logs} />,
];
const UserResources = [
  <Resource name="dashboard" list={SC.Dashboard} />,
  <Resource name="search" list={SC.Search} />,
  <Resource name="requests" list={SC.Request} />,
  <Resource name="orders" list={SC.Order} />,
  <Resource name="inventory" list={SC.Inventory} />,
  <Resource name="report" list={SC.Report} />,
  <Resource name="members" list={SC.Members} />,
  <RouteChangeTracker />,
];
export default () => {
  const [updateApp, setUpdateApp] = useState(false);
  const [updateNotification, setUpdateNotification] = useState({
    title: 'We Have a New Update Available!',
    message: '',
  });
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  document.addEventListener('keydown', (event) => {
    // F12 key
    if (event.key === 'F12') {
      event.preventDefault();
    }

    // Ctrl+Shift+I or Cmd+Option+I (for Mac)
    if (
      (event.ctrlKey && event.shiftKey && event.key === 'I') ||
      (event.metaKey && event.altKey && event.key === 'I')
    ) {
      event.preventDefault();
    }
  });
  // let history = useHistory();

  // (function () {
  //   let devtoolsDetected = false;

  //   const detectDevTools = () => {
  //     if (devtoolsDetected) {
  //       window.location.hash = ''; // Clear the URL hash
  //     }
  //   };

  //   // Method 1: Monitor `console` properties
  //   const checkConsole = () => {
  //     const element = new Image();
  //     Object.defineProperty(element, 'id', {
  //       get: function () {
  //         devtoolsDetected = true;
  //         detectDevTools();
  //       },
  //     });

  //     console.dir(element); // Triggers the getter if DevTools is open
  //   };

  //   setInterval(checkConsole, 1000);

  //   // Method 2: Monitor window resizing (Detect DevTools opened in different modes)cc
  //   const checkResize = () => {
  //     const widthThreshold = window.outerWidth - window.innerWidth > 100;
  //     const heightThreshold = window.outerHeight - window.innerHeight > 100;

  //     if (widthThreshold || heightThreshold) {
  //       devtoolsDetected = true;

  //       detectDevTools();
  //     }
  //   };

  //   window.addEventListener('resize', checkResize);

  //   // Method 3: Detect focus/blur changes
  //   const checkFocus = () => {
  //     if (document.hasFocus() === false) {
  //       setTimeout(() => {
  //         if (document.hasFocus() === false && !devtoolsDetected) {
  //           devtoolsDetected = true;
  //           detectDevTools();
  //         }
  //       }, 100);
  //     }
  //   };

  //   window.addEventListener('blur', checkFocus);
  // })();

  // document.addEventListener('contextmenu', (event) => {
  //   event.preventDefault();
  // });

  const updateNotif = useSelector((state) =>
    state.reducer.notif ? state.reducer.notif.update : null
  );
  const user = useSelector((state) => state.reducer.user);

  const notify = useNotify();
  const cls = styles();

  let token = localStorage.getItem('token');

  const checkUpdateApp = () => {
    if (token) {
      checkUpdate().then((res) => {
        if (res.update === 1) {
          setUpdateApp(true);
        }
        if (res.notification) {
          setUpdateNotification(res.notification);
        }
      });
    }
  };

  const update = () => {
    setLoadingUpdate(true);
    updateSamrtApp()
      .then((res) => {
        setLoadingUpdate(false);
        notify('App Successfully Updated', 'success');
        window.location.reload(true);
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    checkUpdateApp();
  }, []);

  useEffect(() => {
    checkUpdateApp();
  }, [token]);

  useEffect(() => {
    if (updateNotif && token) {
      setUpdateApp(true);
      setUpdateNotification({
        title: updateNotif.title,
        message: updateNotif.body,
      });
    }
  }, [updateNotif]);

  return (
    <>
      {updateApp && (
        <UpdatePopup
          updateNotification={updateNotification}
          update={update}
          loadingUpdate={loadingUpdate}
        />
        // <div className={cls.updatePopup}>
        //   <div className={cls.popupCard}>
        //     <p className={cls.updateText}>{updateNotification.title}</p>
        //     <div>
        //       {
        //         <div
        //           dangerouslySetInnerHTML={{
        //             __html: updateNotification.message,
        //           }}
        //         />
        //       }
        //     </div>
        //     <button onClick={() => update()} className={cls.updateBtn}>
        //       {loadingUpdate ? (
        //         <CircularProgress size={20} color="#ffffff" />
        //       ) : (
        //         'Update Now'
        //       )}
        //     </button>
        //   </div>
        // </div>
      )}
      <Admin
        theme={theme}
        authProvider={authProvider}
        customRoutes={customRoutes}
        dataProvider={dataProvider}
        loginPage={SC.Login}
        logoutButton={LogOutButton}
        layout={Layout}
        history={history}>
        {(permissions) => [
          permissions === 'admin' ? AdminResources : UserResources,
        ]}
      </Admin>
    </>
  );
};
