import React, { useEffect, useState } from 'react';
import { Modal, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import styles from '../styles';
import {
  disableAdminInventoryContainer,
  enableAdminInventoryContainer,
} from '@/actions';
import { useNotify } from 'react-admin';
import closeIcon from '../../../assets/img/closeIcon.svg';
import { fDate } from '../../../actions/Helper';

export default ({
  DATA = [],
  open,
  selectedRow,
  setShowContainersModal,
  getInventoryList,
}) => {
  const cls = styles();
  const notify = useNotify();
  const [containers, setContainers] = useState(selectedRow.containers);

  const updateContainers = (index, name, value) => {
    setContainers((prev) => {
      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const handleEnableCongtainer = (containerId, index) => {
    enableAdminInventoryContainer(containerId).then((response) => {
      if (response.success) {
        notify('container updated');
        getInventoryList();
        updateContainers(index, 'status', 1);
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleDisableContainer = (containerId, index) => {
    disableAdminInventoryContainer(containerId).then((response) => {
      if (response.success) {
        notify('container updated');
        getInventoryList();
        updateContainers(index, 'status', 0);
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleChangeContainerStatus = (value, containerId, index) => {
    if (value === true) {
      handleEnableCongtainer(containerId, index);
    } else {
      handleDisableContainer(containerId, index);
    }
  };

  const getInventoryContainers = () => {
    let inventoryContainers = [];
    for (let index = 0; index < selectedRow.containers.length; index++) {
      if (
        selectedRow.containers[index].status === 1 ||
        selectedRow.containers[index].status === 0
      ) {
        inventoryContainers.push({
          name: selectedRow.containers[index]
            ? selectedRow.containers[index].name
            : '',
          chases: selectedRow.containers[index]
            ? selectedRow.containers[index].chases
            : '',
          id: selectedRow.containers[index]
            ? selectedRow.containers[index].id
            : null,
          status: selectedRow.containers[index]
            ? selectedRow.containers[index].status
            : null,
            lastUpdate: selectedRow.containers[index]
            ? selectedRow.containers[index].updated_at
            : null,
            scrpLast: selectedRow.containers[index]
            ? selectedRow.containers[index].scr_last
            : null,
        
        });
      }
    }
    setContainers(inventoryContainers);
  };

  useEffect(() => {
    getInventoryContainers();
  }, []);
  console.log('containers', containers, DATA);

  const gatEditContainersContent = () => {
    return (
      <table className={cls.containerTable}>
        <thead>
          <tr>
            <th style={{ flex: '0.3' }}>#</th>
            <th style={{ flex: '1' }}>Container#</th>
            <th style={{ flex: '1' }}>Chassis#</th>
            <th style={{ flex: '1' }}>Last Update</th>
            <th style={{ flex: '1' }}>Last Scrape</th>
            <th style={{ flex: '0.3' }}>Status</th>
          </tr>
        </thead>
        <tbody>
          {containers.map((container, index) => {
            return (
              <tr key={index}>
                <td style={{ flex: '0.3' }}>{index + 1}</td>
                <td style={{ flex: '1' }}>
                  <span>
                    {container.name ? container.name : 'Not Applicable'}
                  </span>
                </td>
                <td style={{ flex: '1' }}>
                  <span>
                    {container.chases ? container.chases : 'Not Applicable'}
                  </span>
                </td>
                <td style={{ flex: '1' }}>
                  <span>
                    {container.lastUpdate ? `${fDate(container.lastUpdate)}` : '-'}
                  </span>
                </td>
                <td style={{ flex: '0.9' }}>
                  <span>
                    {container.scrpLast ? `${fDate(container.scrpLast)}` : '-'}
                  </span>
                </td>
                <td style={{ flex: '0.3' }}>
                  {container.status !== null && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={container.status === 1 ? true : false}
                          className={cls.checkbox}
                          onChange={(e) =>
                            handleChangeContainerStatus(
                              e.target.checked,
                              container.id,
                              index
                            )
                          }
                        />
                      }
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <Modal open={open}>
        <div
          id="app_modal"
          className={cls.modal}
          onClick={(e) =>
            e.target.id === 'app_modal' && setShowContainersModal(false)
          }
          style={{ width: '100%', height: '100%' }}>
          <Box className={cls.modalBox}>
            <div
              onClick={() => setShowContainersModal(false)}
              className={cls.closeModal}>
              <img alt="close_icon" src={closeIcon} />
            </div>
            <h3 className={cls.modalTitle}>Container Availability</h3>
            <div className={cls.containersTableContainer}>
              {gatEditContainersContent()}
            </div>
            <button
              className={cls.updateContainersBtn}
              onClick={() => setShowContainersModal(false)}>
              Close
            </button>
          </Box>
        </div>
      </Modal>
    </div>
  );
};
