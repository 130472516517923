import React, { useEffect, useState } from 'react';
import { Loading } from 'react-admin';
import {
  Button,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { addInventory } from '@/actions';
import DateInput from '@/components/DateInput';
import { locationOpt } from '@/actions/Helper';
import { debounce } from 'lodash';
import { TrendingFlatRounded } from '@material-ui/icons';

import styles from './styles';
import { boolean } from 'yup';

export default ({ notify, setData, getInventoryList, DATADefault }) => {
  const cls = styles();
  const [loading, setLoading] = React.useState(false);
  const [showContainerNames, setShowContainerNames] = useState(true);
  const selectOp = useSelector((state) => state.reducer);
  const [form, setFormData] = React.useState({
    size: null,
    ssl: null,
    date: new Date(),
    count: '',
    loc: null,
    containers: [],
  });
  const [showContainers, setShowContainers] = React.useState(false);

  useEffect(() => {
    let isShowContainers = localStorage.getItem('smart_user_show_containers');
    let isShowContainerNames = localStorage.getItem(
      'smart_user_show_container_names'
    );
    if (isShowContainers === 'true') {
      setShowContainers(true);
    }
    if (isShowContainerNames === 'false') {
      setShowContainerNames(false);
    }
  }, []);

  const setForm = (it, val) => setFormData({ ...form, [it]: val });

  const Add = () => {
    let isFillAllContainers = true;
    let emptyContainers = form.containers.filter(
      (container) => !container.name || !container.chases
    );
    if (!showContainers || (showContainers && emptyContainers.length === 0)) {
      for (let el in form) {
        if (!form[el]) {
          notify('Can not add, Please check fields.', 'warning');
          return;
        }
      }

      setLoading(true);

      addInventory({
        ...form,
      })
        .then((res) => {
          notify('Added Successfully.');
          getInventoryList();
          setLoading(false);
        })
        .catch((err) => {
          notify(err, 'warning');
          setData([]);
          setLoading(false);
        });
    } else {
      notify('please fill all containers and chassis');
    }
  };

  const Reset = () =>
    setFormData({
      size: null,
      ssl: null,
      date: new Date(),
      count: '',
      loc: null,
      containers: [],
    });

  const setFormContainers = () => {
    let containers = [];
    for (let i = 0; i < form.count; i++) {
      if (form.containers[i]) {
        containers.push(form.containers[i]);
      } else {
        containers.push('');
      }
    }
    setForm('containers', containers);
  };

  useEffect(() => {
    setFormContainers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.count]);

  if (loading) return <Loading className={cls.loading} />;

  const [selectedSSL, setSelectedSSL] = useState(null); // New state for selected SSL

  const filterDataBySSL = (data, ssl) => {
    if (!ssl) return data; // Return all data if no SSL is selected
    return data.filter((item) => item.ssl === ssl.value); // Assuming `value` contains the relevant data
  };

  // Filter the data
  const filteredData = filterDataBySSL(DATADefault, selectedSSL);

  return (
    // <div id='emptyCard' className={cls.card}>
    //   <Grid container spacing={2}>
    //   <Grid item xs={12} sm={4} lg={3}>
    //       <Select
    //         placeholder="SSL"
    //         className={clsx(cls.select)}
    //         value={form.ssl}
    //         onChange={(item) => {
    //           setForm('ssl', item);
    //           setSelectedSSL(item);  // Set selected SSL state
    //         }}
    //         options={selectOp.ssl}
    //       />
    //     </Grid>
    //     <Grid item xs={12} sm={4} lg={3}>
    //       <Select
    //         placeholder="Size"
    //         className={cls.select}
    //         value={form.size}
    //         onChange={(item) => setForm('Size', item)}
    //         options={selectOp.size}
    //       />
    //     </Grid>
    //     <Grid item xs={12} sm={4} lg={3}>
    //       <DateInput
    //         placeholder="Available date"
    //         className={cls.date}
    //         format="MM/dd/yyyy"
    //         value={form.date}
    //         onChange={(item) => setForm('date', item)}
    //       />
    //     </Grid>
    //     <Grid className={cls.searchbtn} item xs={12} sm={4} lg={3}>
    //     {/* <Button
    //       className={cls.find}
    //       endIcon={<TrendingFlatRounded className={cls.findIcon} />}
    //       // onClick={Detail}
    //       >
    //       Search
    //     </Button> */}
    //     </Grid>

    //   </Grid>
    //   <div className={cls.actions}>
    //     <div className={cls.buttons}>
    //       {/* <Button className={clsx(cls.but, cls.red)} onClick={Reset}>
    //         Reset
    //       </Button> */}

    //     </div>
    //   </div>
    // </div>
    <></>
  );
};
