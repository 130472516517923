import React, { useEffect } from 'react';
import { Loading } from 'react-admin';
import { Button, TextField, Tooltip, Grid } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';

import { locationOpt, _get } from '@/actions/Helper';
import DateInput from '@/components/DateInput';

import styles from './editStyles';
import { updateInventoriContainers } from '@/actions';

export default ({ Update, Close, data }) => {
  const cls = styles();
  const [loading, setLoading] = React.useState(false);
  const Sizes = useSelector((state) => state.reducer.size);
  const SSLs = useSelector((state) => state.reducer.ssl);

  const parseData = ({ id, size, ssl, date, count_all, address }) => ({
    id,
    date,
    count: count_all,
    size: Sizes.find(({ label }) => label === size),
    ssl: SSLs.find(({ label }) => label === ssl),
    loc: {
      '& svg': {
        fill: '#f8b100',
      },

      label: _get(address, 'address', ''),
      value: {
        lng: _get(address, 'long', ''),
        lat: _get(address, 'lat', ''),
        address: _get(address, 'address', ''),
        city: _get(address, 'city', ''),
      },
    },
  });

  const [form, setFormData] = React.useState({
    id: null,
    size: null,
    ssl: null,
    date: null,
    count: 0,
    loc: null,
  });
  const [containers, setContainers] = React.useState(data.containers);

  React.useEffect(() => {
    setFormData(parseData(data));
  }, [data, Sizes, SSLs]);

  const setForm = (it, value) => setFormData({ ...form, [it]: value });

  const handleUpdateContainers = (name, value, index) => {
    setContainers((prev) => {
      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const submit = () => {
    setLoading(true);
    Update(form, data.id, containers).then(() => setLoading(false));
  };

  const getInventoryContainers = () => {
    let inventoryContainers = [];
    data.containers.forEach((container) => {
      if (
        container.status !== 2 &&
        inventoryContainers.length < data.count_all
      ) {
        inventoryContainers.push({
          name: container.name ? container.name : '',
          id: container.id ? container.id : null,
          chases: container.chases ? container.chases : '',
          status: container.status ? container.status : null,
        });
      }
    });
    setContainers(inventoryContainers);
  };

  useEffect(() => {
    getInventoryContainers();
  }, []);

  return (
    <div
      id="app_modal"
      onClick={(e) => e.target.id === 'app_modal' && Close()}
      className={cls.popup}>
      <div className={cls.card}>
        {loading ? (
          <Loading className={cls.loading} />
        ) : (
          <>
            <Tooltip arrow title="Close" placement="top">
              <CloseRounded className={cls.close} onClick={Close} />
            </Tooltip>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Select
                  placeholder="Size"
                  className={cls.select}
                  value={form.size}
                  onChange={(item) => setForm('size', item)}
                  options={Sizes}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Select
                  placeholder="SSL"
                  className={cls.select}
                  value={form.ssl}
                  onChange={(item) => setForm('ssl', item)}
                  options={SSLs}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                <TextField
                  type="number"
                  placeholder="Number"
                  className={cls.input}
                  value={form.count}
                  onChange={(e) => setForm('count', e.target.value)}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ min: 0 }}
                />
              </Grid> */}
              {/* </Grid> */}
              {/* <Grid container spacing={2}> */}
              <Grid item xs={12} sm={6}>
                <AsyncSelect
                  placeholder="Location"
                  className={cls.loc}
                  value={form.loc}
                  loadOptions={(e) => locationOpt(e)}
                  onChange={(item) => setForm('loc', item)}
                  cacheOptions
                  defaultOptions
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <DateInput
                  placeholder="Available date"
                  className={cls.date}
                  format="MM/dd/yyyy"
                  value={form.date}
                  onChange={(item) => setForm('date', item)}
                />
              </Grid>
              {/* </Grid> */}
              {/* <Grid container spacing={2}> */}
              {/* <span>Container Names</span> */}

              {containers.map((container, index) => {
                return (
                  <>
                    {container.status !== 2 && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <div className={`${cls.input} ${cls.containerInput}`}>
                            <span className={cls.containerBadge}>{`Container ${
                              index + 1
                            }`}</span>
                            <TextField
                              type="text"
                              key={index}
                              placeholder={`Container ${index + 1}`}
                              // className={cls.input}
                              disabled={container.status === 2}
                              value={container.name}
                              onChange={(e) =>
                                handleUpdateContainers(
                                  'name',
                                  e.target.value,
                                  index
                                )
                              }
                              InputProps={{ disableUnderline: true }}
                              inputProps={{ min: 0 }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className={`${cls.input} ${cls.containerInput}`}>
                            <span className={cls.containerBadge}>{`Chassis ${
                              index + 1
                            }`}</span>
                            <TextField
                              type="text"
                              key={index}
                              placeholder={`Chassis ${index + 1}`}
                              // className={cls.input}
                              value={container.chases}
                              onChange={(e) =>
                                handleUpdateContainers(
                                  'chases',
                                  e.target.value,
                                  index
                                )
                              }
                              InputProps={{ disableUnderline: true }}
                              inputProps={{ min: 0 }}
                            />
                          </div>
                        </Grid>
                      </>
                    )}
                  </>
                );
              })}
            </Grid>

            <Button className={cls.but} onClick={submit}>
              Update
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
