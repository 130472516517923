import React, { useEffect, useState, useRef } from 'react';
import {
  Tooltip,
  IconButton,
  CircularProgress,
  Checkbox,
} from '@material-ui/core';
import {
  DeleteRounded,
  CreateRounded,
  Share as ShareIcon,
} from '@material-ui/icons';
import { fDate, fTimeDate } from '@/actions/Helper';
import styles from './styles';
import Containers from './Containers';
import Share from './Share/index';
import {
  disableInventoryContainer,
  enableInventoryContainer,
  getInventoryShareLink,
} from '@/actions';
import { useNotify, Loading } from 'react-admin';
import { useSelector } from 'react-redux';
export default ({
  DATA = [],
  Del,
  DelContainer,
  Edit,
  getInventoryList,
  getInventoryListNotLoading,
  currentPage,
  tableTheadTitle,
  mptyTrackingEnable,
}) => {
  const cls = styles();
  const [containersData, setContainersData] = useState(DATA);
  const [showContainersModal, setShowContainersModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [timerinterval, setTimerInterval] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [enable_empty_tracking, setEnable_empty_tracking] = useState(null);
  const [shareLink, setShareLink] = useState({
    loading: null,
    DATA: null,
  });
  useEffect(() => {
    setContainersData(DATA);
  }, [DATA]);
  const notify = useNotify();

  const updateNotif = useSelector((state) =>
    state.reducer.notif ? state.reducer.notif : null
  );
  const updateNotif11 = useSelector((state) =>
    state.reducer.containerData ? state.reducer.containerData : null
  );

  const empty_trackingState = useSelector((state) => {
    if (state) {
      const stateRes = state.reducer?.user.metas?.enable_empty_tracking?.value;
      console.log('stateRes', stateRes);

      return stateRes;
    }
    // if (state.reducer?.user.metas?.enable_empty_tracking) {
    //   return state.reducer?.user.metas?.enable_empty_tracking?.value;
    // }
  });
  useEffect(() => {
    setEnable_empty_tracking(empty_trackingState);
  }, [empty_trackingState]);

  useEffect(() => {
    if (
      updateNotif11?.container?.gated_out ||
      updateNotif11?.container?.gated_in
    ) {
      getInventoryListNotLoading();
      notify('Containers Data Updated.');
    }
  }, [updateNotif11]);

  const formatDateTimeToUTC = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedDate =
      `${String(date.getUTCMonth() + 1).padStart(2, '0')}-` + // Months are zero-based
      `${String(date.getUTCDate()).padStart(2, '0')}-` +
      `${String(date.getUTCFullYear()).slice(-2)}`; // Slice the last two digits for the year

    // Check if both hours and minutes are 00
    if (hours === 0 && minutes === 0) {
      return formattedDate; // Return only the date
    } else {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )} ${formattedDate}`; // Return time and date
    }
  };

  const formatDateToUTC = (dateString) => {
    const date = new Date(dateString);
    const formattedDate =
      `${String(date.getUTCMonth() + 1).padStart(2, '0')}-` + // Months are zero-based
      `${String(date.getUTCDate()).padStart(2, '0')}-` +
      `${String(date.getUTCFullYear()).padStart(2, '0')} `;

    return formattedDate;
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const handleEnableCongtainer = (containerId, index) => {
    enableInventoryContainer(containerId).then((response) => {
      if (response.success) {
        notify('container updated');
        // updateContainers(index, 'status', 1);
        // setRefreshInventories(true);
        getInventoryListNotLoading();
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleDisableContainer = (containerId, index) => {
    disableInventoryContainer(containerId).then((response) => {
      if (response.success) {
        notify('container updated');
        // updateContainers(index, 'status', 0);
        // setRefreshInventories(true);
        getInventoryListNotLoading();
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleChangeContainerStatus = (value, containerId, index) => {
    if (value === true) {
      handleEnableCongtainer(containerId, index);
    } else {
      handleDisableContainer(containerId, index);
    }
  };

  const getActivContainers = (inventory, target) => {
    if (inventory) {
      let activeContainers = [];
      let deactiveContainers = [];
      inventory.containers.forEach((container) => {
        if (container.status === 1) {
          activeContainers.push(container);
        } else if (container.status === 0) {
          deactiveContainers.push(container);
        }
      });

      if (deactiveContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`all ${cls.containerChecBox}`}
            checked={true}
          />
        );
      } else if (activeContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`any ${cls.containerChecBox}`}
            checked={false}
          />
        );
      } else {
        return (
          <input
            type="checkbox"
            checked={true}
            className={`some ${cls.containerChecBox}`}
          />
        );
      }
    }
  };

  const handleGetShareLink = (i) => {
    setShareLink({ loading: i.id });
    getInventoryShareLink(i.id)
      .then((res) => {
        setShareModal(i);
        setShareLink({
          loading: null,
          DATA: res,
        });
      })
      .catch((err) => {
        setShareLink({ loading: null });
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    let someCompletedCheckboxs = document.getElementsByClassName('some');
    let allCompletedCheckboxs = document.getElementsByClassName('all');
    let notCompletedCheckboxs = document.getElementsByClassName('any');
    for (let i = 0; i < someCompletedCheckboxs.length; i++) {
      someCompletedCheckboxs[i].indeterminate = true;
    }
    for (let i = 0; i < allCompletedCheckboxs.length; i++) {
      allCompletedCheckboxs[i].indeterminate = false;
    }
    for (let i = 0; i < notCompletedCheckboxs.length; i++) {
      notCompletedCheckboxs[i].indeterminate = false;
      notCompletedCheckboxs[i].checked = false;
    }
  }, [getActivContainers, getInventoryList]);
  // console.log('data', DATA);
  let uniqueIndex = 0;

  // const [progress, setProgress] = useState(0);
  const [fastProgress, setFastProgress] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setProgress((prev) => {
  //       if (prev >= 100) {
  //         clearInterval(interval);
  //         return 100;
  //       }
  //       return prev + 0.1667;
  //     });
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    let fastInterval; // Define fastInterval outside the function

    const startFastProgress = () => {
      fastInterval = setInterval(() => {
        setFastProgress((prev) => {
          if (prev >= 100) {
            clearInterval(fastInterval); // Stop the interval when progress reaches 100
            setFastProgress(0); // Reset progress to 0
            startFastProgress(); // Restart the progress
            return 0;
          }
          return prev + 1.6667; // Increment by 1.6667 every 10 milliseconds
        });
      }, 30); // 10 milliseconds interval
    };

    startFastProgress(); // Start the progress initially

    return () => {
      clearInterval(fastInterval); // Cleanup interval on component unmount
    };
  }, []);

  // Helper function to calculate the difference in minutes
  const getMinutesDiff = (time1, time2) => {
    const diff = Math.abs(new Date(time2) - new Date(time1));
    return Math.floor(diff / 60000); // Convert milliseconds to minutes
  };

  const [timers, setTimers] = useState({});

  const startTimers = () => {
    containersData.forEach((i) => {
      i.containers.forEach((container, idd) => {
        const timerKey = `timer_${container.id}`;
        const lastUpdate = container.scr_next;
        const lastScrape = container.scr_last;
        const elapsedMinutes = getMinutesDiff(lastUpdate, new Date());
        if (
          lastScrape !== null ||
          container.gated_out !== null ||
          container.gated_in !== null ||
          i.ssl === 'OOCL' ||
          elapsedMinutes > 10
        ) {
          localStorage.removeItem(timerKey);
          setTimerInterval((prevState) => ({
            ...prevState,
            [timerKey]: 0, // Restart timer from 0
          }));
        } else {
          let storedTime = parseInt(localStorage.getItem(timerKey)) || 0;

          if (storedTime + elapsedMinutes >= 600) {
            updateCheckAmirbio();
            localStorage.removeItem(timerKey);
          } else {
            storedTime += elapsedMinutes;
            localStorage.setItem(timerKey, storedTime);

            const interval = setInterval(() => {
              storedTime += 1;
              localStorage.setItem(timerKey, storedTime);
              // Update the state for each timer
              setTimerInterval((prevState) => ({
                ...prevState,
                [timerKey]: storedTime,
              }));

              if (storedTime >= 600) {
                updateCheckAmirbio();
                getInventoryListNotLoading();
                // notify('Containers Data Updated.');
                window.location.reload();
                localStorage.setItem(timerKey, 0); // Reset timer to 0 when 600 is reached
                setTimerInterval((prevState) => ({
                  ...prevState,
                  [timerKey]: 0, // Restart timer from 0
                }));
              }

              if (container.gated_out) {
                clearInterval(interval);
                localStorage.removeItem(timerKey);
              }
            }, 1000); // Every second
          }
        }
      });
    });
  };

  const updateCheckAmirbio = () => {};

  useEffect(() => {
    startTimers(); // Start the timers on component mount
  }, [DATA, containersData, updateNotif11]);

  const amirbiosRef = useRef(null);
  const tableRef = useRef(null);
  const amirbios1Ref = useRef(null);
  const amirbios2Ref = useRef(null);

  const [tableWidth, setTableWidth] = useState(0);
  const [spanWidth, setSpanWidth] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (amirbiosRef.current && amirbios1Ref.current) {
        const amirbiosTop = amirbiosRef.current.getBoundingClientRect().top;

        if (amirbiosTop <= 69) {
          amirbios2Ref.current.classList.add(cls.fix);
          tableRef.current.classList.add(cls.tableTopMargin);
        } else {
          amirbios2Ref.current.classList.remove(cls.fix);
          tableRef.current.classList.remove(cls.tableTopMargin);
        }
      }
      syncScroll('amirbios');
    };
    const updateWidth = () => {
      if (tableRef.current && amirbios1Ref.current) {
        const tableCurrentWidth =
          tableRef.current.getBoundingClientRect().width;
        setTableWidth(tableCurrentWidth);
      }
    };

    const updateHeadWidth = () => {
      if (amirbiosRef.current && amirbios2Ref.current) {
        const tableeCurrentWidth =
          amirbiosRef.current.getBoundingClientRect().width;
        setSpanWidth(tableeCurrentWidth);
      }
    };

    const syncScroll = (source) => {
      if (amirbiosRef.current || amirbios2Ref.current) {
        if (source === 'amirbios') {
          console.log('sss');

          amirbios2Ref.current.scrollLeft = amirbiosRef.current.scrollLeft;
        } else if (source === 'amirbios2') {
          console.log('www');

          amirbiosRef.current.scrollLeft = amirbios2Ref.current.scrollLeft;
        }
      }
    };

    updateWidth();
    updateHeadWidth();

    const handleScroll1 = () => syncScroll('amirbios');
    const handleScroll2 = () => syncScroll('amirbios2');

    if (amirbiosRef.current) {
      amirbiosRef.current.addEventListener('scroll', handleScroll1);
    }

    if (amirbios1Ref.current) {
      amirbios2Ref.current.addEventListener('scroll', handleScroll2);
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateWidth);
    window.addEventListener('resize', updateHeadWidth);
    window.addEventListener('scroll', updateWidth);
    window.addEventListener('scroll', updateHeadWidth);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateWidth);
      window.removeEventListener('resize', updateHeadWidth);
      window.removeEventListener('scroll', updateWidth);
      window.removeEventListener('scroll', updateHeadWidth);

      if (amirbiosRef.current) {
        amirbiosRef.current.removeEventListener('scroll', handleScroll1);
      }

      if (amirbios2Ref.current) {
        amirbios2Ref.current.removeEventListener('scroll', handleScroll2);
      }

      // if (amirbios2Ref.current) {
      //   amirbios2Ref.current.removeEventListener('scroll', () =>
      //     syncScroll('amirbios2')
      //   );
      // }
    };
  }, [DATA, tableTheadTitle, containersData, amirbiosRef, amirbios2Ref]);

  useEffect(() => {
    if (amirbios2Ref.current) {
      amirbios2Ref.current.style.width = `${spanWidth - 4}px`;
    }
  }, [spanWidth]);

  useEffect(() => {
    if (amirbios1Ref.current) {
      amirbios1Ref.current.style.width = `${tableWidth - 4}px`;
    }
  }, [tableWidth]);

  console.log('enable_empty_tracking', enable_empty_tracking);

  return (
    <div ref={amirbiosRef} id="amirbios" className={cls.listContainer}>
      {showContainersModal && selectedRow && (
        <Containers
          open={showContainersModal}
          DATA={containersData}
          selectedRow={selectedRow}
          setShowContainersModal={setShowContainersModal}
          getInventoryList={getInventoryList}
        />
      )}
      {shareModal && !shareLink.loading && (
        <Share
          shareLink={shareLink}
          shareModal={shareModal}
          setShareModal={setShareModal}
        />
      )}
      {enable_empty_tracking === 'nis' ? (
        <Loading />
      ) : enable_empty_tracking === '1' ? (
        <table ref={tableRef} id="table" className={cls.list}>
          <thead ref={amirbios2Ref} id="amirbios1">
            <tr ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
              <th style={{ flex: 0.4 }}>#</th>
              {tableTheadTitle.map((header, index) =>
                header?.show === false ? (
                  ''
                ) : (
                  <th key={index} style={{ flex: header.flex }}>
                    {header.label}
                  </th>
                )
              )}
              <th style={{ flex: 1.3 }}>Action</th>
            </tr>
          </thead>
          <tbody className={cls.tbody}>
            {containersData.map((i, idx) =>
              i.containers.map((container, idd) => {
                const timerKey = `timer_${container.id}`;
                const time1 = new Date();
                const time2 = container.scr_next;
                const diff = Math.abs(new Date(time2) - new Date(time1));
                const difff = Math.floor(diff / 60000);

                uniqueIndex++;
                return (
                  <>
                    <tr className={cls.tableRow} key={`${uniqueIndex}new`}>
                      <td style={{ flex: 0.4 }}>
                        {uniqueIndex + (currentPage - 1) * 10}
                      </td>
                      {tableTheadTitle.map((header, index) => {
                        const objectKey = header.object;

                        return header?.show === false ? (
                          ''
                        ) : (
                          <td key={`${index}new`} style={{ flex: header.flex }}>
                            {['size', 'ssl', 'loc'].includes(objectKey)
                              ? i[objectKey]
                                ? i[objectKey]
                                : '-'
                              : ''}
                            {[
                              'name',
                              'chases',
                              'return_appointment',
                              'return_terminals',
                              'return_dual_terminals',
                            ].includes(objectKey)
                              ? container[objectKey]
                                ? container[objectKey]
                                : '-'
                              : ''}
                            {objectKey === 'own_chases'
                              ? container['own_chases'] === 0
                                ? 'Pool'
                                : 'Own'
                              : ''}
                            {objectKey === 'return_appointment'
                              ? 'No Apt. Available'
                              : ''}
                            {objectKey === 'gated_out' ? (
                              container.scr_last !== null ||
                              container.gated_in !== null ||
                              container.gated_out !== null ||
                              i.ssl === 'OOCL' ||
                              difff > 10 ? (
                                container.gated_out ? (
                                  formatDateTimeToUTC(container.gated_out)
                                ) : (
                                  '-'
                                )
                              ) : (
                                <svg
                                  width="25"
                                  height="25"
                                  viewBox="0 0 36 36"
                                  className={cls.circularLoader}>
                                  <path
                                    className={cls.circleBackground}
                                    d="M18 2.0845
                              a 15.9155 15.9155 0 0 1 0 31.831
                              a 15.9155 15.9155 0 0 1 0 -31.831"
                                  />
                                  <path
                                    d="M18 2.0845
                              a 15.9155 15.9155 0 0 1 0 31.831
                              a 15.9155 15.9155 0 0 1 0 -31.831"
                                    className={cls.circleFastProgress}
                                    strokeDasharray={`${fastProgress}, 100`}
                                  />
                                  <path
                                    className={cls.circleProgress}
                                    strokeDasharray={`${
                                      timerinterval[timerKey] / 6.2
                                    }, 100`}
                                    d="M18 2.0845
                              a 15.9155 15.9155 0 0 1 0 31.831
                              a 15.9155 15.9155 0 0 1 0 -31.831"
                                  />
                                </svg>
                              )
                            ) : (
                              ''
                            )}
                            {objectKey === 'gated_in'
                              ? container['gated_in']
                                ? formatDateTimeToUTC(container['gated_in'])
                                : '-'
                              : ''}
                            {objectKey === 'last_free_day'
                              ? container['last_free_day']
                                ? formatDateToUTC(container['last_free_day'])
                                : '-'
                              : ''}
                            {objectKey === 'est_per_diem'
                              ? container['est_per_diem']
                                ? formatPrice(container['est_per_diem'])
                                : '-'
                              : ''}
                            {objectKey === 'date'
                              ? container['date']
                                ? fDate(container['date'])
                                : '-'
                              : ''}
                            {objectKey === 'added_date'
                              ? container['added_date']
                                ? fTimeDate(container['added_date'])
                                : container['created_at']
                                ? fTimeDate(container['created_at'])
                                : '-'
                              : ''}
                          </td>
                        );
                      })}

                      <td className={cls.tabActions} style={{ flex: 1.3 }}>
                        {shareLink.loading === i.id ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Tooltip arrow title="Share" placement="top">
                            <IconButton
                              aria-label="share"
                              disabled={i.active === 0}
                              onClick={() => handleGetShareLink(i)}>
                              <ShareIcon
                                style={
                                  i.active === 0
                                    ? { fill: '#a2a2a2' }
                                    : { fill: '#000000' }
                                }
                                className={cls.replyIcon}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip arrow title="Edit" placement="top">
                          <IconButton
                            aria-label="edit"
                            onClick={() =>
                              Edit(
                                i,
                                container.id,
                                container.gated_in,
                                container.gated_out,
                                container
                              )
                            }>
                            <CreateRounded className={cls.editIcon} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          arrow
                          title={container.status === 1 ? 'Disable' : 'Enable'}
                          placement="top">
                          <Checkbox
                            checked={container.status === 1}
                            className={cls.checkbox}
                            onChange={(e) =>
                              handleChangeContainerStatus(
                                e.target.checked,
                                container.id,
                                idd
                              )
                            }
                          />
                        </Tooltip>
                        <Tooltip arrow title="Delete" placement="top">
                          <IconButton
                            aria-label="delete"
                            onClick={() => DelContainer(container.id)}>
                            <DeleteRounded className={cls.delIcon} />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  </>
                );
              })
            )}
          </tbody>
        </table>
      ) : enable_empty_tracking === '0' ||
        enable_empty_tracking === undefined ? (
        <table className={cls.list}>
          <thead className={cls.thead}>
            <th style={{ flex: 0.35 }}>#</th>
            <th style={{ flex: 1 }}>Size</th>
            <th style={{ flex: 0.7 }}>SSL</th>
            <th style={{ flex: 0.7 }}>Active</th>
            <th style={{ flex: 0.7 }}>Container #</th>
            <th style={{ flex: 1.2 }}>Location</th>
            <th style={{ flex: 1 }}>Available Date</th>
            <th style={{ flex: 0.7 }}>Number of Own Chassis</th>
            <th style={{ flex: 0.7 }}>Number of Pool Chassis</th>
            <th style={{ flex: 1 }}>Last Update</th>
            <th style={{ flex: 1 }}>Action</th>
          </thead>
          <tbody className={cls.tbody}>
            {containersData.map((i, idx) => (
              <tr className={cls.tableRow} key={idx}>
                <td style={{ flex: 0.35 }}>
                  {idx + 1 + (currentPage - 1) * 10}
                </td>
                <td style={{ flex: 1 }}>{i.size}</td>
                <td style={{ flex: 0.7 }}>{i.ssl}</td>
                <td style={{ flex: 0.7 }}>{i.count_all}</td>
                <td style={{ flex: 0.7 }}>{i.active}</td>
                <td style={{ flex: 1.2 }}>{i.loc}</td>
                <td style={{ flex: 1 }}>{fDate(i.date)}</td>
                <td style={{ flex: 0.7 }}>{i.own_chases_count}</td>
                <td style={{ flex: 0.7 }}>{i.pool_chases_count}</td>
                <td style={{ flex: 1 }}>{fTimeDate(i.up)}</td>
                <td className={cls.tabActions} style={{ flex: 1 }}>
                  {shareLink.loading === i.id ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Tooltip
                      className={cls.tooltipClass}
                      arrow
                      title="Share"
                      placement="top">
                      <IconButton
                        aria-label="share"
                        disabled={i.active === 0}
                        onClick={() => handleGetShareLink(i)}>
                        <ShareIcon
                          style={
                            i.active === 0
                              ? { fill: '#a2a2a2' }
                              : { fill: '#000000' }
                          }
                          className={cls.replyIcon}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip arrow title="Edit" placement="top">
                    <IconButton aria-label="edit" onClick={() => Edit(i)}>
                      <CreateRounded className={cls.editIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow title="Containers" placement="top">
                    <IconButton
                      onClick={() => {
                        setShowContainersModal(!showContainersModal);
                        setSelectedRow(i);
                      }}>
                      {/* <img src={getActivContainers(i)} /> */}
                      {getActivContainers(i)}
                    </IconButton>
                  </Tooltip>

                  <Tooltip arrow title="Delete" placement="top">
                    <IconButton aria-label="delete" onClick={() => Del(i.id)}>
                      <DeleteRounded className={cls.delIcon} />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
    </div>
  );
};
