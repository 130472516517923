import React from 'react';
import { Link } from 'react-router-dom';
import { ReplayRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { Grid } from '@material-ui/core';
import styles from './styles';

const parseData = (q, opt, data) => ({
  size: opt.size.find((i) => i.value.toString() === q.size),
  ssl: opt.ssl.find((i) => i.value.toString() === q.ssl),
  add: q.address.substr(0, 10),
  count: q.count,
  tCont: data.reduce((a, b) => a + b.count, 0),
  tCom: data.length,
});
function refreshPage() {
  window.location.reload();
}

const getDetail = (i) =>
  `${_get(i, 'size.label', '')}, ${_get(i, 'ssl.label', '')}, ${i.add}`;

export default ({ q, DATA = {}, RemNum }) => {
  const cls = styles();
  const selectOp = useSelector((state) => state.reducer);

  const [state, setState] = React.useState({});
  React.useEffect(() => {
    if (selectOp) setState(parseData(q, selectOp, DATA));
  }, [DATA, selectOp, q]);

  return (
    <Grid container spacing={2} className={cls.container}>
      <Grid item xs={12} lg={6} xl={5}>
        <div className={cls.card1}>
          <div className={cls.col}>
            <h1>Search Result</h1>
            <h2>Request Details:</h2>
            <h3>{getDetail(state)}</h3>
          </div>
          <div className={cls.divider} />
          <div className={cls.col1}>
            <h3>Total Company: {state.tCom}</h3>
            <h3>Total Container: {state.tCont}</h3>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} lg={6} xl={4}>
        <div className={cls.card2}>
          <div className={cls.col}>
            <h1>Status</h1>
            <h2>search type</h2>
            <h3>{state.count} manual, 0 smart</h3>
          </div>
          <div className={cls.divider} />
          <div className={cls.col1}>
            <div className={cls.row}>
              <h1>{parseInt(state.count - RemNum) || 0}</h1>
              <h3>Selected</h3>
            </div>
            <div className={cls.row}>
              <h1>{RemNum || 0}</h1>
              <h3>Remaining</h3>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} lg={6} xl={3}>
        <div className={cls.card1} style={{ flex: 1 }}>
          <div className={cls.col2}>
            <h3>We will Find You Best match</h3>
            <span
              onClick={(e) => {
                refreshPage();
              }}
              className={cls.but}>
              Search again
              <ReplayRounded className={cls.icon} />
            </span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
