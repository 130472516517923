import React from 'react';
import { CloseRounded } from '@material-ui/icons';
import styles from './styles';
import closeIcon from '../../assets/img/closeIcon.svg';
import { Modal, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { useOutsideAlerter } from '@/hocs';
import { Tooltip, Grid, TextField, Button } from '@material-ui/core';
import clsx from 'clsx';
import { TextareaAutosize } from '@mui/material';
import Select from 'react-select';
import { adminUploadHtmlToScrape } from '@/actions';
import { useNotify } from 'react-admin';

export default ({
  Close,
  mode,
  DATA,
  open,
  handleChangeStatusShifts,
  terminal_id,
  date,
  restriction_id,
  shift_column,
  shift_value,
  shift_message,
  ssl_key,
  indexContainer,
  idx,
}) => {
  const cls = styles();
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    Close();
  });
  const setForm = (it, val) => setFormData({ ...form, [it]: val });
  const notify = useNotify();

  const [message_ssl, setMessage_ssl] = React.useState(DATA);
  const terminalList = [
    {
      label: 'PCT',
      value: 'PCT',
    },
    {
      label: 'Piar A',
      value: 'PiarA',
    },
  ];
  const [form, setFormData] = React.useState({
    slug: '',
    content: '',
  });

  const handleUploadHtmlToScrape = (form) => {
    adminUploadHtmlToScrape(form)
      .then((res) => {
        notify('Successfully Uploaded!', 'success');
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const saveMessage = () => {
    const terminal_idd = terminal_id;
    const dated = date;
    const restriction_idd = restriction_id;
    const shift_columnd = shift_column;
    const shift_valued =
      shift_value === 'DUAL'
        ? 2
        : shift_value === false
        ? 0
        : shift_value === true
        ? 1
        : shift_value === 1 || shift_value === 0
        ? shift_value
        : null;
    const keyd = ssl_key;
    const indexContainerd = indexContainer;
    const idxd = idx;

    handleChangeStatusShifts(
      terminal_idd,
      dated,
      restriction_idd,
      shift_columnd,
      shift_valued,
      message_ssl,
      keyd,
      indexContainerd,
      idx
    );
    Close();
  };
  console.log('form', form);

  return (
    <>
      <div>
        <Modal open={open}>
          <div
            id="app_modal"
            className={cls.modal}
            // onClick={
            //   (e) => console.log('hI')
            //   // e.target.id === 'app_modal' && setShowContainersModal(false)
            // }
            style={{ width: '100%', height: '100%' }}>
            <Box className={cls.modalBox}>
              <div
                // onClick={() => setShowContainersModal(false)}
                className={cls.closeModal}>
                <img alt="close_icon" src={closeIcon} />
              </div>
              <h3 className={cls.modalTitle}>Own Chassis</h3>
              <div className={cls.containersTableContainer}>
                {/* {gatEditContainersContent()} */}
              </div>
              <button
                className={cls.updateContainersBtn}
                // onClick={() => setShowContainersModal(false)}
              >
                Close
              </button>
            </Box>
          </div>
        </Modal>
      </div>
      <div className={cls.popup}>
        <div ref={wrapperRef} className={cls.popupCard}>
          {mode === 'imortHtml' ? (
            <>
              <h3>Import HTML Code</h3>
              <Grid item xs={12} sm={12} lg={12}>
                <div className={`${cls.input} ${cls.containerInput}`}>
                  <span className={cls.containerBadge}>{`Terminals `}</span>
                  <Select
                    placeholder="Terminals"
                    className={clsx(cls.select)}
                    // value={selectedSSL}
                    onChange={(item) => {
                      setForm('slug', item.value);
                      // setSelectedSSL(item); // Set selected SSL state
                    }}
                    options={terminalList}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <TextareaAutosize
                  className={cls.inputTextArea}
                  type="text"
                  key={'a'}
                  minRows={6}
                  defaultValue={DATA}
                  placeholder={``}
                  onChange={(e) => {
                    setForm('content', e.target.value);

                    // setVesselValue(e.target.value);
                  }}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ min: 0 }}
                />
              </Grid>

              <span className={cls.msgPopupBtns}>
                <Button
                  className={clsx(cls.saveBtn)}
                  onClick={(e) => {
                    Close();
                  }}>
                  Cancel
                </Button>
                <Button
                  className={clsx(cls.saveBtn)}
                  onClick={(e) => {
                    handleUploadHtmlToScrape(form);
                  }}>
                  Save
                </Button>
              </span>
            </>
          ) : (
            <>
              <h3>Message</h3>
              <Grid item xs={12} sm={12} lg={12}>
                <TextareaAutosize
                  className={cls.inputTextArea}
                  type="text"
                  key={'a'}
                  minRows={3}
                  defaultValue={DATA}
                  placeholder={``}
                  onChange={(e) => {
                    setMessage_ssl(e.target.value);

                    // setVesselValue(e.target.value);
                  }}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <span className={cls.msgPopupBtns}>
                <Button
                  className={clsx(cls.saveBtn)}
                  // onClick={(e) => {
                  //   saveMessage(message_ssl);
                  // }}
                >
                  Scrape
                </Button>
                <Button
                  className={clsx(cls.saveBtn)}
                  onClick={(e) => {
                    saveMessage(message_ssl);
                  }}>
                  Save
                </Button>
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
};
