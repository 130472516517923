import React, { useEffect, useState } from 'react';
import { Loading } from 'react-admin';
import {
  Button,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { addInventory } from '@/actions';
import DateInput from '@/components/DateInput';
import { locationOpt } from '@/actions/Helper';
import { debounce } from 'lodash';

import styles from './styles';
import { boolean } from 'yup';

export default ({ notify, setData, getInventoryList }) => {
  const cls = styles();
  const [loading, setLoading] = React.useState(false);
  const [showContainerNames, setShowContainerNames] = useState(true);
  const selectOp = useSelector((state) => state.reducer);

  const [form, setFormData] = React.useState({
    size: null,
    ssl: null,
    date: new Date(),
    count: '',
    loc: null,
    containers: [],
  });
  const [showContainers, setShowContainers] = React.useState(false);

  useEffect(() => {
    let isShowContainers = localStorage.getItem('smart_user_show_containers');
    let isShowContainerNames = localStorage.getItem(
      'smart_user_show_container_names'
    );
    if (isShowContainers === 'true') {
      setShowContainers(true);
    }
    if (isShowContainerNames === 'false') {
      setShowContainerNames(false);
    }
  }, []);

  const setForm = (it, val) => setFormData({ ...form, [it]: val });

  const Add = () => {
    let isFillAllContainers = true;
    let emptyContainers = form.containers.filter(
      (container) => !container.name || !container.chases
    );
    if (emptyContainers.length === 0) {
      for (let el in form) {
        if (!form[el]) {
          notify('Can not add, Please check fields.', 'warning');
          return;
        }
      }

      setLoading(true);

      addInventory({
        ...form,
      })
        .then((res) => {
          notify('Added Successfully.');
          getInventoryList();
          Reset();
          setLoading(false);
        })
        .catch((err) => {
          notify(err, 'warning');
          setData([]);
          setLoading(false);
        });
    } else {
      notify('please fill all containers and chassis');
    }
  };

  const Reset = () =>
    setFormData({
      size: null,
      ssl: null,
      date: new Date(),
      count: '',
      loc: null,
      containers: [],
    });

  const setFormContainers = () => {
    let containers = [];
    for (let i = 0; i < form.count; i++) {
      if (form.containers[i]) {
        containers.push(form.containers[i]);
      } else {
        containers.push('');
      }
    }
    setForm('containers', containers);
  };

  useEffect(() => {
    setFormContainers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.count]);

  if (loading) return <Loading className={cls.loading} />;

  return (
    <div id="emptyCard" className={cls.card}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} lg={2}>
          <Select
            placeholder="Size"
            className={cls.select}
            value={form.size}
            onChange={(item) => setForm('size', item)}
            options={selectOp.size}
          />
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <Select
            placeholder="SSL"
            className={clsx(cls.select)}
            value={form.ssl}
            onChange={(item) => setForm('ssl', item)}
            options={selectOp.ssl}
          />
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <TextField
            type="number"
            placeholder="Number"
            className={clsx(cls.input)}
            value={form.count}
            onChange={(e) => setForm('count', e.target.value)}
            InputProps={{ disableUnderline: true }}
            inputProps={{ min: 0 }}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <AsyncSelect
            placeholder="Location"
            className={cls.loc}
            value={form.loc}
            loadOptions={locationOpt}
            onChange={(item) => setForm('loc', item)}
            cacheOptions
            defaultOptions
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <DateInput
            placeholder="Available date"
            className={cls.date}
            format="MM/dd/yyyy"
            value={form.date}
            onChange={(item) => setForm('date', item)}
          />
        </Grid>
        {showContainerNames && (
          <Grid item xs={12}>
            {/* <Grid xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={cls.checkbox}
                    checked={showContainers}
                    onChange={(e) => {
                      setShowContainers(e.target.checked);
                      localStorage.setItem(
                        'smart_user_show_containers',
                        e.target.checked
                      );
                    }}
                  />
                }
                label="Show Container Numbers"
              />
            </Grid> */}
            <Grid container spacing={2}>
              {
                // showContainers &&
                form.containers.map((item, i) => (
                  <Grid key={i} item xs={12} sm={3} md={2}>
                    <TextField
                      type="text"
                      placeholder={`Container ${i + 1}`}
                      className={cls.textInput}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ min: 1 }}
                      value={form.containers[i].name}
                      onChange={(e) => {
                        setForm('containers', [
                          ...form.containers.slice(0, i),
                          {
                            ...form.containers[i],
                            name: e.target.value,
                          },
                          ...form.containers.slice(i + 1),
                        ]);
                      }}
                    />
                    <TextField
                      type="text"
                      placeholder={`Chassis ${i + 1}`}
                      className={cls.textInput}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ min: 1 }}
                      value={form.containers[i].chases}
                      onChange={(e) => {
                        setForm('containers', [
                          ...form.containers.slice(0, i),
                          {
                            ...form.containers[i],
                            chases: e.target.value,
                          },
                          ...form.containers.slice(i + 1),
                        ]);
                      }}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        )}
      </Grid>
      <div className={cls.actions}>
        <span className={cls.text}>
          Please fill the form above and click on add button. You can edit or
          delete an inventory later through the below list
        </span>
        <div className={cls.buttons}>
          <Button className={clsx(cls.but, cls.red)} onClick={Reset}>
            Reset
          </Button>
          <Button className={clsx(cls.but, cls.blue)} onClick={Add}>
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};
