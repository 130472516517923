import React, { useState } from 'react';
import styles from './styles';
import { BaseIcon } from '@/Utils';
import { Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import { MemberIcons as memberIcons, Icons } from '@/assets';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import EditMemberPopup from '@/components/Members/Edit';
import AddMemberPopup from '@/components/Members/Add';
import { DeleteRounded, CreateRounded, Email } from '@material-ui/icons';
import { useNotify } from 'react-admin';
import { useHistory } from 'react-router-dom';
import {
  AddAdmin,
  adminDeleteMember,
  adminEditMember,
  adminLoginToUserAccount,
  adminSendVerificationEmail,
  deleteAdmin,
  editAdmin,
  SaveData,
} from '@/actions';
import DeleteMemberPopup from '@/components/DeletePopup';
import {
  Avatar,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core';
import Filter from '@/components/Members/Filter';
import { UrlField } from 'react-admin';
import DeletePopup from '@/components/DeletePopup';
import { validateAdminAddMemberData } from '@/actions/validations';

export default ({ DATA, getAdmins }) => {
  const cls = styles();
  const [userAvatar, setUserAvatar] = useState({
    url: null,
    image: null,
  });
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    last_name: '',
    email: '',
    phone: '',
    company: '',
    password: '',
    // status: DATA.status,
  });
  const editpopUp = useSelector(
    (state) => state.reducer.editMemberPopup || null
  );
  const addpopUp = useSelector((state) => state.reducer.addMemberPopup || null);

  const notify = useNotify();
  const deletepopUp = useSelector(
    (state) => state.reducer.deleteMemberPopup || null
  );

  const renderTableActions = (admin) => {
    let actionItems = [
      {
        icon: <CreateRounded className={cls.editIcon} />,
        tooltip: 'Edit',
        onClick: () => {
          SaveData({ editMemberPopup: admin });
        },
      },
      {
        icon: <DeleteRounded className={cls.delIcon} />,
        tooltip: 'Delete',
        onClick: () => {
          SaveData({ deleteMemberPopup: admin.id });
        },
      },
    ];
    return (
      <div className={cls.tableActionsContainer}>
        {actionItems.map((item, index) => (
          <Tooltip arrow title={item.tooltip} placement="top">
            <IconButton onClick={item.onClick}>{item.icon}</IconButton>
          </Tooltip>
        ))}
      </div>
    );
  };

  const handleChangeStatus = (id, status) => {
    editAdmin({ status, id, isEditStatus: true })
      .then(() => {
        notify('successfully updated', 'success');
        getAdmins();
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const updateMembers = (data) => {
    editAdmin(data)
      .then(() => {
        SaveData({ editMemberPopup: false });
        notify('successfully updated', 'success');
        getAdmins();
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const resetAddMemberValues = () => {
    setFormData({
      id: '',
      name: '',
      last_name: '',
      email: '',
      phone: '',
      company: '',
      password: '',
    });
    setUserAvatar({
      image: '',
      url: '',
    });
  };

  const handleAdd = () => {
    let data = formData;

    if (userAvatar.image) {
      data.image = userAvatar.image;
    }

    let error = validateAdminAddMemberData(data);
    if (error) {
      notify(error, { type: 'warning' });
    } else {
      AddAdmin(formData)
        .then((res) => {
          if (res.status === 'success') {
            notify(res.message, { type: 'success' });
            getAdmins();
            resetAddMemberValues();
          }
        })
        .catch((res) => {
          if (res.errors) {
            Object.keys(res.errors).map((message) => {
              notify(res.errors[message][0], { type: 'warning' });
            });
          } else {
            notify(res, { type: 'warning' });
          }
        });
    }
  };

  const handleDeleteAdmin = (admin) => {
    deleteAdmin(admin).then((res) => {
      if (res.status === 'success') {
        getAdmins();
        notify('admin successfully deleted', { type: 'success' });
      }
    });
    SaveData({ deleteMemberPopup: false });
  };

  return (
    <div className={cls.container}>
      {deletepopUp && (
        <DeleteMemberPopup
          handleDelete={() => handleDeleteAdmin(deletepopUp)}
          closePopup={() => SaveData({ deleteMemberPopup: false })}
        />
      )}
      {editpopUp && (
        <EditMemberPopup
          update={updateMembers}
          DATA={editpopUp}
          notify={() => {}}
        />
      )}
      {addpopUp && (
        <AddMemberPopup
          formData={formData}
          setFormData={setFormData}
          handleAdd={handleAdd}
          userAvatar={userAvatar}
          setUserAvatar={setUserAvatar}
        />
      )}
      {/* <Filter
        title="Members"
        filter={filter}
        setFilter={setFilter}
        setFilterForm={setFilterForm}
        DATA={DATA}
        setLoading={setLoading}
        notify={notify}
        setDATA={setDATA}></Filter> */}
      <div className={cls.tableContainer}>
        <table className={cls.list}>
          <thead className={cls.thead}>
            <th style={{ flex: 1.5, textAlign: 'left' }}>
              <span style={{ paddingLeft: '70px' }}>Admin</span>
            </th>
            <th style={{ flex: 1 }}>Email Address</th>
            <th style={{ flex: 1 }}>Phone</th>
            <th style={{ flex: 1 }}>Status</th>
            <th style={{ flex: 1 }}>Actions</th>
          </thead>
          <tbody className={cls.tbody}>
            <tr className={cls.tableRow}>
              <td className={cls.addAvatar}>

                <BaseIcon
                  src={memberIcons.addMember}
                  onClick={() => {
                    SaveData({ addMemberPopup: true });
                  }}
                  className={clsx(cls.icon)}
                />
              </td>
              <span
                onClick={() => {
                  SaveData({ addMemberPopup: true });
                }}
                className={cls.addMemberText}>
                Add Admin
              </span>
            </tr>
            {DATA.map((i, index) => (
              <tr className={cls.tableRow}>
                <td
                  style={{ flex: 1.5 }}
                  className={`${cls.bigText} ${cls.userName}`}>
                  <div style={{ marginLeft: 10, width: 20 }}>{i.id}</div>
                  <Avatar src={i.image} className={cls.avatar} />
                  <span style={{ cursor: 'pointer' }}>{`${i.name} ${
                    i.last_name || ''
                  }`}</span>
                </td>
                <td style={{ flex: 1 }}>{i.email}</td>
                <td style={{ flex: 1 }}>{i.phone}</td>
                <td style={{ flex: 1 }}>
                  {
                    <FormControl>
                      <Select
                        displayEmpty
                        disableUnderline={true}
                        value={i.status}
                        // className={cls.selectBox}
                        onChange={(e) =>
                          handleChangeStatus(i.id, e.target.value)
                        }
                        inputProps={{ 'aria-label': 'Without label' }}>
                        <MenuItem selected disabled value="">
                          Status
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                          Registered
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          Active
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                          Deactive
                        </MenuItem>
                        <MenuItem key={0} value={0}>
                          Suspended
                        </MenuItem>
                        {/* <MenuItem key={3} value={3}>
                          {3}
                        </MenuItem> */}
                      </Select>
                    </FormControl>
                  }
                </td>
                <td style={{ flex: 1 }}>{renderTableActions(i)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
