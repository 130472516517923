import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  filterContainer: {
    // width: '100%',
  },
  card: {
    position: 'absolute',
    padding: '0px 20px 20px',
    display: 'flex',
    width: '280px',
    right: '108px',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: 450,
    // overflow: 'auto',
    borderRadius: 5,
    boxShadow: '0px 0px 10px 0 rgb(0 0 0 / 43%)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    marginBottom: 20,
    zIndex: 3,
    ['@media (max-width:760px)']: {
      right: '25px',
    },
  },
  cardTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  optionsBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  listSpan: {
    background: '#f8f8f8',
    overflow: 'auto',
    height: '300px',
  },
  actionsSpan: {
    display: 'flex',
    padding: '0px 10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ededed',
    '&:hover': {
      background: '#ebebeb',
    },
  },
  dragdropIconTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    fontSize: '12px',
    fontFamily: 'Averta Light',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 13,
  },
  row2: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  select: {
    width: '-webkit-fill-available',
    fontFamily: 'Averta Light',
    '& > div': {
      border: 'solid 1px #707070',
    },
    '& > div:first-child > div:first-child': {
      padding: '2px 8px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  date: {
    boxSizing: 'border-box',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    border: 'solid 1px #707070',
    padding: '0 10px',
    '& input': {
      color: 'hsl(0,0%,50%) !important',
      padding: 0,
      cursor: 'pointer',
      fontFamily: 'Averta Light',
      fontSize: '15px !important',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
    '& > div': {
      fontSize: 13,
    },
  },
  searchIcon: {
    width: '18px',
    height: '18px',
    fill: '#E0E0E0',
    paddingRight: '10px',
  },
  searchInput: {
    '&::placeholder': {
      color: '#E0E0E0',
    },
  },
  itemsTopTitle: {
    color: '#b6b6b6',
    margin: '0px 0px 5px',
    fontSize: '12px',
  },
  search: {
    display: 'flex',
    alignItems: 'center',
  },
  searchBox: {
    height: '28px',
    background: '#ffffff',
    border: '1px solid #E0E0E0',
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    boxSizing: 'border-box',
    // margin: '0 16px',
    '& input': {
      border: 'none',
      background: 'transparent',
      flex: '1',
      '&:focus-visible': {
        border: 'none !important',
        outline: 'none',
      },
    },
  },

  loc: {
    '& svg': {
      fill: '#f8b100',
    },

    fontFamily: 'Averta Light',
    width: '-webkit-fill-available',
    // marginLeft: 15,
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& > div': {
      border: 'solid 1px #707070',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '@media (max-width:900px)': {
      marginLeft: 0,
    },
  },
  ml15: {
    marginLeft: 15,
  },
  input: {
    fontFamily: 'Averta Light',
    width: '-webkit-fill-available',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #707070',
    color: 'hsl(0,0%,50%) !important',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
      '&::placeholder': {
        color: '#000000',
      },
    },
    '& > div': {
      fontSize: 13,
    },
  },

  but: {
    width: 100,
    height: 30,
    borderRadius: 5,
    backgroundColor: '#9e9e9e !important',
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    color: '#fff',
    padding: 0,
    marginTop: 15,
  },
  btnsSpan: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    paddingTop: '18px',
  },
  close: {
    position: 'absolute',
    top: 10,
    left: 10,
    height: 20,
    cursor: 'pointer',
    fill: '#e4464b',
  },
  filterRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  row3: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end',
    ['@media (max-width:760px)']: {
      marginTop: 25,
      marginBottom: 10,
    },
  },
  dragdropIcon: {
    height: 20,
    width: 20,
    fill: '#9f9f9f',
  },

  filter: {
    height: 20,
    width: 20,
    fill: '#9f9f9f',
    margin: 'auto auto auto 10px',
  },
  title: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1,
    color: '#43424b',
  },
  headerBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '18px',
    '@media (max-width:900px)': {
      flexDirection: 'column',
      margin: 10,
    },
  },
});
