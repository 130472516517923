import React from 'react';
import {
  AccountCircle,
  QueryBuilder,
  PhoneAndroid,
  Notifications,
} from '@material-ui/icons';
import { fTimeDate } from '@/actions/Helper';
import styles from './styles';
import { useSelector } from 'react-redux';

export default ({ DATA = {}, Chat, selectedUser }) => {
  const hasNewMessages = useSelector(
    (state) => state.reducer.whatsappNewMessages
  );
  console.log('selectedUserinjaaaaaaaa', selectedUser);
  const cls = styles({
    black: Boolean(!DATA.seen),
  });

  return (
    <div className={DATA.from === selectedUser ? cls.cardSelected : cls.card}>
      <div className={cls.row} onClick={() => Chat(DATA.from)}>
        <div className={cls.f2}>
          <div>
            <div className={cls.row2}>
              <AccountCircle className={cls.icon} />
              <span className={cls.light}>{DATA.from_name}</span>
            </div>
            <div className={cls.row2}>
              <PhoneAndroid className={cls.icon} />
              <span className={cls.light}>{DATA.from}</span>
            </div>
            <div className={cls.row2}>
              <QueryBuilder className={cls.icon} />
              <span className={cls.light}>{fTimeDate(DATA.updated_at)}</span>
            </div>
          </div>
        </div>
        {hasNewMessages.find((element) => element.by === DATA.from) && (
          <Notifications style={{ color: '#ffffff' }} />
        )}
      </div>
    </div>
  );
};
