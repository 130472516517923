import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  popup: {
    // position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
  },

  card: {
    width: '700px',
    position: 'relative',
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  loading: {
    height: '100%',
    margin: 0,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  row2: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  select: {
    fontFamily: 'Averta Light',
    width: '100%',
    '& > div': {
      border: 'solid 1px #707070',
    },
    '& > div:first-child > div': {
      padding: '0 0 0 10px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  date: {
    boxSizing: 'border-box',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    border: 'solid 1px #707070',
    padding: '0 10px',
    '& input': {
      fontFamily: 'Averta Light',
      padding: 0,
      cursor: 'pointer',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
  },
  dateWithBox: {
    boxSizing: 'border-box',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    // border: 'solid 1px #707070',
    padding: '0 10px',
    '& input': {
      fontFamily: 'Averta Light',
      padding: 0,
      cursor: 'pointer',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
  },

  loc: {
    '& svg': {
      fill: '#f8b100',
    },

    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& > div': {
      border: 'solid 1px #707070',
    },
  },
  containerInput: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  containerBadge: {
    background: '#e5e5e5',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: '0 14px',
    whiteSpace: 'nowrap',
    width: '70px',
    minWidth: '70px',
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    border: 'solid 1px #707070',
    '& input': {
      fontFamily: 'Averta Light',
      padding: '5px 10px',
    },
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: '#e4464b',
  },
  but: {
    width: 100,
    height: 38,
    borderRadius: 5,
    backgroundColor: '#007fff',
    fontFamily: 'Averta Light',
    fontSize: 14,
    fontWeight: 300,
    color: '#fff',
    padding: 0,
    marginLeft: 'auto',
    marginTop: 20,
    '&:hover': {
      backgroundColor: '#007fff',
    },
  },
}));
