import React from 'react';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createTheme } from '@material-ui/core';
import { EventRounded } from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/styles';
import { enUS } from 'date-fns/locale';


const materialTheme = createTheme({
  overrides: {
    MuiPickersModal: {
      dialogRoot: {
        '& *': {
          fontFamily: 'Averta !important',
        },
      },
    },
    MuiFormControl: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiSvgIcon: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#f8b100',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#f8b100',
      },
    },
  },
});

export default (props) => (
  <ThemeProvider theme={materialTheme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={{ ...enUS, options: { weekStartsOn: 1 } }}>
      <DatePicker
        InputProps={{ disableUnderline: true, endAdornment: <EventRounded /> }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);
