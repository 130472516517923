import React, { useEffect, useState } from 'react';
import styles from './styles';
import Select from 'react-select';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Modal,
  Box,
  Button,
} from '@material-ui/core';
import {
  adminChangeFTPIntegration,
  adminChangeTmsTypeExport,
  adminGetSingleUser,
  adminGetTmsList,
  getProfile,
} from '@/actions';
import closeIcon from '../../../assets/img/closeIcon.svg';
import EmailForm from './EmailForm';

export default ({
  companies,
  notify,
  setActiveTms,
  activeTms,
  state,
  fetchTablesData,
  tmsType,
  setTmsType,
  selectedCompany,
  tmsExcelMode,
  setTmsExcelMode,
}) => {
  const cls = styles();

  const [loadingTMS, setLoadingTMS] = useState(false);
  const [tmsPopUp, setTmsPopUp] = useState(false);
  const [tmsList, setTmsList] = useState([]);

  const makeid = (length) => {
    let result = '';
    let characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleToggleFTP = (e) => {
    setLoadingTMS(true);
    setActiveTms(e.target.checked);
    let data = {
      user_id: state.DATA.id,
      ftp_password: state.DATA.ftp_password || makeid(10),
      tms_type: tmsType.value,
      disable: !e.target.checked,
    };
    adminChangeFTPIntegration(data)
      .then((res) => {
        if (res.message === 'success') {
          notify('successfully updated', 'success');
          notify('successfully updated', 'success');
        }
        setLoadingTMS(false);
      })
      .catch((err) => {
        notify('err', 'warning');
        setLoadingTMS(false);
      });
  };

  const handleSelectTmsType = (tmsType) => {
    adminChangeTmsTypeExport(tmsType.value, selectedCompany.value).then(() => {
      setTmsType(tmsType);
    });
  };
  useEffect(() => {
    adminGetTmsList()
      .then((data) => {
        let options = data.map((item) => {
          return {
            value: item.slug,
            label: item.name,
          };
        });
        setTmsList(options);
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  }, []);

  return (
    <div className={cls.card}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Select
            isClearable
            placeholder="List of TMS"
            className={cls.select}
            value={tmsType}
            onChange={(item) => handleSelectTmsType(item)}
            options={tmsList}
          />
        </Grid>
        {tmsType && (
          <Grid item xs={12} sm={6}>
            <Select
              isClearable
              placeholder="Excel Mode"
              className={cls.select}
              value={tmsExcelMode}
              onChange={(item) => setTmsExcelMode(item)}
              options={[
                {
                  label: 'Email',
                  value: 'Email',
                },
                {
                  label: 'FTP',
                  value: 'FTP',
                },
              ]}
            />
          </Grid>
        )}
      </Grid>
      <div
        className={`${cls.infoContainer} ${
          activeTms ? cls.blackText : cls.grayText
        }`}>
        {state.loading ? (
          <div className={cls.loaderContainer}>
            <CircularProgress className={cls.loader} />
          </div>
        ) : (
          <>
            {tmsExcelMode && tmsExcelMode.value === 'Email' && (
              <EmailForm
                notify={notify}
                selectedCompany={selectedCompany}
                DATA={state.DATA}
              />
            )}
            {tmsExcelMode && tmsExcelMode.value === 'FTP' && (
              <>
                <div className={cls.info}>
                  <span className={cls.infoTitle}>FTP Username:</span>
                  <span className={cls.infoDesc}>
                    {state.DATA.ftp_username || ''}
                  </span>
                </div>
                <div className={cls.info}>
                  <span className={cls.infoTitle}>FTP Password:</span>
                  <span className={cls.infoDesc}>
                    {state.DATA.ftp_password || ''}
                  </span>
                </div>
                <div className={cls.info}>
                  <span className={cls.infoTitle}>Server Address:</span>
                  <span className={cls.infoDesc}>
                    {state.DATA.ftp_username
                      ? process.env.REACT_APP_FTP_URL
                      : ''}
                  </span>
                </div>
                <div className={cls.info}>
                  <span className={cls.infoTitle}>Server Port:</span>
                  <span className={cls.infoDesc}>
                    {state.DATA.ftp_username ? '21' : ''}
                  </span>
                </div>
                {/* <div>
                  {loadingTMS ? (
                    <div className={cls.loaderContainer}>
                      <CircularProgress className={cls.loader} />
                    </div>
                  ) : (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={activeTms}
                          className={cls.checkbox}
                          onChange={(e) => {
                            if (Object.keys(state.DATA).length === 0) {
                              notify('Please Select Company ');
                            } else {
                              handleToggleFTP(e);
                            }
                          }}
                        />
                      }
                      label={activeTms ? 'TMS is Active' : 'TMS is Deactive'}
                    />
                  )}
                </div> */}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
