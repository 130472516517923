import React from 'react';
import { useNotify, Loading } from 'react-admin';
import { Filter } from '@/components/Global';
import {
  getGateSchedule,
  getPortsList,
  getScrapeGateList,
  getScrapeRecivablesList,
  getScrapeVesselsList,
} from '@/actions';
import List from './List';
import styles from './styles';
import Pagination from '@/components/pagination';
import Add from './Add';

export default (props) => {
  const cls = styles();
  const notify = useNotify();
  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    pages: [],
  });
  const [loading, setLoading] = React.useState(true);
  const [DATATable, setDataTable] = React.useState({});
  const [DATATableRecivable, setDataTableRecivable] = React.useState({});
  const [DATATableVessels, setDataTableVessels] = React.useState({});
  const [dataTableVesselsTerminal, setDataTableVesselsTerminal] =
    React.useState({});
  const [dataTableVesselsSsl, setDataTableVesselsSsl] = React.useState({});
  const [PortsList, setPortsList] = React.useState({});
  const [PortId, setPortId] = React.useState(null);
  const [filter, setFilter] = React.useState({
    open: false,
    DATA: [],
  });

  // Format the date to separate day, abbreviated month, and year
  function formatDate(date, format) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dayName = dayNames[date.getDay()];
    const monthNumber = String(monthIndex + 1).padStart(2, '0'); // Add leading zero if necessary
    const month = monthNames[monthIndex];
    if (format == 1) {
      return `${year}-${monthNumber}-${day}`;
    } else {
      return { day, dayName, monthNumber, month, year };
    }
  }

  // Print the dates of the current week
  function getCurrentWeekDates() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const startDate = new Date(today);
    startDate.setDate(startDate.getDate() - dayOfWeek);
    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      weekDates.push(date);
    }
    return weekDates;
  }

  const weekDates = getCurrentWeekDates();
  const formattedCurrentWeekDates = weekDates.map((date) =>
    formatDate(date, 1)
  );

  const getGateScheduleList = () => {
    // setLoading(true);
    getScrapeGateList('LA/LB')
      .then((res) => {
        if (res) {
          setDataTable(res.data.scrapeSettings);
        }
        let keys = Object.keys(DATATable);

        if (keys.length > 0) {
          setLoading(false);
        }
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  const getRecivablesScheduleList = () => {
    // setLoading(true);
    getScrapeRecivablesList('LA/LB')
      .then((res) => {
        if (res) {
          setDataTableRecivable(res.data.scrapeSettings);
        }
        let keys = Object.keys(DATATable);

        if (keys.length > 0) {
          setLoading(false);
        }
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  const getVesselsScheduleList = () => {
    // setLoading(true);
    getScrapeVesselsList('LA/LB')
      .then((res) => {
        if (res) {
          setDataTableVessels(res.data.scrapeSettings);
        }
        let keys = Object.keys(DATATable);

        if (keys.length > 0) {
          setLoading(false);
        }
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  const getVesselsScheduleListTerminal = () => {
    const source = `&source=Terminal`;
    // setLoading(true);
    getScrapeVesselsList('LA/LB', source)
      .then((res) => {
        if (res) {
          setDataTableVesselsTerminal(res.data.scrapeSettings);
        }
        let keys = Object.keys(DATATable);

        if (keys.length > 0) {
          setLoading(false);
        }
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  const getVesselsScheduleListSsl = () => {
    const source = `&source=SSL`;
    // setLoading(true);
    getScrapeVesselsList('LA/LB', source)
      .then((res) => {
        if (res) {
          setDataTableVesselsSsl(res.data.scrapeSettings);
        }
        let keys = Object.keys(DATATable);

        if (keys.length > 0) {
          setLoading(false);
        }
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const getPortsListFanc = () => {
    // setLoading(true);
    getPortsList()
      .then((res) => {
        setPortsList(Object.values(res.data.ports).map((ports) => ports));
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  React.useEffect(() => {
    // getGateScheduleList();
    // getRecivablesScheduleList();
    // getVesselsScheduleList();
    // getVesselsScheduleListTerminal();
    // getVesselsScheduleListSsl();
    getPortsListFanc();
  }, [pagination.currentPage]);
  return (
    <div id="inventoryEmpty" className={cls.content}>
      <div className={cls.cardHeader}>
        <span className={cls.title}>Scrape Setting</span>
        {/* <span>
          <span className={cls.title}>Export</span>
          <span
            className={cls.titleBlue}
            // onClick={handleExport}
          >
            XLSX
          </span>
        </span> */}
      </div>

      {loading ? (
        <Loading />
      ) : (
        <List
          DATA={DATATable}
          TerminalsList={DATATable}
          DATATableRecivable={DATATableRecivable}
          DATATableVessels={DATATableVessels}
          dataTableVesselsSsl={dataTableVesselsSsl}
          dataTableVesselsTerminal={dataTableVesselsTerminal}
          PortsList={PortsList}
          getGateScheduleList={getGateScheduleList}
          getRecivablesScheduleList={getRecivablesScheduleList}
          getVesselsScheduleList={getVesselsScheduleList}
          getVesselsScheduleListTerminal={getVesselsScheduleListTerminal}
          getVesselsScheduleListSsl={getVesselsScheduleListSsl}
        />
      )}
    </div>
  );
};
