import {
  SaveData,
  SaveNotif,
  adminChangeWhatsappReplayStatus,
  adminEditWhatsappReplay,
  adminGetWhatsappUserList,
  adminGetWhatsappUserMessages,
  aiWhatsapp,
  aiWhatsappGroupList,
} from '@/actions';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';

import List from './List';
import Messages from './Messages';
import styles from './styles';
import DateInput from '@/components/DateInput';
import useMediaQuery from 'use-mediaquery';
import { Loading, useNotify } from 'react-admin';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Tooltip, Grid, TextField, Button } from '@material-ui/core';
import ListEmpty from './ListEmpty';
import { Filter } from '@/components/Global';

export default () => {
  const [state, setState] = React.useState({
    DATA: [],
    loading: true,
  });

  const [loading, setLoading] = React.useState(false);
  const [whatsAppGroupList, setWhatsAppGroupList] = useState([]);
  const [whatsAppGroupListOpt, setWhatsAppGroupListOpt] = useState([]);

  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [searchingChat, setSearchingChat] = useState(null);
  const [dataTypeChange, setdataTypeChange] = useState('export');
  const [selectedGroup, setSelectedGroup] = useState();
  // const [formData, setFormData] = React.useState({
  //   groups: [],
  //   type: null,
  //   from: null,
  //   to: null,
  // });

  const [form, setForm] = React.useState({
    from: null,
    to: null,
  });

  console.log('formData', form);
  console.log('selected To', selectedToDate);
  const [messages, setMessages] = useState({
    loading: false,
    currentPage: 1,
    totalPage: 1,
    DATA: [],
  });
  const [filter, setFilter] = React.useState  ({
    open: false,
    DATA: [],
  });

  const cls = styles();
  const notify = useNotify();

  const getAiWhatsappGroupList = () => {
    setLoading(true);
    aiWhatsappGroupList()
      .then((res) => {
        // console.log(res);
        setWhatsAppGroupList(res.data.groups);

        setWhatsAppGroupListOpt(
          res.data.groups.map((group, index) => ({
            label: group.name,
            id: group.id,
            value: index + 1,
          }))
        );
        // setSelectedGroup(whatsAppGroupListOpt[0].id)
      })
      .catch((err) => {
        console.log(err);
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const getAiWhatsappFanc = () => {
    const formToApiType = dataTypeChange ? `?type=${dataTypeChange}&` : '?';
    // const formToApifrom = formData.from ? '?from=' + formData.from : '';
    const formToApigroup = selectedGroup?.id
      ? 'groups[]=' + [selectedGroup?.id]
      : '';

    setLoading(true);
    aiWhatsapp(formToApiType, formToApigroup)
      .then((res) => {
        setMessages(res.data.messages.data);
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  useEffect(() => {
    getAiWhatsappFanc();
  }, [selectedGroup, dataTypeChange]);

  useEffect(() => {
    getAiWhatsappGroupList();
    getAiWhatsappFanc();
  }, []);
  useEffect(() => {
    setSelectedGroup(whatsAppGroupListOpt[1]);
  }, [whatsAppGroupListOpt]);

  return (
    <div id="inventoryEmpty" className={cls.content}>
      <div className={cls.card}>
        <Grid container spacing={2}>
          {/* <Grid className={cls.buttonsDiv} item xs={12} sm={12} lg={12}> */}
          {/* <div className={cls.containerActions}> */}
          <Grid item xs={12} sm={4} lg={3}>
            <div className={cls.containerRowRound}>
              <span
                onClick={() => setdataTypeChange('export')}
                className={clsx(
                  cls.chassisText,
                  dataTypeChange == 'export' && cls.activeChassis
                )}>
                Empty
              </span>
              <span
                onClick={() => setdataTypeChange('empty')}
                className={clsx(
                  cls.chassisText,
                  dataTypeChange == 'empty' && cls.activeChassis
                )}>
                Export
              </span>
              <span
                onClick={() => setdataTypeChange('')}
                className={clsx(
                  cls.chassisText,
                  dataTypeChange == '' && cls.activeChassis
                )}>
                All
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`From `}</span>
              <DateInput
                placeholder=""
                className={cls.date}
                format="MM/dd/yyyy"
                value={form.from}
                onChange={(item) => setForm('from', item)}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`To `}</span>
              <DateInput
                placeholder=""
                className={cls.date}
                format="MM/dd/yyyy"
                value={form.to}
                onChange={(item) => {
                  setSelectedToDate(item);
                  setForm('to', item);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`Group`}</span>
              <Select
                placeholder="Group"
                className={clsx(cls.select)}
                value={selectedGroup}
                onChange={(item) => {
                  // setForm('ssl', item);
                  setSelectedGroup(item); // Set selected SSL state
                }}
                options={whatsAppGroupListOpt}
              />
            </div>
          </Grid>
          {/* </div> */}
          {/* </Grid> */}
        </Grid>
      </div>
      <Grid className={cls.filterSection} item xs={12} sm={12} lg={12}>
        <Filter
          title="WhatsApp Messages"
          filter={filter}
          setFilter={setFilter}
          whatsappCompany={1}
          whatsappDatePosted={2}
          whatsappBy={3}
          // DATA={DATA}
        ></Filter>
      </Grid>
      <Grid className={cls.chatContainer} item xs={12} sm={12} lg={12}>
        {loading ? (
          <Loading />
        ) : (
          messages && (
            <ListEmpty
              DATA={messages}
              formData={form}
              messages={messages}
              dataTypeChange={dataTypeChange}
              getAiWhatsappFanc={getAiWhatsappFanc}
            />
          )
        )}
      </Grid>
    </div>
  );
};
