import React from 'react';
import { useNotify, Loading } from 'react-admin';
import { GetAppRounded } from '@material-ui/icons';
import { Filter } from '@/components/Global';
import { ParseExportInventoryList } from '@/actions/ParseData';
import { noScroll, removeNoScroll } from '@/actions/Helper';
import {
  getExportInventory,
  // upExportInventory,
  delExportInventory,
  importExportInventory,
  upBooking,
  getTableList,
} from '@/actions';

import Add from './Add';
import List from './List';
// import Edit from './Edit';
import styles from './styles';
import Pagination from '@/components/pagination';
import Edit from './Edit';
import Options from '@/components/Global/Options';

export default (props) => {
  const cls = styles();
  const notify = useNotify();
  const [state, setState] = React.useState({
    edit: null,
    file: null,
  });
  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    pages: [],
  });
  const [loading, setLoading] = React.useState(true);
  const [DATA, setData] = React.useState([]);
  const [filter, setFilter] = React.useState({
    open: false,
    DATA: [],
  });
  const [options, setOptions] = React.useState({
    open: false,
    DATA: [],
  });

  const [tableTheadTitle, settableTheadTitle] = React.useState([]);

  const getInventoryTableHeadList = () => {
    setLoading(true);

    getTableList('exportTable')
      .then((res) => {
        if (res.data?.settings?.data) {
          settableTheadTitle(res.data?.settings?.data);
        } else {
          settableTheadTitle([
            // { label: '#', flex: 0.4, "show":  true  },
            { label: 'Size', flex: 1, show: true, object: 'size' },
            { label: 'SSL', flex: 1.2, show: true, object: 'ssl' },
            {
              label: 'Booking #',
              flex: 1,
              show: true,
              object: 'name',
            },
            {
              label: 'Container #',
              flex: 1.2,
              show: true,
              object: 'container',
            },
            // {
            //   label: 'Chassis #',
            //   flex: 1,
            //   show: true,
            //   object: 'chassis',
            // },
            {
              label: 'Location',
              flex: 1.2,
              show: true,
              object: 'loc',
            },
            {
              label: 'Empty Released',
              flex: 1.3,
              show: true,
              object: 'gated_out',
            },
            {
              label: 'Gated In',
              flex: 1.2,
              show: true,
              object: 'gated_in',
            },
            { label: 'Vessel', flex: 1.2, show: true, object: 'vessel_name' },
            {
              label: 'voyage',
              flex: 1,
              show: true,
              object: 'vessel_voyage_in',
            },
            {
              label: 'Terminal Apt.',
              flex: 1.2,
              show: true,
              object: 'appointment_date',
            },
            {
              label: 'ERD',
              flex: 1.2,
              show: true,
              object: 'erd',
            },
            {
              label: 'Cutoff',
              flex: 1.2,
              show: true,
              object: 'cutoff',
            },
            // { label: 'MTO', flex: 1, show: true, object: 'slug' },
            {
              label: 'Pickup Location',
              flex: 1,
              show: true,
              object: 'pick_up_location',
            },
            {
              label: 'Terminal Return',
              flex: 1.1,
              show: true,
              object: 'return_to_terminal',
            },
            {
              label: 'Vessel Departure',
              flex: 1.2,
              show: true,
              object: 'vessel_departure',
            },
            {
              label: 'Available Date',
              flex: 1.2,
              show: true,
              object: 'date',
            },
            {
              label: 'Last Update',
              flex: 1.5,
              show: true,
              object: 'up',
            },
            // { label: 'Action', flex: 1.2 , "show":  true, object: '' },
          ]);
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getInventoryTableHeadList();
  }, []);

  React.useEffect(() => {
    if (state.file) {
      setLoading(true);
      importExportInventory(state.file)
        .then((res) => {
          let DATA = ParseExportInventoryList(res);
          setData(DATA);
          setFilter((f) => ({ ...f, DATA }));
          setLoading(false);
        })
        .catch((err) => {
          notify(err, 'warning');
          setData([]);
          setFilter((f) => ({ ...f, DATA: [] }));
          setLoading(false);
        });
    }
  }, [state.file, notify]);

  const Del = (id) =>
    delExportInventory(id)
      .then((msg) => {
        notify(msg);
        getExportInventory()
          .then((res) => {
            let DATA = ParseExportInventoryList(res.data);
            setData(DATA);
            setFilter({ ...filter, DATA });
            setLoading(false);
          })
          .catch((err) => notify(err, 'warning'));
      })
      .catch((err) => notify(err, 'warning'));

  const openPopUp = (item, bookingId, booking) => {
    noScroll();
    setState({ ...state, edit: item, bookingId: bookingId, booking: booking });
  };

  const Update = (form, inventory_id, containers) => {
    // for (let el in form) {
    // if (!form[el]) {
    //   notify('Can not add, Please check fields.', 'warning');
    //   return;
    // }
    // }

    return upBooking(form, state.bookingId)
      .then((res) => {
        notify('Updated Successfully.');
        getExportInventoryList();
        setFilter({ ...filter, DATA });
        closePopup();
      })
      .catch((err) => notify(err, 'warning'));
    //   updateInventoriContainers(inventory_id, { containers }).then(() => {
    // })
  };

  const closePopup = () => {
    removeNoScroll();
    setState({ ...state, edit: null });
  };

  const handleExport = () => {};

  const getExportInventoryList = () => {
    setLoading(true);
    getExportInventory({ page: pagination.currentPage })
      .then((res) => {
        let DATA = ParseExportInventoryList(res.data);
        let pages = [];
        for (let i = 1; i <= res.last_page; i++) {
          pages.push(i);
        }
        setPagination((prev) => {
          return {
            ...prev,
            pages,
          };
        });
        setData(DATA);
        setFilter({ open: false, DATA });
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  const getExportInventoryListNotLoading = () => {
    getExportInventory({ page: pagination.currentPage })
      .then((res) => {
        let DATA = ParseExportInventoryList(res.data);
        let pages = [];
        for (let i = 1; i <= res.last_page; i++) {
          pages.push(i);
        }
        setPagination((prev) => {
          return {
            ...prev,
            pages,
          };
        });
        setData(DATA);
        setFilter({ open: false, DATA });
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getExportInventoryList();
  }, [pagination.currentPage]);
  console.log('state', state.booking);

  return (
    <div id="inventoryExport" className={cls.content}>
      {state.edit && (
        <Edit
          data={state.edit}
          bookingId={state.bookingId}
          Close={closePopup}
          Update={Update}
          booking={state.booking}
        />
      )}
      <div className={cls.cardHeader}>
        <span className={cls.title}>Add Export</span>
        <div className={cls.row}>
          <span className={cls.title}>Import</span>
          <label className={cls.import}>
            <input
              type="file"
              style={{ display: 'none' }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => {
                setState({ ...state, file: e.target.files[0] });
                e.target.value = null;
              }}
            />
            <GetAppRounded />
            <span>XLSX</span>
          </label>
        </div>
      </div>

      <Add
        notify={notify}
        setData={(res) => {
          let DATA = ParseExportInventoryList(res);
          setData(DATA);
          setFilter({ ...filter, DATA });
        }}
        getExportInventoryList={getExportInventoryList}
      />
      <span className={cls.tableTopActions}>
        <Filter
          title="Your Export"
          filter={filter}
          setFilter={setFilter}
          DATA={DATA}>
          <span className={cls.title}>Export</span>
          <span className={cls.titleBlue} onClick={handleExport}>
            XLSX
          </span>
          <Options
            tableTheadTitle={tableTheadTitle}
            tableType={'exportTable'}
            options={options}
            setOptions={setOptions}></Options>
        </Filter>
      </span>
      {loading ? (
        <Loading />
      ) : (
        <List
          DATA={filter.DATA}
          Del={Del}
          Edit={openPopUp}
          getExportInventoryList={getExportInventoryList}
          getExportInventoryListNotLoading={getExportInventoryListNotLoading}
          currentPage={pagination.currentPage}
          tableTheadTitle={tableTheadTitle}
        />
      )}
      <Pagination pagination={pagination} setPagination={setPagination} />
    </div>
  );
};
