import React, { useState, useEffect, version } from 'react';
import Profile from './Profile';
import styles from './styles';
import { Button, Grid } from '@material-ui/core';
import Refer from './Refer';
import { useNotify, Loading } from 'react-admin';

import TerminalCredentials from './TerminalCredentials';
import { useSelector } from 'react-redux';
import { getTerminalsCredList } from '@/actions';
import StreetTurnPreferences from './StreetTurnPreferences';

export default () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();

  const [terminalsListRes, setTerminalsListRes] = useState([]);
  const [terminalsCredRes, setTerminalsCredRes] = useState([]);
  const cls = styles();
  const enable_login_credentials = useSelector(
    (state) => state.reducer.user?.metas?.enable_login_credentials?.value
  );

  const getTerminalsListCredFanc = () => {
    setLoading(true);
    getTerminalsCredList()
      .then((res) => {
        setTerminalsListRes(res.data?.default_list);
        setTerminalsCredRes(res.data?.user_credentials);
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  useEffect(() => {
    getTerminalsListCredFanc();
  }, []);

  const appVersion = 'v1.9.1';
  const profileTabItems = [
    {
      title: 'Profile',
      content: <Profile />,
    },
    {
      title: 'Preferences',
      content: (
        <StreetTurnPreferences
          setSelectedTab={setSelectedTab}
          // getTerminalsListCredFanc={getTerminalsListCredFanc}
          // terminalsListRes={terminalsListRes}
          // terminalsCredRes={terminalsCredRes}
        />
      ),
    },
    {
      title: 'Terminal Credentials',
      content: (
        <TerminalCredentials
          setSelectedTab={setSelectedTab}
          getTerminalsListCredFanc={getTerminalsListCredFanc}
          terminalsListRes={terminalsListRes}
          terminalsCredRes={terminalsCredRes}
        />
      ),
    },
    {
      title: 'Referral',
      content: <Refer setSelectedTab={setSelectedTab} />,
    },
  ];
  return (
    <div>
      <div className={cls.profileTabs}>
        <Grid container className={cls.gridContainer} spacing={2}>
          {profileTabItems.map((item, index) =>
            enable_login_credentials === '0' &&
            item.title === 'Terminal Credentials' ? (
              ''
            ) : enable_login_credentials === null &&
              item.title === 'Terminal Credentials' ? (
              ''
            ) : enable_login_credentials === undefined &&
              item.title === 'Terminal Credentials' ? (
              ''
            ) : (
              <Grid item xs={'auto'} sm={'auto'} lg={'auto'}>
                <Button
                  className={
                    selectedTab === index
                      ? cls.activeProfileTabsBtn
                      : cls.profileTabsBtn
                  }
                  onClick={() => setSelectedTab(index)}
                  key={index}>
                  {item.title}
                </Button>
              </Grid>
            )
          )}
          <Grid item xs={'auto'} sm={'auto'} lg={'auto'}>
            <a
              target="_blank"
              href={`https://apps.microsoft.com/detail/xpdllq9q7xvjlp`}>
              <Button className={cls.winAppDownload}>
                Download Windows App
              </Button>
            </a>
          </Grid>
        </Grid>
      </div>
      <div>{profileTabItems[selectedTab].content}</div>
    </div>
  );
};
