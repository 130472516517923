import React, { forwardRef, useCallback } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { MenuItem, ListItemIcon, Tooltip } from '@material-ui/core';
import styles from './styles';
import { useLocation } from 'react-router-dom';

const NavLinkRef = forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

export default forwardRef(({ leftIcon, onClick, sidebarIsOpen }, ref) => {
  const cls = styles();
  const handleMenuTap = useCallback((e) => onClick && onClick(e), [onClick]);

  const { pathname } = useLocation();

  const renderMenuItem = () => (
    <MenuItem
      className={clsx(sidebarIsOpen ? cls.open : cls.close)}
      activeClassName={cls.active}
      component={NavLinkRef}
      ref={ref}
      to="/terminal/admin"
      onClick={handleMenuTap}>
      <ListItemIcon className={cls.listItemIcon}>{leftIcon}</ListItemIcon>
      <span className="DashboardItemText">Schedule</span>

      <div className="RequestResource" style={{ width: '100%' }}>
        <div className={cls.child}>
          <MenuItem
            className={cls.item}
            activeClassName={cls.itemActive}
            component={NavLinkRef}
            ref={ref}
            to={
              pathname === '/terminal/admin'
                ? '/terminal/admin'
                : '/terminal/admin/GateSchedule'
            }
            onClick={handleMenuTap}>
            Gate
          </MenuItem>
          <MenuItem
            className={cls.item}
            activeClassName={cls.itemActive}
            component={NavLinkRef}
            ref={ref}
            to={'/terminal/admin/ReceivablesSchedule'}
            onClick={handleMenuTap}>
            Receivables
          </MenuItem>
          <MenuItem
            className={cls.item}
            activeClassName={cls.itemActive}
            component={NavLinkRef}
            ref={ref}
            to={'/terminal/admin/vessel'}
            onClick={handleMenuTap}>
            Vessel
          </MenuItem>
          <MenuItem
            className={cls.item}
            activeClassName={cls.itemActive}
            component={NavLinkRef}
            ref={ref}
            to={'/terminal/admin/scrape/setting'}
            onClick={handleMenuTap}>
            Setting
          </MenuItem>
        </div>
      </div>
    </MenuItem>
  );

  if (sidebarIsOpen) return renderMenuItem();

  return (
    <Tooltip arrow title="Requests" placement="right">
      {renderMenuItem()}
    </Tooltip>
  );
});
