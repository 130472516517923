import React, { useEffect, useState } from 'react';
import { useNotify, Loading } from 'react-admin';
import { Button, TextField, Tooltip, Grid } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { locationOpt, _get } from '@/actions/Helper';
import DateInput from '@/components/DateInput';

import styles from './editStyles';
import { getTerminalsList, updateInventoriContainers } from '@/actions';

export default ({ Update, Close, data, bookingId, booking }) => {
  const cls = styles();
  const [loading, setLoading] = React.useState(false);
  const Sizes = useSelector((state) => state.reducer.size);
  const SSLs = useSelector((state) => state.reducer.ssl);
  const [terminalsList, setTerminalsList] = useState([]);
  const [terminalsSelectOptions, setTerminalsSelectOptions] = useState([]);
  const notify = useNotify();

  const parseData = ({ id, size, ssl, date, count_all, address }) => ({
    id,
    date,
    count: count_all,
    size: Sizes.find(({ label }) => label === size),
    ssl: SSLs.find(({ label }) => label === ssl),
    erd: booking?.additional.erd ?? null,
    cutoff: booking?.additional.cutoff ?? null,
    mto: { value: booking?.mto?.id, label: booking?.mto?.slug } ?? null,
    appointment_date: booking?.additional?.appointment_date ?? null,
    count_all: data.count_all,
    count_booked: data.count_booked,
    booking: booking,
    bookings: data.bookings,
    bookingNumber: data.bookings[0]?.name,
    loc: {
      '& svg': {
        fill: '#f8b100',
      },

      label: _get(address, 'address', ''),
      value: {
        lng: _get(address, 'long', ''),
        lat: _get(address, 'lat', ''),
        address: _get(address, 'address', ''),
        city: _get(address, 'city', ''),
      },
    },
  });
  const getTerminalsListFanc = () => {
    setLoading(true);
    getTerminalsList()
      .then((res) => {
        const terminalsRes = Object.values(res.data.terminals);
        if (terminalsRes) {
          setTerminalsList(terminalsRes); // Set the state directly
        }
        setLoading(false);
      })
      .catch((err) => {
        // notify(err, 'warning');
        setLoading(false);
      });
  };
  useEffect(() => {
    getTerminalsListFanc();
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    if (terminalsList.length > 0) {
      const options = terminalsList.map((terminal, index) => ({
        label: terminal.name,
        slug: terminal.slug,
        value: index + 1,
      }));

      // Set portSelectOptions state
      setTerminalsSelectOptions(options);
    }
  }, [terminalsList]); // Dependencies array with portsList
  console.log('bookingId', booking);

  const [form, setFormData] = React.useState({
    id: bookingId,
    booking: booking,
    size: null,
    ssl: null,
    date: null,
    count: 0,
    loc: null,
    count: '',
    loc: null,
    bookingNumber: null,
    erd: booking?.additional.erd ?? null,
    cutoff: booking?.additional.cutoff ?? null,
    mto: booking?.additional.mto ?? null,
    appointment_date: null,
    chassis: {
      own: true,
      pool: true,
    },
  });
  const [bookings, setBookings] = React.useState(data.bookings);

  React.useEffect(() => {
    setFormData(parseData(data));
  }, [data, Sizes, SSLs]);

  const setForm = (it, value) => setFormData({ ...form, [it]: value });

  const handleUpdateBookings = (name, value, index) => {
    setBookings((prev) => {
      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const submit = () => {
    for (let el in form) {
      // if (!form[el]) {
      //   notify('Can not add, Please check fields.', 'warning');
      //   return;
      // }
    }

    setLoading(true);
    Update(form, data.id, bookings).then(() => setLoading(false));
  };

  const getInventoryBookings = () => {
    let inventoryBookings = [];
    data.bookings.forEach((booking) => {
      if (booking.status !== 2 && inventoryBookings.length < data.count_all) {
        inventoryBookings.push({
          name: booking.name ? booking.name : '',
          id: booking.id ? booking.id : null,
          chases: booking.chases ? booking.chases : '',
          status: booking.status ? booking.status : null,
        });
      }
    });
    setBookings(inventoryBookings);
  };
  console.log('form', form);

  useEffect(() => {
    getInventoryBookings();
  }, []);
  return (
    <div
      id="app_modal"
      onClick={(e) => e.target.id === 'app_modal' && Close()}
      className={cls.popup}>
      <div className={cls.card}>
        {loading ? (
          <Loading className={cls.loading} />
        ) : (
          <>
            <Tooltip arrow title="Close" placement="top">
              <CloseRounded className={cls.close} onClick={Close} />
            </Tooltip>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Select
                  placeholder="Size"
                  className={cls.select}
                  value={form.size}
                  onChange={(item) => setForm('size', item)}
                  options={Sizes}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Select
                  placeholder="SSL"
                  className={cls.select}
                  value={form.ssl}
                  onChange={(item) => setForm('ssl', item)}
                  options={SSLs}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <AsyncSelect
                  placeholder="Location"
                  className={cls.loc}
                  value={form.loc}
                  loadOptions={(e) => locationOpt(e)}
                  onChange={(item) => setForm('loc', item)}
                  cacheOptions
                  defaultOptions
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateInput
                  placeholder="Available date"
                  className={cls.dateNormal}
                  format="MM/dd/yyyy"
                  value={form.date}
                  onChange={(item) => setForm('date', item)}
                />
              </Grid>
              <Grid item xs={12}>
                {form.bookings?.map(
                  (booking, index) =>
                    booking.id === bookingId && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={12}>
                          <span className={cls.bookingsName}>
                            Booking#
                            <hr className={cls.bookingsNameHr}></hr>
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <div className={`${cls.input} ${cls.containerInput}`}>
                            <span
                              className={cls.containerBadge}>{`Booking#`}</span>
                            <TextField
                              type="text"
                              placeholder={``}
                              className={cls.textInput}
                              InputProps={{ disableUnderline: true }}
                              inputProps={{ min: 1 }}
                              defaultValue={booking.name}
                              onChange={(item) =>
                                setForm('bookingNumber', item.target.value)
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <div className={`${cls.input} ${cls.containerInput}`}>
                            <span className={cls.containerBadge}>{`MTO `}</span>
                            <Select
                              placeholder="MTO"
                              className={clsx(cls.select)}
                              value={form.mto}
                              onChange={(item) => setForm('mto', item)}
                              options={terminalsSelectOptions}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <div className={`${cls.input} ${cls.containerInput}`}>
                            <span className={cls.containerBadge}>{`ERD `}</span>
                            <DateInput
                              placeholder="ERD"
                              className={cls.date}
                              format="MM/dd/yyyy"
                              value={form.erd}
                              onChange={(item) => setForm('erd', item)}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <div className={`${cls.input} ${cls.containerInput}`}>
                            <span
                              className={cls.containerBadge}>{`CutOff `}</span>
                            <DateInput
                              placeholder="CutOff"
                              className={cls.date}
                              format="MM/dd/yyyy"
                              value={form.cutoff}
                              onChange={(item) => setForm('cutoff', item)}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <div className={`${cls.input} ${cls.containerInput}`}>
                            <span
                              className={cls.containerBadge}>{`Term Apt`}</span>
                            <DateInput
                              placeholder="Term Apt"
                              className={cls.date}
                              format="MM/dd/yyyy"
                              value={form.appointment_date}
                              onChange={(item) =>
                                setForm('appointment_date', item)
                              }
                            />
                          </div>
                        </Grid>
                        {/* <Grid item xs={12} sm={3} lg={3}>
                          <div className={`${cls.input} ${cls.containerInput}`}>
                            <span
                              className={cls.containerBadge}>{`Number `}</span>
                            <TextField
                              type="number"
                              placeholder=""
                              className={clsx(cls.input, cls.numberInput)}
                              value={form.count}
                              onChange={(e) => setForm('count', e.target.value)}
                              InputProps={{ disableUnderline: true }}
                              inputProps={{ min: 0 }}
                            />
                          </div>
                        </Grid> */}
                      </Grid>
                    )
                )}
              </Grid>
              {bookings.map((booking, index) => {
                return (
                  <>
                    {booking.status !== 2 && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <div className={`${cls.input} ${cls.containerInput}`}>
                            <span className={cls.containerBadge}>{`booking ${
                              index + 1
                            }`}</span>
                            <TextField
                              type="text"
                              key={index}
                              placeholder={`booking ${index + 1}`}
                              // className={cls.input}
                              disabled={booking.status === 2}
                              value={booking.name}
                              onChange={(e) =>
                                handleUpdateBookings(
                                  'name',
                                  e.target.value,
                                  index
                                )
                              }
                              InputProps={{ disableUnderline: true }}
                              inputProps={{ min: 0 }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className={`${cls.input} ${cls.containerInput}`}>
                            <span className={cls.containerBadge}>{`Chassis ${
                              index + 1
                            }`}</span>
                            <TextField
                              type="text"
                              key={index}
                              placeholder={`Chassis ${index + 1}`}
                              // className={cls.input}
                              value={booking.chases}
                              onChange={(e) =>
                                handleUpdateBookings(
                                  'chases',
                                  e.target.value,
                                  index
                                )
                              }
                              InputProps={{ disableUnderline: true }}
                              inputProps={{ min: 0 }}
                            />
                          </div>
                        </Grid>
                      </>
                    )}
                  </>
                );
              })}
            </Grid>

            <Button className={cls.but} onClick={submit}>
              Update
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
