import React from 'react';
import { Menu, IconButton, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import clsx from 'clsx';
import { HeaderIcons } from '@/assets';
import { SaveData, getNotifications, seenNotif } from '@/actions';
import { _get } from '@/actions/Helper';
import Card from './Card';

import styles from './styles';

export default (props) => {
  const history = useHistory();

  const notif = useSelector((state) => state.reducer.notif || {});
  const cls = styles({ notif });

  const [state, setState] = React.useState({
    loading: true,
    DATA: [],
    menu: null,
  });

  React.useEffect(() => {
    if (state.menu) {
      getNotifications().then((DATA) =>
        setState((state) => ({
          ...state,
          DATA: DATA.slice(0, 5),
          loading: false,
        }))
      );
      SaveData({ notif: null });
    }
  }, [state.menu]);

  const Open = (data) => {
    setState({ ...state, menu: null });
    seenNotif(data.id);
    let Item = _get(data, 'data', {});
    if (Item.type === 'chat')
      SaveData({
        Chat: {
          id: Item.order_id,
          type: Item.status,
          chatId: Item.id,
          mode: data.mode || 'empty',
        },
      });
    else if (Item.type === 'whatsapp-messages') history.push(`/Ai/chat`);
    // SaveData({
    //   Chat: {
    //     id: Item.order_id,
    //     type: Item.status,
    //     chatId: Item.id,
    //     mode: data.mode || 'empty',
    //   },
    // });
    else if (Item.type === 'order') history.push(`/orders/${Item.status}`);
    else if (Item.type === 'request') history.push(`/requests/${Item.status}`);
  };

  return (
    <>
      <IconButton
        className={cls.bellIcon}
        onClick={(e) => setState({ ...state, menu: e.currentTarget })}>
        <img src={HeaderIcons.bell} alt="NotificationIcon" />
        <div className={cls.redDot} />
      </IconButton>
      <Menu
        elevation={0}
        keepMounted
        anchorEl={state.menu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        open={Boolean(state.menu)}
        onClose={() => setState({ ...state, menu: null })}
        classes={{ paper: cls.paper, list: cls.list }}>
        {state.loading ? (
          <div className={cls.loading}>
            <CircularProgress />
          </div>
        ) : state.DATA.length === 0 ? (
          <div className={cls.loading}>
            <div className={cls.empty}>You have no new notification</div>
          </div>
        ) : (
          <div>
            <div className={clsx('scroll-bar', cls.content)}>
              {state.DATA.map((data, idx) => (
                <Card DATA={data} key={idx} Open={Open} />
              ))}
            </div>
            {/* <Link to="/notifications" className={cls.but}>
              See all notifications
            </Link> */}
          </div>
        )}
      </Menu>
    </>
  );
};
