import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  FormControlLabel,
  Checkbox,
  Avatar,
} from '@material-ui/core';
import styles from './styles';
import {
  disableInventoryBooking,
  disableInventoryContainer,
  enableInventoryBooking,
  enableInventoryContainer,
} from '@/actions';
import { useNotify } from 'react-admin';
import closeIcon from '../../assets/img/closeIcon.svg';
import onwChassisICon from '../../assets/img/own_chases.jpeg';

export default ({
  DATA = [],
  open,
  selectedRow,
  setShowBookingsModal,
  getExportInventoryList,
}) => {
  const cls = styles();
  const notify = useNotify();
  const [bookings, setBookings] = useState(selectedRow.bookings);
  const [refreshInventories, setRefreshInventories] = useState(false);
  const updateContainers = (index, name, value) => {
    setBookings((prev) => {
      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };
  // const originalDate = "2024-04-20T04:31:52.000000Z";
  const formatUpdatedAtDate = (dateString) => {
    function isInvalidDate(dateStr) {
      return isNaN(Date.parse(dateStr));
    }

    if (dateString == 'please contact your line') {
      return dateString;
    } else if (dateString == 'TBA') {
      return dateString;
    } else if (isInvalidDate(dateString) == true) {
      return '';
    } else {
      const date = new Date(dateString);
      const formattedDate =
        new Date(date).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit',
        }) +
        ' ' +
        new Date(date).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
      // console.log(dateString);
      return formattedDate;
    }
  };

  const handleEnableCongtainer = (containerId, index) => {
    enableInventoryBooking(containerId).then((response) => {
      if (response.success) {
        notify('Booking updated');
        updateContainers(index, 'status', 1);
        setRefreshInventories(true);
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleDisableContainer = (containerId, index) => {
    disableInventoryBooking(containerId).then((response) => {
      if (response.success) {
        notify('container updated');
        updateContainers(index, 'status', 0);
        setRefreshInventories(true);
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleChangeContainerStatus = (value, containerId, index) => {
    if (value === true) {
      handleEnableCongtainer(containerId, index);
    } else {
      handleDisableContainer(containerId, index);
    }
  };

  const getExportBookings = () => {
    let exportBookings = [];
    for (let index = 0; index < selectedRow.bookings.length; index++) {
      if (
        selectedRow.bookings[index].status === 1 ||
        selectedRow.bookings[index].status === 0
      ) {
        exportBookings.push({
          name: selectedRow.bookings[index]
            ? selectedRow.bookings[index].name
            : '',
          match_time: selectedRow.bookings[index]
            ? selectedRow.bookings[index].match_time
            : '',
          id: selectedRow.bookings[index]
            ? selectedRow.bookings[index].id
            : null,
          status: selectedRow.bookings[index]
            ? selectedRow.bookings[index].status
            : null,
          erd: selectedRow.bookings[index]
            ? selectedRow.bookings[index].additional.erd
            : null,
          appointment_date: selectedRow.bookings[index]
            ? selectedRow.bookings[index].additional.appointment_date
            : null,
          cutoff: selectedRow.bookings[index]
            ? selectedRow.bookings[index].additional.cutoff
            : null,
          bill_of_lading: selectedRow.bookings[index]
            ? selectedRow.bookings[index].additional.bill_of_lading
            : null,
          mto: selectedRow.bookings[index]
            ? selectedRow.bookings[index].mto?.slug
            : null,
          count: selectedRow.bookings[index]
            ? selectedRow.bookings[index]?.count
            : null,
        });
      }
    }
    setBookings(exportBookings);
  };
  console.log('bookings', bookings);

  useEffect(() => {
    getExportBookings();
  }, []);
  const gatEditContainersContent = () => {
    return (
      <table className={cls.containerTable}>
        <thead>
          <tr>
            <th style={{ flex: '0.1' }}>#</th>
            <th style={{ flex: '1' }}>Booking#</th>
            <th style={{ flex: '1' }}>BOL</th>
            <th style={{ flex: '0.6' }}>Number</th>
            <th style={{ flex: '1.3' }}>ERD</th>
            <th style={{ flex: '1.3' }}>Cutoff</th>
            <th style={{ flex: '1' }}>MTO</th>
            <th style={{ flex: '1.3' }}>Term Apt</th>
            <th style={{ flex: '0.3' }}>Status</th>
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking, index) => {
            return (
              <tr key={index}>
                <td style={{ flex: '0.1' }}>{index + 1}</td>
                <td style={{ flex: '1' }}>
                  <span>{booking.name ? booking.name : 'N/A'}</span>
                </td>
                <td style={{ flex: '1' }}>
                  <span>
                    {booking.bill_of_lading ? booking.bill_of_lading : 'N/A'}
                  </span>
                </td>
                <td style={{ flex: '0.6' }}>
                  <span>{booking.count ? booking.count : 'N/A'}</span>
                </td>
                <td style={{ flex: '1.3' }}>
                  <div className={cls.chassisNameContainer}>
                    {booking.erd ? formatUpdatedAtDate(booking.erd) : 'N/A'}
                  </div>
                </td>
                <td style={{ flex: '1.3' }}>
                  <div className={cls.chassisNameContainer}>
                    {booking.cutoff
                      ? formatUpdatedAtDate(booking.cutoff)
                      : 'N/A'}
                  </div>
                </td>
                <td style={{ flex: '1' }}>
                  <div className={cls.chassisNameContainer}>
                    <span>{booking.mto ? booking.mto : 'N/A'}</span>
                  </div>
                </td>{' '}
                <td style={{ flex: '1.3' }}>
                  <div className={cls.chassisNameContainer}>
                    {booking.appointment_date
                      ? formatUpdatedAtDate(booking.appointment_date)
                      : 'N/A'}
                  </div>
                </td>
                <td style={{ flex: '0.3' }}>
                  {booking.status !== null && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={booking.status === 1 ? true : false}
                          className={cls.checkbox}
                          onChange={(e) =>
                            handleChangeContainerStatus(
                              e.target.checked,
                              booking.id,
                              index
                            )
                          }
                        />
                      }
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <Modal open={open}>
        <div
          id="app_modal"
          className={cls.modal}
          onClick={(e) => {
            if (e.target.id === 'app_modal') {
              setShowBookingsModal(false);
              refreshInventories && getExportInventoryList();
              setRefreshInventories(false);
            }
          }}
          style={{ width: '100%', height: '100%' }}>
          <Box className={cls.modalBox}>
            <div
              onClick={() => {
                setShowBookingsModal(false);
                refreshInventories && getExportInventoryList();
                setRefreshInventories(false);
              }}
              className={cls.closeModal}>
              <img alt="close_icon" src={closeIcon} />
            </div>
            <h3 className={cls.modalTitle}>Booking Availability</h3>
            <div className={cls.containersTableContainer}>
              {gatEditContainersContent()}
            </div>
            <span className={cls.updateContainersBtnSpan}>
              {/* <button
                className={cls.updateContainersBtnClose}
                onClick={() => {
                  setShowBookingsModal(false);
                }}>
                Close
              </button> */}
              <button
                className={cls.updateContainersBtnSave}
                onClick={() => {
                  setShowBookingsModal(false);
                  refreshInventories && getExportInventoryList();
                  setRefreshInventories(false);
                }}>
                Close
              </button>
            </span>
          </Box>
        </div>
      </Modal>
    </div>
  );
};
