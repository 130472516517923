import React from 'react';
import styles from './styles';

export default ({ tablesData, setTablesData, pageContent }) => {
  const cls = styles();

  const handleChangeMtos = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setTablesData((prev) => {
      return {
        ...prev,
        mtos: {
          ...prev.mtos,
          [name]: value,
        },
      };
    });
  };
  // console.log(tablesData, "sdffs")

  return (
    <div>
      <h3 className={cls.header}>MTO NAMING</h3>
      <div className={cls.listContainer}>
        <table className={cls.list}>
          <thead>
            <th style={{ flex: 0.7 }}>SMART TURN</th>
            <th style={{ flex: 1 }}>MTO</th>
            <th style={{ flex: 0.7 }}>USER</th>
          </thead>
          <tbody>
            {tablesData.mtos_default &&
              Object.keys(tablesData.mtos_default).map((key, index) => (
                <tr>
                  <td style={{ flex: 1 }}>{key}</td>
                  <td style={{ flex: 0.7 }}>{tablesData.mtos_default[key]}</td>
                  <td style={{ flex: 0.7 }}>
                    {pageContent &&
                    (pageContent.value === 1 || pageContent.value === 2) ? (
                      <>{tablesData.mtos[key]}</>
                    ) : (
                      <input
                        onChange={(e) => handleChangeMtos(e)}
                        type="text"
                        defaultValue={tablesData?.mtos && tablesData?.mtos[key]}
                        // value={tablesData.ssl_default[key]}
                        name={key}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
