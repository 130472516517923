import React from 'react';
import { Button, TextField, Tooltip, Grid } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { ReactSVG } from 'react-svg';
import DateInput from '@/components/DateInput';
import { locationOpt } from '@/actions/Helper';
import { Icons } from '@/assets';
import styles from './styles';

export default ({
  title,
  DATA = [],
  filter = {},
  setFilter,
  children,
  companies,
  whatsappBy,
  whatsappDatePosted,
  whatsappCompany,
  filterWithApi,
  withDateFilter,
}) => {
  const cls = styles();
  const selectOp = useSelector((state) => state.reducer);

  const [form, setFormData] = React.useState({
    size: null,
    ssl: null,
    loc: null,
    date: null,
    num: '',
  });
  const setForm = (i, v) => setFormData({ ...form, [i]: v });

  React.useEffect(() => {
    if (filter.open) $('.filter-card').slideDown();
    else $('.filter-card').slideUp();
  }, [filter.open]);

  const Filter = () => {
    let arr = DATA;
    if (form.size) arr = arr.filter((i) => i.size === form.size.label);
    if (form.ssl) arr = arr.filter((i) => i.ssl === form.ssl.label);
    if (form.loc) arr = arr.filter((i) => form.loc.label.includes(i.loc));
    // if (form.date) arr = arr.filter((i) => i.size === form.size);
    if (form.num) arr = arr.filter((i) => i.num <= form.num);
    if (form.company) arr = arr.filter((i) => i.company <= form.company);

    setFilter({ ...filter, DATA: arr });
  };

  const Close = () => setFilter({ ...filter, open: false });
  const Toggle = () => setFilter({ ...filter, open: !filter.open });
  // <div className={cls.search}>
  //         <h2 className={cls.title}>{title}</h2>
  //         <div className={cls.searchBox}>
  //           <BaseIcon src={memberIcons.search} className={cls.searchIcon} />
  //           <input
  //             className={cls.searchInput}
  //             type="text"
  //             placeholder="Find Members"
  //           />
  //         </div>
  //         <div className={cls.filter}>
  //           <span className={cls.export}>Export</span>
  //           <span className={cls.xlsx}>XLSX</span>
  //           <span className={cls.filterTitle}>Filter</span>
  //           <BaseIcon src={Icons.filter} className={cls.filterIcon} />
  //         </div>
  //       </div>
  return (
    <div className={cls.filterContainer}>
      <div className={cls.headerBar}>
        <span className={cls.title}>{title}</span>

        <div className={cls.row3}>
          {children}
          <div className={cls.filterRow} onClick={Toggle}>
            <span className={cls.title}>Filter</span>
            <ReactSVG src={Icons.filter} className={cls.filter} />
          </div>
        </div>
      </div>
      <div
        className={clsx(cls.card, 'filter-card')}
        style={{ display: 'none' }}>
        <Tooltip arrow title="Close" placement="top">
          <CloseRounded className={cls.close} onClick={Close} />
        </Tooltip>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Select
              isClearable
              placeholder="Size"
              className={cls.select}
              value={form.size}
              onChange={(item) => setForm('size', item)}
              options={selectOp.size}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Select
              isClearable
              placeholder="SSL"
              className={clsx(cls.select)}
              value={form.ssl}
              onChange={(item) => setForm('ssl', item)}
              options={selectOp.ssl}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AsyncSelect
              isClearable
              placeholder="Location"
              className={cls.loc}
              value={form.loc}
              loadOptions={(e) => locationOpt(e)}
              onChange={(item) => setForm('loc', item)}
              cacheOptions
              defaultOptions
            />
          </Grid>

          {withDateFilter && (
            <Grid item xs={12} sm={6} md={3}>
              <DateInput
                placeholder="Date"
                className={cls.date}
                format="MM/dd/yyyy"
                value={form.date}
                onChange={(item) => setForm('date', item)}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              type="number"
              placeholder="Number"
              className={clsx(cls.input)}
              value={form.num}
              onChange={(e) => setForm('num', e.target.value)}
              InputProps={{ disableUnderline: true }}
              inputProps={{ min: 0 }}
            />
          </Grid>

          {companies && (
            <Grid item xs={12} sm={6} md={3}>
              <Select
                isClearable
                placeholder="Company"
                className={clsx(cls.select)}
                value={form.company}
                onChange={(item) => setForm('company', item)}
                options={companies}
              />
            </Grid>
          )}
          {companies && (
            <Grid item xs={12} sm={6} md={3}>
              <Select
                isClearable
                placeholder="Company"
                className={clsx(cls.select)}
                value={form.company}
                onChange={(item) => setForm('company', item)}
                options={companies}
              />
            </Grid>
          )}
          {whatsappBy && (
            <Grid item xs={12} sm={6} md={3}>
              <Select
                isClearable
                placeholder="By"
                className={clsx(cls.select)}
                value={form.company}
                onChange={(item) => setForm('By', item)}
                options={companies}
              />
            </Grid>
          )}
          {whatsappDatePosted && (
            <Grid item xs={12} sm={6} md={3}>
              <DateInput
                placeholder="Date Posted"
                className={cls.date}
                format="MM/dd/yyyy"
                value={form.date}
                onChange={(item) => setForm('dateposted', item)}
              />
            </Grid>
          )}
          {whatsappCompany && (
            <Grid item xs={12} sm={6} md={3}>
              <Select
                isClearable
                placeholder="Company"
                className={clsx(cls.select)}
                value={form.company}
                onChange={(item) => setForm('company', item)}
                options={companies}
              />
            </Grid>
          )}
        </Grid>

        <div className={cls.row2}>
          <Button
            className={cls.but}
            onClick={() => {
              filterWithApi ? filterWithApi(form) : Filter();
            }}>
            Filter
          </Button>
        </div>
      </div>
    </div>
  );
};
