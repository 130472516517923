import React, { useEffect, useState } from 'react';
import styles from './styles';
import { useHistory } from 'react-router-dom';
import {
  getInventoryShareLink,
  getPortsList,
  getTerminalsList,
  getVesselSchedule,
  getSslVesselSchedule,
  aiWhatsapp,
  aiWhatsappGroupList,
} from '@/actions';
import { DeleteRounded, CreateRounded, CropSquare } from '@material-ui/icons';

import { useSelector } from 'react-redux';
import { Icons, TerminalsIcon } from '@/assets';
import { ReactSVG } from 'react-svg';
import { Tooltip, Grid, TextField, Button } from '@material-ui/core';
import DateInput from '@/components/DateInput';
import clsx from 'clsx';
import Select from 'react-select';
import { getGateSchedule } from '@/actions';
import { useNotify, Loading } from 'react-admin';
import { FormHelperText, IconButton } from '@mui/material';
import Containers from './Containers';
import WPMPopup from './WPMPopup';

export default ({
  DATA,
  form,
  messages,
  getAiWhatsappFanc,
  dataTypeChange,
}) => {
  const cls = styles();
  const notify = useNotify();

  // const setForm = (it, val) => setFormData({ ...form, [it]: val });
  const [showContainersModal, setShowContainersModal] = useState(false);
  const [whatsappPb, setWhatsappPb] = useState();
  const [selectedRow, setSelectedRow] = useState({
    first: null,
    second: null,
    third: null,
  });
  const [containersRow, setContainersRow] = useState([]);
  const [aiAnalysisRes, setAiAnalysisRes] = useState([]);
  const [mode, setMode] = useState(null);
  const history = useHistory();

  const [formData, setFormData] = React.useState({
    groups: [],
    type: null,
    from: null,
  });
  const [popUp, setPopup] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    edit: null,
    file: null,
  });

  const [whatsAppGroupList, setWhatsAppGroupList] = useState([]);
  const [whatsAppGroupListOpt, setWhatsAppGroupListOpt] = useState([]);

  // const [messages, setMessages] = useState({
  //   loading: false,
  //   currentPage: 1,
  //   totalPage: 1,
  //   DATA: [],
  // });

  const getAiWhatsappGroupList = () => {
    setLoading(true);
    aiWhatsappGroupList()
      .then((res) => {
        // console.log(res);
        setWhatsAppGroupList(res.data.groups);

        setWhatsAppGroupListOpt(
          res.data.groups.map((group, index) => ({
            label: group.name,
            // slug: port.slug,
            value: index + 1,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const openPopUp = (item) => {
    setState({ ...state, edit: item });
  };

  const containers = (selectedRoww) => {
    Object.values(messages).forEach((value, i) => {
      if (i === selectedRoww.first) {
        const aiAnalysisRess = value;

        const containersRes =
          value.match_type === 'export'
            ? value.after_ai_parts[selectedRoww.second]?.empties[
                selectedRoww.third
              ]?.containers
            : value.match_type === 'empty'
            ? value.after_ai_parts[selectedRoww.second]?.exports[
                selectedRoww.third
              ]?.bookings
            : '';
        setContainersRow(containersRes);
        setAiAnalysisRes(aiAnalysisRess);
      }
    });
  };

  // useEffect(() => {
  //   // containers(selectedRow);
  // }, [selectedRow, mode, containersRow, showContainersModal]);

  const getActivContainers = (inventory, target) => {
    
    if (inventory) {
      let activeContainers = [];
      activeContainers =
      inventory.containers
          ? inventory.containers.filter((container) => {
              return container.status === 1;
            })
          : inventory.bookings
          ? inventory.bookings.filter((booking) => {
              return booking.status === 1;
            })
          : '';

      if (activeContainers.length === inventory.count_all) {
        return (
          <input
            type="checkbox"
            className={`all ${cls.containerChecBox}`}
            checked={true}
          />
        );
      } else if (activeContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`any ${cls.containerChecBox}`}
            checked={false}
          />
        );
      } else {
        return (
          <input
            type="checkbox"
            checked={true}
            className={`some ${cls.containerChecBox}`}
          />
        );
      }
    }
  };

  useEffect(() => {
    getAiWhatsappGroupList();
  }, []);

  useEffect(() => {
    const groupList = whatsAppGroupList.map((group, index) => ({
      label: group.name,
      // slug: port.slug,
      value: index + 1,
    }));
  }, [whatsAppGroupList]);

  // Function to get the dates of the current week
  function getCurrentWeekDates() {
    const today = new Date();
    const currentDay = today.getDay();
    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(today.getDate() - currentDay);

    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      dates.push(date);
    }
    return dates;
  }

  // Format the date to separate day, abbreviated month, and year
  function formatDate(date, format) {
    const day = date.getDate().toString().padStart(2, '0');
    const dayNumber = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dayName = dayNames[date.getDay()];
    const monthNumber = String(monthIndex + 1).padStart(2, '0'); // Add leading zero if necessary
    const month = monthNames[monthIndex];
    const fullDate = `${year}/${monthNumber}/${day}`;
    if (format == 1) {
      return `${year}-${monthNumber}-${dayNumber}`;
    } else {
      return { fullDate, day, dayNumber, dayName, monthNumber, month, year };
    }
  }

  // Print the dates of the current week
  function getCurrentWeekDates() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const startDate = new Date(today);
    startDate.setDate(startDate.getDate() - dayOfWeek);
    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      weekDates.push(date);
    }
    return weekDates;
  }
  function directChat(directChatID) {
    localStorage.setItem('whatsappUserId', directChatID);
    history.push(`/ai/chat/${directChatID}`);
  }
  const weekDates = getCurrentWeekDates();
  const formattedDates = weekDates.map((date) => formatDate(date));

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = { month: mm, day: dd, year: yyyy };

  const todayIndex = formattedDates.findIndex(
    (date) =>
      date.day === parseInt(today.day) &&
      date.monthNumber === parseInt(today.month) &&
      date.year === today.year
  );

  // const originalDate = "2024-04-20T04:31:52.000000Z";
  const formatUpdatedAtDate = (dateString) => {
    if (dateString == 'please contact your line') {
    } else if (dateString == 'TBA') {
      return dateString;
    } else if (dateString == 'Invalid Date') {
      return 'N/A';
    } else {
      const date = new Date(dateString);
      const formattedDate =
        new Date(date).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit',
        }) +
        ' ' +
        new Date(date).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
      return formattedDate;
    }
  };

  return (
    <>
      {popUp && (
        <WPMPopup
          // selectedCompany={selectedCompany}
          // setSelectedCompany={setSelectedCompany}
          // companies={users}
          // showCompanies={popUp !== 'avalable' ? true : false}
          Close={() => setPopup(null)}></WPMPopup>
      )}

      {showContainersModal && selectedRow && (
        <Containers
          open={showContainersModal}
          DATA={containersRow}
          aiAnalysisRes={aiAnalysisRes}
          mode={mode}
          whatsappPb={whatsappPb}
          selectedRow={selectedRow}
          setShowContainersModal={setShowContainersModal}
          // getInventoryList={getInventories}
        />
      )}

      <div id="amirbios" className={cls.listContainer}>
        <table id="table" className={cls.list}>
          <thead id="amirbios1">
            <tr id="amirbios2" className={cls.thead}>
              <th style={{ flex: 1 }} className={`${cls.tableTitleTop}`}>
                WhatsApp
              </th>
              <th style={{ flex: 0.638 }} className={`${cls.tableTitleTop}`}>
                SmartTurn
              </th>
            </tr>
          </thead>
          <thead id="amirbios1">
            <tr id="amirbios2" className={cls.thead}>
              <th style={{ flex: 1 }} className={cls.tableTitleSorting}>
                <th style={dataTypeChange == '' ? { flex: 0.29 } : { flex: 0.26 }} clasNsame={cls.tableTitleSorting}>
                  #
                </th>
                <th style={{ flex: 1 }} className={cls.tableTitleSorting}>
                  Size/Type
                </th>
                <th style={{ flex: 1 }} className={cls.tableTitleSorting}>
                  SSL
                </th>
                <th style={{ flex: 1 }} className={cls.tableTitleSorting}>
                  Number
                </th>
                <th style={{ flex: 1 }} className={cls.tableTitleSorting}>
                  Location
                </th>
                <th style={{ flex: 1 }} className={cls.tableTitleSorting}>
                  By
                </th>
                <th style={{ flex: 1 }} className={cls.tableTitleTopEnd}>
                  Date Posted
                </th>
                {dataTypeChange == '' ? (
                  <th style={{ flex: 0.505 }} className={cls.tableTitleTopEnd}>
                    Type
                  </th>
                ) : null}
              </th>
              <th
                style={dataTypeChange == '' ? { flex: 0.751 } : { flex: 0.7485 }}
                className={cls.tableTitleSorting}>
                <th style={{ flex: 0.22 }} className={cls.tableTitleSorting}>
                  #
                </th>
                <th style={{ flex: 1 }} className={cls.tableTitleSorting}>
                  Company
                </th>
                <th style={{ flex: 1 }} className={cls.tableTitleSorting}>
                  Number
                </th>
                <th style={{ flex: 1 }} className={cls.tableTitleSorting}>
                  Location
                </th>
                <th style={{ flex: 1 }} className={cls.tableTitleSorting}>
                  Last Update
                </th>
                <th style={{ flex: 1 }} className={cls.tableTitleSorting}>
                  Actons
                </th>
              </th>
            </tr>
          </thead>
          <tbody className={cls.tableBody}>
            {Object.values(messages).map((idx, i) => (
              <tr className={cls.tableRow} key={idx.id}>
                <td style={{ flex: 0.023 }} className={cls.tableRowCount}>
                  <span style={{ flex: 1 }} className={cls.counttd}>
                    {i + 1}
                  </span>
                </td>
                <td style={{ flex: 1 }} className={cls.after_ai_partsRow}>
                  {Array.isArray(idx?.after_ai_parts)
                    ? idx.after_ai_parts.map((part, ii) => (
                        <span
                          style={{ flex: 1 }}
                          className={cls.after_ai_partRow}>
                          <td style={{ flex: 1 }} className={cls.tableRowLeft}>
                            <span style={{ flex: 1 }} className={cls.shifttd}>
                              {part.specification ? (
                                <span key={i}>
                                  {part?.specification?.size
                                    ? part?.specification?.size + ' - '
                                    : ''}
                                  {part?.specification?.name
                                    ? part?.specification?.name + ' - '
                                    : ''}
                                  {part?.specification?.model}
                                </span>
                              ) : (
                                '-'
                              )}
                            </span>
                            <span style={{ flex: 1 }} className={cls.shifttd}>
                              <span key={i}>
                                {part?.ssl?.alias ? part?.ssl?.alias : '-'}
                              </span>
                            </span>
                            <span style={{ flex: 1 }} className={cls.shifttd}>
                              <span>{idx.number ? idx.number : '-'}</span>
                            </span>
                            <span style={{ flex: 1 }} className={cls.shifttd}>
                              <span>{idx.location ? idx.location : '-'}</span>
                            </span>
                            <span style={{ flex: 1 }} className={cls.shifttd}>
                              <span>
                                {idx.from_name}
                                <br style={{ marginBottom: '5px' }}></br>
                                {idx.from}
                              </span>
                            </span>
                            <span
                              style={{ flex: 1 }}
                              className={cls.shifttdEnd}>
                              <span>
                                {idx.created_at
                                  ? formatUpdatedAtDate(`${idx.created_at}`)
                                  : '-'}
                              </span>
                            </span>
                            {dataTypeChange == '' ? (
                              <span
                                style={{ flex: 0.5 }}
                                className={cls.shifttdEnd}>
                                <span>
                                  {idx.match_type ? idx.match_type : '-'}
                                </span>
                              </span>
                            ) : null}
                          </td>
                          <td
                            style={
                              dataTypeChange == ''
                                ? { flex: 0.785 }
                                : { flex: 0.7845 }
                            }
                            className={`${cls.shifttdmultiRow}`}>
                            {dataTypeChange === 'empty' ? (
                              Array.isArray(part.empties) &&
                              part.empties.length > 0 ? (
                                part.empties.map((empt, j) => (
                                  <tr
                                    style={{ flex: 1 }}
                                    className={cls.tableRowMulti}
                                    key={j}>
                                    <span
                                      style={{ flex: 0.25 }}
                                      className={cls.tableRowCount}>
                                      <span
                                        style={{ flex: 1 }}
                                        className={cls.counttd}>
                                        {j + 1}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>
                                        {empt.user?.company
                                          ? empt.user?.company
                                          : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>
                                        {empt.count_available
                                          ? empt.count_available
                                          : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>
                                        {empt.address?.city
                                          ? empt.address?.city
                                          : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>
                                        {empt.updated_at
                                          ? formatUpdatedAtDate(
                                              `${empt.updated_at}`
                                            )
                                          : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <div className={cls.tableActions}>
                                        <Tooltip
                                          arrow
                                          title="Containers"
                                          placement="top">
                                          <IconButton
                                            onClick={() => {
                                              setShowContainersModal(
                                                !showContainersModal
                                              );
                                              containers({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setSelectedRow({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setMode('containers');
                                            }}>
                                            {getActivContainers(empt)}
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          title="Direct Chat"
                                          placement="top">
                                          <IconButton
                                            style={{ padding: 4 }}
                                            onClick={() =>
                                              directChat(idx.from)
                                            }>
                                            <ReactSVG
                                              style={{ padding: 0 }}
                                              src={Icons.directMessage}
                                              className={cls.icon}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          title="WhatsApp Message"
                                          placement="top">
                                          <IconButton
                                            style={{ padding: 4 }}
                                            onClick={() => {
                                              setSelectedRow({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setMode('whatsapp');
                                              containers({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setShowContainersModal(
                                                !showContainersModal
                                              );
                                            }}>
                                            <ReactSVG
                                              style={{ padding: 0 }}
                                              src={Icons.WhatsApp}
                                              className={cls.icon}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </span>
                                  </tr>
                                ))
                              ) : (
                                <tr
                                  style={{ flex: 1 }}
                                  className={cls.tableRowMulti}>
                                  <span
                                    style={{ flex: 0.25 }}
                                    className={cls.tableRowCount}>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.counttd}>
                                      {'-'}
                                    </span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <span>{'-'}</span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <span>{'-'}</span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <span>{'-'}</span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <span>{'-'}</span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <div className={cls.tableActions}>
                                      <Tooltip
                                        style={{ width: '34px' }}
                                        arrow
                                        title=""
                                        placement="top">
                                        <IconButton>
                                          {/* {getActivContainers(empt)} */}
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        arrow
                                        title="Direct Chat"
                                        placement="top">
                                        <IconButton
                                          style={{ padding: 4 }}
                                          onClick={() => directChat(idx.from)}>
                                          <ReactSVG
                                            style={{ padding: 0 }}
                                            src={Icons.directMessage}
                                            className={cls.icon}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        arrow
                                        title="WhatsApp Message"
                                        placement="top">
                                        <IconButton
                                          style={{ padding: 4 }}
                                          onClick={() => {
                                            setWhatsappPb(i);
                                            containers({
                                              first: i,
                                              second: ii,
                                              third: 1,
                                            });
                                            setSelectedRow({
                                              first: i,
                                              second: ii,
                                              third: 1,
                                            });
                                            setMode('whatsapp');
                                            setShowContainersModal(
                                              !showContainersModal
                                            );
                                          }}>
                                          <ReactSVG
                                            style={{ padding: 0 }}
                                            src={Icons.WhatsApp}
                                            className={cls.icon}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </span>
                                </tr>
                              )
                            ) : dataTypeChange === 'export' ? (
                              Array.isArray(part.exports) &&
                              part.exports.length > 0 ? (
                                part.exports.map((expt, j) => (
                                  <tr
                                    style={{ flex: 1 }}
                                    className={cls.tableRowMulti}
                                    key={expt.count_available}>
                                    <span
                                      style={{ flex: 0.25 }}
                                      className={cls.tableRowCount}>
                                      <span
                                        style={{ flex: 1 }}
                                        className={cls.counttd}
                                        key={j}>
                                        {j + 1}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span key={j}>
                                        {expt.user?.company
                                          ? expt.user?.company
                                          : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>
                                        {expt.count ? expt.count : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>
                                        {expt.address?.city
                                          ? expt.address?.city
                                          : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>
                                        {expt.updated_at
                                          ? formatUpdatedAtDate(
                                              `${expt.updated_at}`
                                            )
                                          : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <div className={cls.tableActions}>
                                        <Tooltip
                                          arrow
                                          title="Bookings"
                                          placement="top">
                                          <IconButton
                                            onClick={() => {
                                              setShowContainersModal(
                                                !showContainersModal
                                              );
                                              containers({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setSelectedRow({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setMode('exports');
                                            }}>
                                            {getActivContainers(expt)}
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          title="Direct Chat"
                                          placement="top">
                                          <IconButton
                                            style={{ padding: 4 }}
                                            onClick={() =>
                                              directChat(idx.from)
                                            }>
                                            <ReactSVG
                                              style={{ padding: 0 }}
                                              src={Icons.directMessage}
                                              className={cls.icon}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          title="WhatsApp Message"
                                          placement="top">
                                          <IconButton
                                            style={{ padding: 4 }}
                                            onClick={() => {
                                              setShowContainersModal(
                                                !showContainersModal
                                              );
                                              containers({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setSelectedRow({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setMode('whatsapp');
                                            }}>
                                            <ReactSVG
                                              style={{ padding: 0 }}
                                              src={Icons.WhatsApp}
                                              className={cls.icon}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </span>
                                  </tr>
                                ))
                              ) : (
                                <tr
                                  style={{ flex: 1 }}
                                  className={cls.tableRowMulti}>
                                  <span
                                    style={{ flex: 0.25 }}
                                    className={cls.tableRowCount}>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.counttd}>
                                      {'-'}
                                    </span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <span>{'-'}</span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <span>{'-'}</span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <span>{'-'}</span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <span>{'-'}</span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <div className={cls.tableActions}>
                                      <Tooltip
                                        style={{ width: '34px' }}
                                        arrow
                                        title=""
                                        placement="top">
                                        <IconButton>
                                          {/* {getActivContainers(empt)} */}
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        arrow
                                        title="Direct Chat"
                                        placement="top">
                                        <IconButton
                                          style={{ padding: 4 }}
                                          onClick={() => directChat(idx.from)}>
                                          <ReactSVG
                                            style={{ padding: 0 }}
                                            src={Icons.directMessage}
                                            className={cls.icon}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        arrow
                                        title="WhatsApp Message"
                                        placement="top">
                                        <IconButton
                                          style={{ padding: 4 }}
                                          onClick={() => {
                                            setShowContainersModal(
                                              !showContainersModal
                                            );
                                            containers({
                                              first: i,
                                              second: ii,
                                              third: 1,
                                            });
                                            setSelectedRow({
                                              first: i,
                                              second: ii,
                                              third: 1,
                                            });
                                            setMode('whatsapp');
                                          }}>
                                          <ReactSVG
                                            style={{ padding: 0 }}
                                            src={Icons.WhatsApp}
                                            className={cls.icon}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </span>
                                </tr>
                              )
                            ) : dataTypeChange === '' ? (
                              Array.isArray(part.empties) &&
                              part.empties.length > 0 ? (
                                part.empties.map((empt, j) => (
                                  <tr
                                    style={{ flex: 1 }}
                                    className={cls.tableRowMulti}
                                    key={j}>
                                    <span
                                      style={{ flex: 0.25 }}
                                      className={cls.tableRowCount}>
                                      <span
                                        style={{ flex: 1 }}
                                        className={cls.counttd}>
                                        {j + 1}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>
                                        {empt.user?.company
                                          ? empt.user?.company
                                          : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>
                                        {empt.count_available
                                          ? empt.count_available
                                          : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>{empt.loc ? empt.loc : '-'}</span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>
                                        {empt.updated_at
                                          ? formatUpdatedAtDate(
                                              `${empt.updated_at}`
                                            )
                                          : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <div className={cls.tableActions}>
                                        <Tooltip
                                          arrow
                                          title="Containers"
                                          placement="top">
                                          <IconButton
                                            onClick={() => {
                                              setShowContainersModal(
                                                !showContainersModal
                                              );
                                              containers({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setSelectedRow({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setMode('containers');
                                            }}>
                                            {getActivContainers(empt)}
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          title="Direct Chat"
                                          placement="top">
                                          <IconButton
                                            style={{ padding: 4 }}
                                            onClick={() =>
                                              directChat(idx.from)
                                            }>
                                            <ReactSVG
                                              style={{ padding: 0 }}
                                              src={Icons.directMessage}
                                              className={cls.icon}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          title="WhatsApp Message"
                                          placement="top">
                                          <IconButton
                                            style={{ padding: 4 }}
                                            onClick={() => {
                                              setShowContainersModal(
                                                !showContainersModal
                                              );
                                              containers({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setSelectedRow({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setMode('whatsapp');
                                            }}>
                                            <ReactSVG
                                              style={{ padding: 0 }}
                                              src={Icons.WhatsApp}
                                              className={cls.icon}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </span>
                                  </tr>
                                ))
                              ) : Array.isArray(part.exports) &&
                                part.exports.length > 0 ? (
                                part.exports.map((expt, j) => (
                                  <tr
                                    style={{ flex: 1 }}
                                    className={cls.tableRowMulti}
                                    key={j}>
                                    <span
                                      style={{ flex: 0.25 }}
                                      className={cls.tableRowCount}>
                                      <span
                                        style={{ flex: 1 }}
                                        className={cls.counttd}>
                                        {j + 1}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>
                                        {expt.user?.company
                                          ? expt.user?.company
                                          : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>
                                        {expt.count ? expt.count : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>{expt.loc ? expt.loc : '-'}</span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <span>
                                        {expt.updated_at
                                          ? formatUpdatedAtDate(
                                              `${expt.updated_at}`
                                            )
                                          : '-'}
                                      </span>
                                    </span>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.shifttdd}>
                                      <div className={cls.tableActions}>
                                        <Tooltip
                                          arrow
                                          title="Bookings"
                                          placement="top">
                                          <IconButton
                                            onClick={() => {
                                              setShowContainersModal(
                                                !showContainersModal
                                              );
                                              containers({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setSelectedRow({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setMode('exports');
                                            }}>
                                            {getActivContainers(expt)}
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          title="Direct Chat"
                                          placement="top">
                                          <IconButton
                                            style={{ padding: 4 }}
                                            onClick={() =>
                                              directChat(idx.from)
                                            }>
                                            <ReactSVG
                                              style={{ padding: 0 }}
                                              src={Icons.directMessage}
                                              className={cls.icon}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          title="WhatsApp Message"
                                          placement="top">
                                          <IconButton
                                            style={{ padding: 4 }}
                                            onClick={() => {
                                              setShowContainersModal(
                                                !showContainersModal
                                              );
                                              containers({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setSelectedRow({
                                                first: i,
                                                second: ii,
                                                third: j,
                                              });
                                              setMode('whatsapp');
                                            }}>
                                            <ReactSVG
                                              style={{ padding: 0 }}
                                              src={Icons.WhatsApp}
                                              className={cls.icon}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </span>
                                  </tr>
                                ))
                              ) : (
                                <tr
                                  style={{ flex: 1 }}
                                  className={cls.tableRowMulti}>
                                  <span
                                    style={{ flex: 0.25 }}
                                    className={cls.tableRowCount}>
                                    <span
                                      style={{ flex: 1 }}
                                      className={cls.counttd}>
                                      {'-'}
                                    </span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <span>{'-'}</span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <span>{'-'}</span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <span>{'-'}</span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <span>{'-'}</span>
                                  </span>
                                  <span
                                    style={{ flex: 1 }}
                                    className={cls.shifttdd}>
                                    <div className={cls.tableActions}>
                                      <Tooltip
                                        style={{ width: '34px' }}
                                        arrow
                                        title=""
                                        placement="top">
                                        <IconButton>
                                          {/* {getActivContainers(empt)} */}
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        arrow
                                        title="Direct Chat"
                                        placement="top">
                                        <IconButton
                                          style={{ padding: 4 }}
                                          onClick={() => directChat(idx.from)}>
                                          <ReactSVG
                                            style={{ padding: 0 }}
                                            src={Icons.directMessage}
                                            className={cls.icon}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        arrow
                                        title="WhatsApp Message"
                                        placement="top">
                                        <IconButton
                                          style={{ padding: 4 }}
                                          onClick={() => {
                                            setShowContainersModal(
                                              !showContainersModal
                                            );
                                            containers({
                                              first: i,
                                              second: ii,
                                              third: 1,
                                            });
                                            setSelectedRow({
                                              first: i,
                                              second: ii,
                                              third: 1,
                                            });
                                            setMode('whatsapp');
                                          }}>
                                          <ReactSVG
                                            style={{ padding: 0 }}
                                            src={Icons.WhatsApp}
                                            className={cls.icon}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </span>
                                </tr>
                              )
                            ) : (
                              ' '
                            )}
                          </td>
                        </span>
                      ))
                    : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
