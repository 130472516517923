import React, { useEffect } from 'react';
import { AppBar } from '@/components/Landing';
import HomeLayout from '@/components/HomeLayout';
import styles from '../Ports/styles';
import $ from 'jquery'; // Import jQuery
import { Grid } from '@material-ui/core';
import { Header, List, Map } from '@/components/Search';
import { getTerminalsList } from '@/actions';
import { ReactSVG } from 'react-svg';
import { Icons } from '@/assets';
import ReactMapboxGl, {
  Layer,
  Feature,
  Marker,
  Cluster,
} from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export default () => {
  const cls = styles();

  const [state, setState] = React.useState({
    loading: true,
  });

  useEffect(() => {
    getTerminalsList()
      .then((DATA) => {
        setState({
          loading: false,
          DATA: DATA?.data.terminals,
        });
      })
      .catch(() => setState((st) => ({ ...st, loading: false })));
    // const fetchData = async () => {
    //   try {
    //     const url = 'https://www.lbct.com/Operations/EmptyRestrictions';
    //     const response = await fetch(url);

    //     if (!response.ok) {
    //       throw new Error(
    //         `Failed to fetch the website. Status code: ${response.status}`
    //       );
    //     }

    //     const htmlContent = await response.text();
    //     console.log(htmlContent);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };

    // fetchData();
  }, []);
  console.log('state terminal', state.DATA);

  const Map = ReactMapboxGl({
    accessToken:
      'pk.eyJ1IjoiYW5keXJpZDE5ODciLCJhIjoiY2xpMWR6NDh5MDd4ODNybXU2dWpwOWFpdyJ9.JtcEYgN7HA5Me5Cwce-TDw',
  });

  // const clusterMarker = (coordinates) => (
  //   <Marker coordinates={coordinates} style={styles.clusterMarker}>
  //     C
  //   </Marker>
  //   );
  return (
    <div className={cls.container}>
      <div className={cls.terminalsMapDiv}>
        <Grid container spacing={2} className={cls.searchBar}>
          <Grid item xs={4} lg={3} className={cls.terminalsMapSidebar}>
            {state.DATA?.map((i, idx) => {
              return (
                <Grid
                  key={idx}
                  item
                  xs={0}
                  lg={12}
                  className={cls.terminalsListCart}>
                  <span className={cls.terminalTitle}>{i.name}</span>
                  <span className={cls.terminalAddress}>{i.address}</span>
                  <span className={cls.terminalPort}>Port : {i.port}</span>
                  <span className={cls.iconsRow}>
                    <a
                      id="iconlinks"
                      target="_blank"
                      className={cls.iconsTitle}
                      style={{ color: '#000000' }}
                      href={i.website}>
                      <ReactSVG
                        with="35px"
                        src={Icons.website_icon}
                        className={cls.terminalsListIcons}
                      />
                      Website
                    </a>
                    <span className={cls.iconsTitle}>
                      <ReactSVG
                        with="35px"
                        src={Icons.restraction_icon}
                        className={cls.terminalsListIcons}
                      />
                      Exaption
                    </span>
                    <span className={cls.iconsTitle}>
                      <ReactSVG
                        with="35px"
                        src={Icons.examption_icon}
                        className={cls.terminalsListIcons}
                        title="Website"
                      />
                      Restriction
                    </span>
                  </span>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={8} sm={9} lg={6}>
            {/* <Map  /> */}
            <Map
              center={[-118.23318139103753, 33.7585919783768]}
              zoom={[12]}
              style="mapbox://styles/mapbox/streets-v9"
              containerStyle={{
                height: '85vh',
                width: '68vw',
              }}>
              {state.DATA?.map((latLang, key) => (
                <Marker
                  key={key}
                  coordinates={[latLang.loc_lat, latLang.loc_lng]}
                  anchor="bottom">
                  <img width="35px" src={Icons.locationMap} />
                </Marker>
              ))}

              {/* <Popup key={station.id} coordinates={station.position}>
            <StyledPopup>
              <div>{station.name}</div>
              <div>
                {station.bikes} bikes / {station.slots} slots
              </div>
            </StyledPopup>
          </Popup> */}
            </Map>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
