import React, { useState, useEffect } from 'react';
import { Avatar, IconButton, Modal } from '@material-ui/core';
import styles from './styles';
import { Done, Close, Edit } from '@material-ui/icons';
import { fTimeDate } from '@/actions/Helper';

export default ({
  DATA = {},
  handleChangeReplyStatus,
  message_id,
  token_id,
  handleEditMessage,
  editingMessage,
  setEditingMessage,
}) => {
  const cls = styles({ status: DATA.status, AI: DATA.AI, is_me: DATA.is_me });

  const editMessage = () => {
    if (editMessage) {
      handleEditMessage(editingMessage?.message, message_id, token_id);
    }
  };

  useEffect(() => {
    // console.log('Data updated', DATA);
    // You can perform additional actions when DATA changes
  }, [DATA]);
  return (
    <div>
      <Modal
        open={editingMessage && editingMessage.id === DATA.id ? true : false}>
        <div
          id="app_modal"
          className={cls.modal}
          onClick={(e) =>
            e.target.id === 'app_modal' && setEditingMessage(null)
          }
          style={{ width: '100%', height: '100%' }}>
          <div className={cls.modalBox}>
            <textarea
              rows={8}
              className={cls.input}
              value={editingMessage ? editingMessage.message : ''}
              onChange={(e) => {
                const message = e.target.value;
                setEditingMessage((prev) => {
                  return {
                    ...prev,
                    message,
                  };
                });
              }}
            />
            <div className={cls.actionContainer}>
              <button
                onClick={() => setEditingMessage(null)}
                className={cls.noBtn}>
                Cancel
              </button>
              <button
                onClick={() =>
                  editMessage(editingMessage.message, message_id, token_id)
                }
                className={cls.yesBtn}>
                Update
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className={cls.card}>
        <Avatar className={cls.avatar} src={DATA.avatar} alt={DATA.name} />
        <div className={cls.chatContainer}>
          {DATA.AI && DATA.status === 1 && (
            <div className={cls.actions}>
              <IconButton
                onClick={() =>
                  handleChangeReplyStatus(
                    'verify',
                    DATA.id,
                    message_id,
                    token_id
                  )
                }
                className={cls.tickIcon}>
                <Done />
              </IconButton>
              <IconButton
                onClick={() =>
                  handleChangeReplyStatus(
                    'unverify',
                    DATA.id,
                    message_id,
                    token_id
                  )
                }
                className={cls.closeIcon}>
                <Close />
              </IconButton>
              <IconButton
                onClick={() => setEditingMessage(DATA)}
                className={cls.editIcon}>
                <Edit />
              </IconButton>
            </div>
          )}
          <div className={cls.chat}>
            <div className={cls.title}>
              {DATA.message.split('\n').map((item, index) => (
                <span className={cls.chatLine} key={index}>
                  {item.includes('http') ? (
                    <a
                      className={cls.chatLineLink}
                      href={item}
                      target="_blank"
                      rel="noopener noreferrer">
                      {item}
                    </a>
                  ) : (
                    item
                  )}
                </span>
              ))}
              {/* {DATA.message?.includes(',') ? '' : DATA.message} */}
              <div className={cls.dateContainer}>
                <span>{fTimeDate(DATA.updated_at).split(' ')[1]}</span>
                <span>{fTimeDate(DATA.updated_at).split(' ')[0]}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
