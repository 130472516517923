import React, { useEffect, useState } from 'react';
import styles from './styles';
import { Grid, TextField, Button } from '@material-ui/core';
import { Loading, useNotify } from 'react-admin';
import { getReferredList, sendReferralInvite } from '@/actions';
import Pagination from '@/components/pagination';

export default ({ setSelectedTab }) => {
  const [formData, setFormData] = useState({
    f_name: '',
    l_name: '',
    company: '',
    email: '',
    phone: '',
  });
  const [referred, setReferred] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pages: [1],
  });
  const cls = styles();
  const notify = useNotify();

  const formInputs = [
    {
      label: 'First Name',
      name: 'f_name',
      type: 'text',
    },
    {
      label: 'Last Name',
      name: 'l_name',
      type: 'text',
    },
    {
      label: 'Company',
      name: 'company',
      type: 'text',
    },
    {
      label: 'Email',
      name: 'email',
      type: 'email',
    },
    {
      label: 'Phone Number',
      name: 'phone',
      type: 'text',
    },
    {
      label: 'Send Invite',
      type: 'submit',
    },
  ];

  const changeFormData = (key, value) => {
    setFormData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleSendInvite = () => {
    let formError = false;
    Object.keys(formData).map((key) => {
      if (!formData[key]) {
        formError = true;
      }
    });
    if (formError) {
      notify('Please fill in all fields', 'warning');
    } else {
      sendReferralInvite(formData)
        .then((res) => {
          if (!res.errors) {
            notify('User has been successfully invited', 'success');
            setFormData({
              f_name: '',
              l_name: '',
              company: '',
              email: '',
              phone: '',
            });
            getReferred();
          } else {
            Object.keys(res.errors).map((key) => {
              notify(res.errors[key][0], 'warning');
            });
          }
        })
        .catch((err) => {
          notify(err, 'warning');
        });
    }
  };

  const createArrayFromNumbers = (number) => {
    let numberArray = [];
    for (let i = 1; i <= number; i++) {
      numberArray.push(i);
    }

    return numberArray;
  };

  const getReferred = () => {
    getReferredList({ page: pagination.currentPage })
      .then((res) => {
        if (res.message === 'Complete your profile please.') {
          notify('Complete your profile please.', 'warning');
          setSelectedTab(0);
        } else {
          setReferred(res.data);
          setPagination((prev) => {
            return {
              ...prev,
              pages: createArrayFromNumbers(
                Math.ceil(
                  res.data.referred_users.total /
                    res.data.referred_users.per_page
                )
              ),
            };
          });
        }
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    getReferred();
  }, [pagination.currentPage]);

  return referred ? (
    <div className={cls.referContainer}>
      <div className={cls.referCard}>
        <div
          dangerouslySetInnerHTML={{
            __html: referred.current_referral_program.content1,
          }}
        />

        {/* <h2>Spread the word and earn rewards</h2>
          <p>
            When someone signs up with your unique referal link,both you and the
            refereed user will receive $100 after
          </p> */}
      </div>
      <Grid container>
        <Grid item xs={12} md={6}>
          <div>
            {formInputs.map((item, index) => {
              return item.type !== 'submit' ? (
                <div className={cls.referInputContainer}>
                  <span className={cls.inputLabel}>{item.label}</span>
                  <TextField
                    type={item.type}
                    placeholder=""
                    className={cls.input}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ min: 1 }}
                    value={formData[item.name]}
                    onChange={(ev) =>
                      changeFormData(item.name, ev.target.value)
                    }
                  />
                </div>
              ) : (
                <div className={cls.referInputContainer}>
                  <span />
                  <Button
                    onClick={() => handleSendInvite()}
                    className={cls.referBtn}>
                    {item.label}
                  </Button>
                </div>
              );
            })}
          </div>
          <div>
            <div className={cls.referListBottomBorder}>
              <span className={cls.referListTitle}>Referrals</span>
            </div>
            {referred.referred_users.data.map((user, index) => (
              <div
                key={index}
                className={`${cls.referListItem} ${cls.referListBottomBorder}`}>
                <div className={cls.referListItemTitle}>
                  <span className={cls.referListBigText}>
                    {`${user.f_name} ${user.l_name} (${user.company})`}
                  </span>
                  <span className={cls.referListSmallText}>
                    {user.status_text}
                  </span>
                </div>
                <div className={cls.referListSmallText}>${user.bonus}</div>
              </div>
            ))}
            <Pagination pagination={pagination} setPagination={setPagination} />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className={cls.cardContainer}>
            <div className={cls.card1}>
              <span className={cls.referListTitle}>Referrals</span>
              <div
                className={`${cls.referListItem} ${cls.referInfoBottomBorder}`}>
                <div className={cls.referListItemTitle}>
                  <span className={cls.referListBigText}>Invite</span>
                </div>
                <div className={cls.referListSmallBoldText}>
                  {referred.invite}
                </div>
              </div>
              <div
                className={`${cls.referListItem} ${cls.referInfoBottomBorder}`}>
                <div className={cls.referListItemTitle}>
                  <span className={cls.referListBigText}>
                    Potential rewards
                  </span>
                </div>
                <div className={cls.referListSmallBoldText}>
                  ${referred.potential_rewards}
                </div>
              </div>
              <div
                className={`${cls.referListItem} ${cls.referInfoBottomBorder}`}>
                <div className={cls.referListItemTitle}>
                  <span className={cls.referListBigText}>You have earned</span>
                </div>
                <div className={cls.referListSmallBoldText}>
                  ${referred.earned_rewards}
                </div>
              </div>
              <Button className={cls.referBtn2}>View Rewards</Button>
            </div>
            <div className={cls.card2}>
              {/* <span className={cls.referListTitle}>Your $100 reward</span>
                <p>
                  When someone signs up with your unique referral link, both you
                  and the refereed user will receive $100 after
                </p> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: referred.current_referral_program.content2,
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  ) : (
    <Loading />
  );
};
