import React, { useEffect, useState, useRef } from 'react';
import { useNotify, Loading } from 'react-admin';
import { Filter } from '@/components/Global';
import { getEmptyReceivingSchedule } from '@/actions';
import styles from './styles';
import Pagination from '@/components/pagination';
import Add from './Add';
import ListAdmin from './ListAdmin';
import MessageChange from './MessageChange';

export default (props) => {
  const cls = styles();
  const notify = useNotify();
  const [state, setState] = React.useState({
    edit: null,
    file: null,
  });
  const [popupMessage, setPopupMessage] = useState({
    DATA: null,
    loading: true,
    popUp: false,
  });

  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    pages: [],
  });
  const [loading, setLoading] = React.useState(true);
  const [DATATable, setDataTable] = React.useState([]);
  const [DATADefault, setDATADefault] = React.useState([]);
  const [filter, setFilter] = React.useState({
    open: false,
    DATA: [],
  });
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = { month: mm, day: dd, year: yyyy };

  const getEmptyReceivingScheduleList = () => {
    setLoading(true);
    getEmptyReceivingSchedule(`${today.year}-${today.month}-${today.day}`)
      .then((res) => {
        setDataTable(res.data.restrictions);
        setDATADefault(res.data.default);
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getEmptyReceivingScheduleList();
  }, [pagination.currentPage]);

  // React.useEffect(() => {
  //   const terminals = Object.keys(DATATable).map((key) => DATATable[key]);
  //   console.log("getEmptyReceivingScheduleList", terminals);

  // }, [DATATable]);
  return (
    <>
      {popupMessage.popUp && (
        <MessageChange
          mode={popupMessage.mode}
          // handleChangeStatusShifts={handleChangeStatusShifts}
          DATA={popupMessage.DATA}
          // terminal_id={popupMessage.terminal_id}
          date={popupMessage.date}
          // restriction_id={popupMessage.restriction_id}
          // shift_column={popupMessage.shift_column}
          // shift_value={popupMessage.shift_value}
          // shift_message={popupMessage.shift_message}
          // ssl_key={popupMessage.key}
          // indexContainer={popupMessage.indexContainer}
          // idx={popupMessage.idx}
          Close={() =>
            setPopupMessage((prev) => {
              return {
                ...prev,
                popUp: false,
              };
            })
          }
        />
      )}
      <div id="inventoryEmpty" className={cls.content}>
        <div className={cls.cardHeader}>
          <span className={cls.title}>Receivables Setting</span>
          <span>
            <span className={cls.title}>Import</span>
            <span
              className={cls.titleBlue}
              onClick={() =>
                setPopupMessage((prev) => ({
                  ...prev,
                  popUp: true,
                  mode: 'imortHtml',
                  // DATA: shift_message,
                  // terminal_id,
                  // date,
                  // restriction_id,
                  // shift_column,
                  // shift_value,
                  // shift_message,
                  // key,
                  // indexContainer,
                  // idx,
                }))
              }>
              HTML
            </span>
            <span className={cls.title}>Export</span>
            <span
              className={cls.titleBlue}
              // onClick={handleExport}
            >
              XLSX
            </span>
          </span>
        </div>

        <Add
          notify={notify}
          DATATable={DATATable}
          DATADefault={DATADefault}
          setData={(res) => {
            setFilter({ ...filter, DATATable });
          }}
        />

        {/* <Filter
        title="Gate Schedule"
        filter={filter}
        setFilter={setFilter}
        DATA={DATA}>
        <span className={cls.title}>Export</span>
        <span className={cls.titleBlue} onClick={handleExport}>
          XLSX
        </span>
      </Filter> */}

        {loading ? (
          <Loading />
        ) : (
          <ListAdmin
            DATA={DATATable}
            TerminalList={DATATable}
            DATADefault={DATADefault}
          />
        )}
      </div>
    </>
  );
};
