import React, { useEffect, useRef } from 'react';
import styles from './styles';
import { CircularProgress } from '@material-ui/core';
import Chat from '@/components/AI/Chat';
import InputAi from '@/components/AI/InputAi';
import { useSelector } from 'react-redux';

export default ({
  messages,
  handleChangeReplyStatus,
  handleEditMessage,
  editingMessage,
  setEditingMessage,
  getMessages,
  setMessages,
  selectedUser,
  notify,
}) => {
  const cls = styles();
  const ChatData = useSelector((state) => state.reducer.Chat || {});
  const { id, userId, chatId } = ChatData;

  const ref = useRef('');

  const RefreshData = () => {};

  React.useEffect(() => {
    if (id || userId || chatId) RefreshData();
  }, [userId]);

  useEffect(() => {
    if (ref.current) {
      const handleScroll = () => {
        const element = ref.current;
        if (element) {
          // Check if the scrollTop is at the top of the element
          const atTop = element.scrollTop;
          if (
            atTop <= 0 &&
            Number(messages.currentPage) * Number(messages.perPage) <
              messages.totalPage
          ) {
            getMessages(messages.currentPage + 1);
          }
        }
        // Attach the scroll event listener
      };
      ref.current.addEventListener('scroll', handleScroll);

      return () => {
        // Clean up the event listener when the component unmounts
        ref.current && ref.current.removeEventListener('scroll', handleScroll);
      };
    }
  }, [messages.totalPage, messages.currentPage]);

  useEffect(() => {
    if (ref.current) {
      const currentElement = ref.current;
      currentElement.scrollTop = 20000;
    }
  }, [messages.DATA.length]);


  return (
    <div className={cls.chatMessagesContainer} ref={ref}>
      {messages.DATA.length ? (
        <>
          {messages.loading ? (
            <div className={cls.loading}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <div>
                <div ref={ref} id="chatslist" className={cls.chatBox}>
                  {messages.DATA.map((i, idx) => (
                    <>
                      <Chat DATA={i} key={idx} />
                      {i.tokens &&
                        i.tokens.map(
                          (token, index2) =>
                            token && (
                              <Chat
                                DATA={{
                                  message: token.message_to_send,
                                  AI: true,
                                  status: token.status,
                                  id: token.id,
                                  updated_at: token.updated_at,
                                }}
                                handleChangeReplyStatus={
                                  handleChangeReplyStatus
                                }
                                key={index2}
                                message_id={i.whatsapp_message_id}
                                token_id={token.id}
                                handleEditMessage={handleEditMessage}
                                editingMessage={editingMessage}
                                setEditingMessage={setEditingMessage}
                              />
                            )
                        )}
                    </>
                  ))}
                </div>
              </div>
              {/* {console.log('mmmmmmmmm', messages)} */}
              <div className={cls.inputContainer}>
                <InputAi
                  type={'adminNewChat'}
                  // id={ChatData.userId}
                  notify={notify}
                  setChat={setMessages}
                  selectedUser={selectedUser}
                  // user={user}
                  // parseData={parseData}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <div className={cls.textContainer}>Select a Chat for start...</div>
      )}
    </div>
  );
};
