import { makeStyles } from '@material-ui/core/styles';
import { AboutBg } from '@/assets';

export default makeStyles(({ breakpoints: BP }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f5f5f5',
    flex: 1,
  },
  bg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 70px)',

    backgroundImage: `linear-gradient(1deg, rgba(0, 0, 0, 0.6) 1%, rgba(0, 0, 0, 0) 99%),
                        url(${AboutBg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  title: {
    fontFamily: 'Averta Bold',
    fontSize: 50,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#ffffff',
    [BP.down('xs')]: {
      fontSize: '2rem',
    },
  },

  content: {
    display: 'flex',
    flex: 1,
    padding: '100px 8vw',
    [BP.down('xs')]: {
      flexDirection: 'column',
      padding: '25px 0',
    },
  },
  contentTitle: {
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    fontSize: 28,
    color: '#202124',
    textAlign: 'left',
    marginBottom: 10,
  },
  des: {
    fontFamily: 'Averta Light',
    fontWeight: 300,
    fontSize: 14,
    color: '#202124',
    textAlign: 'justify',
    lineHeight: 1.63,
    marginTop: 10,
  },
  flex: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '0 30px',
    [BP.down('xs')]: {
      margin: '20px 0',
    },
  },

  img: {
    width: '100%',
    objectFit: 'cover',
    borderRadius: 20,
    height: '100%',
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    textDecorationColor: '#fff',
    fontSize: 20,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.25,
    textAlign: 'left',
    marginTop: 30,
    [BP.down('xs')]: {
      fontSize: '1rem',
    },
  },
  icon: {
    marginRight: 10,
    height: 25,
    width: 25,
  },
  terminalsMapDiv: {
    padding: '0px 20px',
  },
  terminalsMapSidebar: {
    boxShadow: '11px 0px 15px 0px #00000024',
    padding: '0 !important',
    zIndex: 65,
    background: 'white',
    borderRadius: '8px',
    height: '81vh',
    overflow: 'auto',
  },
  terminalsListCart: {
    padding: 8,
    borderBottom: '1px solid gray',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      background: '#ececec',
      transition: 'all 0.3s ease',
    },
  },
  terminalsListIconsSvg: {
    with: 30,
  },
  terminalTitle: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  terminalAddress: {
    fontSize: 12,
    color: '#8f8f8f',
  },
  terminalPort: {
    fontSize: 13,
  },
  iconsRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '10px',
  },
  iconsTitle: {
    fontSize: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#c48c00 !important',
    fill: '#c48c00 !important',
    textDecoration: 'unset',
  },
}));
