import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  mainContainer: {
    margin: '0 20px',
  },
  card: {
    padding: '20px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    minHeight: 139,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    marginBottom: 25,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  select: {
    width: '100%',
    fontFamily: 'Averta Light',
    '& > div': {
      border: 'solid 1px #707070',
    },
    '& > div:first-child > div': {
      padding: '0 0 0 10px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  date: {
    boxSizing: 'border-box',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    border: 'solid 1px #707070',
    padding: '0 10px',
    '& input': {
      padding: 0,
      cursor: 'pointer',
      fontFamily: 'Averta Light',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
  },
  but: {
    width: 100,
    minHeight: 38,
    borderRadius: 5,
    marginBottom: 20,
    backgroundColor: '#007fff',
    fontFamily: 'Averta Light',
    fontSize: 14,
    fontWeight: 300,
    color: '#fff',
    padding: 0,
    marginTop: 20,
    '&:hover': {
      backgroundColor: '#007fff',
    },
  },
  linkBtn: {
    width: 100,
    minHeight: 38,
    borderRadius: 5,
    marginBottom: 20,
    backgroundColor: '#007fff',
    fontFamily: 'Averta Light',
    fontSize: 14,
    fontWeight: 300,
    color: '#fff',
    padding: 0,
    marginTop: 20,
    textDecoration: 'none',
    marginLeft: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#007fff',
    },
  },
  checkbox: {
    '& svg': {
      fill: '#f8b100',
    },
    '& hover': {
      background: '#f8b1001a',
    },
  },
  loc: {
    '& svg': {
      fill: '#f8b100',
    },

    fontFamily: 'Averta Light',
    width: '100%',
    marginRight: 15,
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& > div': {
      border: 'solid 1px #707070',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginTop: '16px',
  },
  resetBtn: {
    width: '63px',
    height: '21px',
    color: '#ffffff',
    background: '#FF0000',
    borderRadius: '5px',
    marginRight: '10px',
    fontSize: '11px',
    borderColor: 'transparent',
  },
  addBtn: {
    width: '63px',
    height: '21px',
    color: '#ffffff',
    background: '#007FFF',
    borderRadius: '5px',
    marginRight: '10px',
    fontSize: '11px',
    borderColor: 'transparent',
  },
  search: {
    display: 'flex',
    alignItems: 'center',
  },
  searchBox: {
    height: '28px',
    background: '#ffffff',
    border: '1px solid #E0E0E0',
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    boxSizing: 'border-box',
    margin: '0 16px',
    '& input': {
      border: 'none',
      background: 'transparent',
      flex: '1',
      '&:focus-visible': {
        border: 'none !important',
        outline: 'none',
      },
    },
  },
  searchIcon: {
    width: '18px',
    height: '18px',
    fill: '#E0E0E0',
    paddingRight: '10px',
  },
  searchInput: {
    '&::placeholder': {
      color: '#E0E0E0',
    },
  },
  title: {
    // fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '23px',
  },
  popupTableTitle: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 1,
    color: '#43424b',
  },
  popupTableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  export: {
    // fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '23px',
  },
  xlsx: {
    // fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '23px',
    color: '#298AE2',
    margin: '0 16px',
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    // fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '23px',
    cursor: 'pointer',
  },
  filterIcon: {
    width: '16.53px',
    height: '16.67px',
    marginLeft: '16px',
  },
  listContainer: {
    overflow: 'auto',
    padding: '0 10px',
  },
  availableListContainer: {
    padding: '0 10px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '20px 0 50px',
    minWidth: 1400,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  loader: {
    width: '25 !important',
    height: '25 !important',
    color: '#f8b100',
  },
  avalableList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: 550,
  },
  thead: {
    display: 'flex',
    fontFamily: 'Averta Bold',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.2,
    color: '#43424b',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: 5,
    [BP.up('lg')]: {
      fontSize: 15,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },
  availableThead: {
    display: 'flex',
    fontFamily: 'Averta Bold',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.2,
    color: '#43424b',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingRight: 20,
    paddingLeft: 5,
    [BP.up('lg')]: {
      fontSize: 15,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },
  tbody: {
    display: 'flex',
    flexDirection: 'column',
  },
  availableTbody: {
    display: 'flex',
    flexDirection: 'column',
    height: 460,
    overflow: 'auto',
    padding: '0 5px',
  },
  tableRow: {
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 61,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    margin: '5px 0',
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    color: '#43424b',
    textAlign: 'center',

    [BP.up('lg')]: {
      fontSize: 14,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },
  bigText: {
    fontFamily: 'Averta Bold',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 2.7,
    color: '#303030',
  },
  bold: {
    fontFamily: 'Averta Bold',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 3.86,
    color: '#E0E0E0',
  },

  // bigText: {
  //   '&:hover': {
  //     color: '#000000',
  //   },
  // },
  bold: {
    '&:hover': {
      color: '#000000',
    },
  },
  tableActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  delIcon: {
    height: 20,
    width: 20,
    fill: '#e4464b',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  editIcon: {
    height: 20,
    width: 20,
    fill: '#f8b100',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '23px',
  },
  importSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  import: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '23px',
  },
  xlsxTitle: {
    color: '#0CA549',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '23px',
    marginLeft: '16px',
  },
  textInput: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #707070',
    marginTop: 5,
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
  containerTable: {
    width: '100%',
    '& tr': {
      display: 'flex',
    },
    '& th': {
      color: '#777575',
      fontSize: '13px',
      padding: '10px',
    },
    '& td': {
      padding: '0 10px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#000000',
      fontSize: '13px',
      height: '27px',
      borderBottom: '1px solid #dedede',
      '& label': {
        margin: 0,
      },
      '& svg': {
        fill: '#000000',
      },
    },
  },
  containerChecBox: {
    accentColor: '#000000',
  },
  checkbox: {
    '& svg': {
      fill: '#f8b100',
    },
    '& hover': {
      background: '#f8b1001a',
    },
  },
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBox: {
    width: '350px',
    minHeight: '397px',
    background: '#FFFFFF',
    borderRadius: '5px',
    padding: '10px 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  containersTableContainer: {
    maxHeight: '300px',
    overflow: 'auto',
    flex: '1',
  },
  closeModal: {
    textAlign: 'end',
    '& img': {
      width: '10px',
      height: '10px',
      cursor: 'pointer',
    },
  },
  modalTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '23px',
    color: '#000000',
    margin: '0 0 20px 0',
  },
  updateContainersBtn: {
    background: '#007fff',
    borderRadius: '5px',
    border: 'none',
    color: '#ffffff',
    fontSize: '13px',
    padding: '10px 15px',
    margin: '20px 15px 0 auto',
    display: 'block',
  },
  popup: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    // zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '99999999999999999999999999999',
  },

  popUpCard: {
    position: 'relative',
    padding: '30px 5px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    minHeight: '200px',
    maxWidth: '80%',
    animation: 'zoomIn 0.7s',
    height: 'auto',
    minWidth: 400,
  },

  close: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: '#e4464b',
  },

  xlSpan: {
    display: 'flex',
    margin: '15px 0',
    borderRadius: '4px',
    fontSize: '0.875rem',
    lineHeight: '1.75rem',
    color: '#298AE2',
    display: 'flex',
    alignItems: 'center',
  },

  xlBtn: {
    background: '#f8b100',
    marginBottom: '15px',
    color: '#ffffff',
    width: '100%',
    fontWeight: 'bold',
    '&:hover': {
      background: '#f8b100',
      color: '#ffffff',
    },
  },

  pagination: {
    display: 'flex',
    alignItems: 'cneter',
    justifyContent: 'center',
    marginTop: 15,
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2rem',
      height: '2rem',
      border: '1px solid #CBCBCB',
      cursor: 'pointer',
      margin: '0.25rem',
      color: '#43424b',
    },
  },
  fileLoader: {
    color: '#ffffff !important',
    width: '25px !important',
    height: '25px !important',
  },
  fileName: {
    marginBottom: '15px',
    fontSize: '14px',
    color: 'gray',
  },
  activePage: {
    background: '#000000',
    color: '#ffffff !important',
  },
  containerActions: {
    display: 'flex',
    alignItems: 'cneter',
    justifyContent: 'space-between',
  },
  containerRowRound: {
    overflow: 'hidden',
    width: '100%',
    minWidth: 160,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    backgroundColor: '#d7d7d7',
    fontFamily: 'Averta Light',
    fontSize: 15,
    fontWeight: 300,
    lineHeight: 1.27,
    color: '#9f9f9f',
    cursor: 'pointer',
    ['@media (max-width:450px)']: {
      marginLeft: 0,
      marginTop: 5,
      width: '100%',
    },
  },
  chassisText: {
    textAlign: 'center',
    display: 'flex',
    color: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    padding: 5,
    flex: '1',
    '&:hover': {
      background: '#edecec',
    },
    '&:first-child': {
      borderRight: '2px solid #ffffff',
      borderRadius: '5px 0 0 5px',
    },
    '&:last-child': {
      borderRadius: '0 5px 5px 0',
    },
  },
  activeChassis: {
    backgroundColor: '#f8b100 !important',
    color: '#000 !important',
    '&:hover': {
      background: '#ffd616 !important',
    },
  },
}));
