import React, { useEffect, useState } from 'react';
import { fDate } from '@/actions/Helper';
import styles from './styles';
import Containers from './Containers';
import Share from './Share/index';
import { getInventoryShareLink } from '@/actions';
import { useNotify } from 'react-admin';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag } from 'react-dnd';
import DragDrop from '../../components/DragDrop/index';
import { Icons } from '@/assets';
import { ReactSVG } from 'react-svg';

export default ({ DATA = [], Del, Edit, getInventoryList, currentPage }) => {
  const cls = styles();
  const [showContainersModal, setShowContainersModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [shareLink, setShareLink] = useState({
    loading: null,
    DATA: null,
  });
  // console.log('ddata', DATA);

  const notify = useNotify();

  const getActivContainers = (inventory, target) => {
    if (inventory) {
      let activeContainers = [];
      let deactiveContainers = [];
      inventory.containers.forEach((container) => {
        if (container.status === 1) {
          activeContainers.push(container);
        } else if (container.status === 0) {
          deactiveContainers.push(container);
        }
      });

      if (deactiveContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`all ${cls.containerChecBox}`}
            checked={true}
          />
        );
      } else if (activeContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`any ${cls.containerChecBox}`}
            checked={false}
          />
        );
      } else {
        return (
          <input
            type="checkbox"
            checked={true}
            className={`some ${cls.containerChecBox}`}
          />
        );
      }
    }
  };

  const handleGetShareLink = (i) => {
    setShareLink({ loading: i.id });
    getInventoryShareLink(i.id)
      .then((res) => {
        setShareModal(i);
        setShareLink({
          loading: null,
          DATA: res,
        });
      })
      .catch((err) => {
        setShareLink({ loading: null });
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    let someCompletedCheckboxs = document.getElementsByClassName('some');
    let allCompletedCheckboxs = document.getElementsByClassName('all');
    let notCompletedCheckboxs = document.getElementsByClassName('any');
    for (let i = 0; i < someCompletedCheckboxs.length; i++) {
      someCompletedCheckboxs[i].indeterminate = true;
    }
    for (let i = 0; i < allCompletedCheckboxs.length; i++) {
      allCompletedCheckboxs[i].indeterminate = false;
    }
    for (let i = 0; i < notCompletedCheckboxs.length; i++) {
      notCompletedCheckboxs[i].indeterminate = false;
      notCompletedCheckboxs[i].checked = false;
    }
  }, [getActivContainers, getInventoryList]);
  console.log('DATA', DATA);

  return (
    <div id="amirbios" className={cls.listContainer}>
      {showContainersModal && selectedRow && (
        <Containers
          open={showContainersModal}
          DATA={DATA}
          selectedRow={selectedRow}
          setShowContainersModal={setShowContainersModal}
          getInventoryList={getInventoryList}
        />
      )}
      {shareModal && !shareLink.loading && (
        <Share
          shareLink={shareLink.DATA}
          shareModal={shareModal}
          setShareModal={setShareModal}
        />
      )}
      <div className={cls.tableCentertDiv}>
        <table className={cls.listTableCenter}>
          <thead className={cls.thead}>
            <th className={cls.tableThTop} style={{ flex: 0.2 }}>
              #
            </th>
            <th className={cls.tableThTop} style={{ flex: 1 }}>
              <ReactSVG src={Icons.importIcon} className={cls.icon} />
              Your Empties
            </th>
            <th className={cls.tableThTop} style={{ flex: 0.6 }}>
              <ReactSVG src={Icons.importIcon} className={cls.icon} />
              Your Exports
            </th>
          </thead>
          <thead className={cls.thead}>
            <td className={cls.tableTD} style={{ flex: 0.211 }}>
              <tr className={cls.tableRowHeader}>
                <th className={cls.tableTh} style={{ flex: 0.25 }}>
                  #
                </th>
                <th className={cls.tableTh} style={{ flex: 1 }}>
                  Size
                </th>
                <th className={cls.tableTh} style={{ flex: 1 }}>
                  SSL
                </th>
              </tr>
            </td>
            <td className={cls.tableTD} style={{ flex: 1 }}>
              <tr className={cls.tableRowHeader} style={{ flex: 1 }}>
                <th className={cls.tableTh} style={{ flex: 1 }}>
                  Container
                </th>
                <th className={cls.tableTh} style={{ flex: 1 }}>
                  Chassis
                </th>
                <th className={cls.tableTh} style={{ flex: 1 }}>
                  Gated Out
                </th>
                <th className={cls.tableTh} style={{ flex: 1 }}>
                  Gated In
                </th>
                <th className={cls.tableTh} style={{ flex: 1.2 }}>
                  Return Terminal
                </th>
                <th className={cls.tableTh} style={{ flex: 1 }}>
                  Return Apt
                </th>
                <th className={cls.tableTh} style={{ flex: 1 }}>
                  Est. PerDiem
                </th>
                <th className={cls.tableTh} style={{ flex: 1 }}>
                  Empty Location
                </th>
              </tr>
            </td>
            <td className={cls.tableTD} style={{ flex: 0.6 }}>
              <tr className={cls.tableRowHeader} style={{ flex: 1 }}>
                <th className={cls.tableTh} style={{ flex: 1.5 }}>
                  Booking #
                </th>
                <th className={cls.tableTh} style={{ flex: 1.5 }}>
                  ERD
                </th>
                <th className={cls.tableTh} style={{ flex: 1.5 }}>
                  Cutoff
                </th>
                <th className={cls.tableTh} style={{ flex: 1.8 }}>
                  Terminal
                </th>
                <th className={cls.tableTh} style={{ flex: 1.5 }}>
                  Location
                </th>
                <th className={cls.tableTh} style={{ flex: 1.1 }}>
                  Saving
                </th>
              </tr>
            </td>
          </thead>
          <tbody className={cls.tbody}>
            {Object.values(DATA).map((i, idx) => (
              <tr className={cls.tableRowMain} key={idx}>
                <td
                  rowSpan={i.empties?.length}
                  className={cls.tableTD}
                  style={{ flex: 0.2 }}>
                  <tr
                    style={{
                      height:
                        i.empties?.length > 0
                          ? `${
                              i.empties?.length * 40 +
                              (i.empties?.length - 1) * 12
                            }px`
                          : i.exports?.length > 0
                          ? `${
                              i.exports?.length * 40 +
                              (i.exports?.length - 1) * 12
                            }px`
                          : '40px',
                      flex: 0.25,
                    }}
                    className={cls.tableRowLeft}>
                    <td
                      rowSpan={i.empties?.length}
                      className={cls.tableTD}
                      style={{ flex: 1 }}>
                      {idx + 1 + (currentPage - 1) * 10}
                    </td>
                    <td
                      rowSpan={i.empties?.length}
                      className={cls.tableTD}
                      style={{ flex: 1 }}>
                      {i.size_type}
                    </td>
                    <td
                      rowSpan={i.empties?.length}
                      className={cls.tableTD}
                      style={{ flex: 1 }}>
                      {i.cabin?.company?.name}
                    </td>
                  </tr>
                </td>
                <td
                  rowSpan={i.empties?.length}
                  className={cls.tableTD}
                  style={{ flex: 1 }}>
                  {Object.values(i?.empties).map((i, idx) => (
                    <tr className={cls.tableRow} style={{ flex: 1 }}>
                      <td className={cls.tableTDMain} style={{ flex: 1 }}>
                        #151653
                      </td>
                      <td className={cls.tableTDMain} style={{ flex: 1 }}>
                        {fDate(i.date)}
                      </td>
                      <td className={cls.tableTDMain} style={{ flex: 1 }}>
                        {fDate(i.date)}
                      </td>
                      <td className={cls.tableTDMain} style={{ flex: 1 }}>
                        LAP
                      </td>
                      <td className={cls.tableTDMain} style={{ flex: 1.2 }}>
                        {i.loc}
                      </td>
                      <td className={cls.tableTDMain} style={{ flex: 1 }}>
                        300$
                      </td>
                      <td className={cls.tableTDMain} style={{ flex: 1 }}>
                        300$
                      </td>
                      <td className={cls.tableTDMain} style={{ flex: 1 }}>
                        300$
                      </td>
                      {/* <td className={cls.matched} style={{ flex: 1 }}></td> */}
                    </tr>
                  ))}
                </td>
                <td
                  rowSpan={i.empties?.length}
                  className={cls.tableTD}
                  style={{ flex: 0.6 }}>
                  {Object.values(i?.exports).map((i, idx) => (
                    <tr className={cls.tableRow} style={{ flex: 1 }}>
                      <td className={cls.tableTDMain} style={{ flex: 1 }}>
                        #151653
                      </td>
                      <td className={cls.tableTDMain} style={{ flex: 1 }}>
                        {fDate(i.date)}
                      </td>
                      <td className={cls.tableTDMain} style={{ flex: 1 }}>
                        {fDate(i.date)}
                      </td>
                      <td className={cls.tableTDMain} style={{ flex: 1 }}>
                        LAP
                      </td>
                      <td className={cls.tableTDMain} style={{ flex: 1.2 }}>
                        {i.loc}
                      </td>
                      <td className={cls.tableTDMain} style={{ flex: 1 }}>
                        300$
                      </td>
                    </tr>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className={cls.tableLefttDiv}>
        <table id="listTableCenter" className={cls.listTableLeft}>
          <thead className={cls.thead}>
            <th className={cls.tableThTop} style={{ flex: 1 }}>
              <ReactSVG src={Icons.importIcon} className={cls.icon} />
              Your Empties
            </th>
          </thead>
          <thead className={cls.thead}>
            <th className={cls.tableTh} style={{ flex: 1 }}>
              Container
            </th>
            <th className={cls.tableTh} style={{ flex: 1 }}>
              Chassis
            </th>
            <th className={cls.tableTh} style={{ flex: 1 }}>
              Gated Out
            </th>
            <th className={cls.tableTh} style={{ flex: 1 }}>
              Gated In
            </th>
            <th className={cls.tableTh} style={{ flex: 1.2 }}>
              Return Terminal
            </th>
            <th className={cls.tableTh} style={{ flex: 1 }}>
              Return Apt
            </th>
            <th className={cls.tableTh} style={{ flex: 1 }}>
              Est. PerDiem
            </th>
            <th className={cls.tableTh} style={{ flex: 1 }}>
              Empty Location
            </th>
          </thead>
          <tbody className={cls.tbody}>
            
          </tbody>
        </table>
      </div> */}

      {/* <div className={cls.tableRightDiv}>
        <table className={cls.listTableRight}>
          <thead className={cls.thead}>
            <th className={cls.tableThTop} style={{ flex: 1 }}>
              <ReactSVG src={Icons.importIcon} className={cls.icon} />
              Your Exports
            </th>
          </thead>
          <thead className={cls.thead}>
            <th className={cls.tableTh} style={{ flex: 1.5 }}>
              Booking #
            </th>
            <th className={cls.tableTh} style={{ flex: 1.5 }}>
              ERD
            </th>
            <th className={cls.tableTh} style={{ flex: 1.5 }}>
              Cutoff
            </th>
            <th className={cls.tableTh} style={{ flex: 1.8 }}>
              Terminal
            </th>
            <th className={cls.tableTh} style={{ flex: 1.5 }}>
              Location
            </th>
            <th className={cls.tableTh} style={{ flex: 1.1 }}>
              Saving
            </th>
          </thead>
          <tbody className={cls.tbody}>
            {Object.values(DATA).map((i, idx) =>
              Object.values(i?.exports).map((i, idx) => (
                <tr className={cls.tableRow} key={idx}>
                  <td className={cls.tableTD} style={{ flex: 1.5 }}>
                    #151653
                  </td>
                  <td className={cls.tableTD} style={{ flex: 1.5 }}>
                    {fDate(i.date)}
                  </td>
                  <td className={cls.tableTD} style={{ flex: 1.5 }}>
                    {fDate(i.date)}
                  </td>
                  <td className={cls.tableTD} style={{ flex: 1.8 }}>
                    LAP
                  </td>
                  <td className={cls.tableTD} style={{ flex: 1.5 }}>
                    {i.loc}
                  </td>
                  <td className={cls.tableTD} style={{ flex: 1.1 }}>
                    300$
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div> */}
    </div>
  );
};
