const LoginIcons = {
  bg: require('./img/login-bg.jpg'),
  fb: require('./img/facebook.svg'),
  google: require('./img/google.svg'),
};

const HeaderIcons = {
  logo: require('./img/logo.svg'),
  logoWhite: require('./img/logo-white.svg'),
  search: require('./img/search.png'),
  bell: require('./img/bell.svg'),
  bell_notify: require('./img/bell_notify.svg'),
  user: require('./img/user.svg'),
};

const SidebarIcons = {
  Dashboard: require('./img/sidebar/dashboard.svg'),
  Search: require('./img/sidebar/smart.svg'),
  Request: require('./img/sidebar/requests.svg'),
  arrowcurcle: require('./img/sidebar/arrowcurcle.svg'),
  Orders: require('./img/sidebar/orders.svg'),
  Inventory: require('./img/sidebar/inventory.svg'),
  Archive: require('./img/sidebar/archive.svg'),
  Report: require('./img/sidebar/report.svg'),
  Members: require('./img/sidebar/members.svg'),
  Content: require('./img/sidebar/content.svg'),
  Chat: require('./img/sidebar/chat.svg'),
  Ports: require('./img/sidebar/ports.svg'),
  GateSchedule: require('./img/sidebar/gateschedule.svg'),
  Finance: require('./img/sidebar/finance.svg'),
  Integration: require('./img/sidebar/integration.svg'),
  Developer: require('./img/sidebar/developer.svg'),
  Update: require('./img/sidebar/update.svg'),
  Docs: require('./img/sidebar/docs.svg'),
  Logs: require('./img/sidebar/logs.svg'),
};

const ChatBarIcons = {
  archive: require('./img/chatbar/archive.svg'),
  chat: require('./img/chatbar/chat.svg'),
  chat_notify: require('./img/chatbar/chat_notify.svg'),
  support: require('./img/chatbar/support.svg'),
  sendIcon: require('./img/chatbar/sendIcon.svg'),
  fileIcon: require('./img/chatbar/fileIcon.svg'),
  emojiIcon: require('./img/chatbar/emojiIcon.svg'),
  AI: require('./img/chatbar/AI.svg'),
};

const Icons = {
  container: require('./img/container.png'),
  filter: require('./img/filter.svg'),
  truck: require('./img/truck.svg'),
  welcome: require('./img/welcome.jpg'),
  tickICon: require('./img/tick.svg'),
  borderedTick: require('./img/bordered-tick.svg'),
  emptyTickContainer: require('./img/tick-container.svg'),
  has_containers_empty: require('./img/has-containers-empty.svg'),
  has_containers_half: require('./img/has_containers_half.svg'),
  has_containers_full: require('./img/has_containers_full.svg'),
  has_containers: require('./img/has_containers.svg'),
  website_icon: require('./img/website.svg'),
  restraction_icon: require('./img/examption.svg'),
  examption_icon: require('./img/restriction.svg'),
  locationMap: require('./img/location.png'),
  dayDateHighlight: require('./img/dayDateHighlight.svg'),
  dayDateHighlightDate: require('./img/dayDateHighlightDate.svg'),
  xiconn: require('./img/xiconn.svg'),
  xiconnnn: require('./img/xiconnnn.svg'),
  close: require('./img/close.svg'),
  arrowTop: require('./img/arrowTop.svg'),
  arrowDown: require('./img/arrowDown.svg'),
  transaction: require('./img/transaction.svg'),
  message: require('./img/HoverIcon.svg'),
  attention: require('./img/attention.svg'),
  WhatsApp: require('./img/whatsapp.svg'),
  directMessage: require('./img/directMessage.svg'),
  pendingIcon: require('./img/pending.svg'),
  checkIcon: require('./img/check-symbol-4794.svg'),
  rejectIcon: require('./img/rejected.svg'),
  userProfileIcon: require('./img/userProfile.svg'),
  passwordIcon: require('./img/password-lock-12.svg'),
  refreshIcon: require('./img/refresh-svgrepo-com.svg'),
  stop: require('./img/stop.svg'),
  stopRed: require('./img/stopred.svg'),
  options: require('./img/options.svg'),
  dragdrop: require('./img/dragdrop.svg'),
  infoIcon: require('./img/information-icon.svg'),
  importIcon: require('./img/export.svg'),
};

const DashboardIcons = {
  arrowBlack: require('./img/dash/arrow-b.svg'),
  arrowWhite: require('./img/dash/arrow-w.svg'),
  welcome: require('./img/dash/welcome.jpg'),
};

const ContainerIcons = [
  require('./img/container/1.svg'),
  require('./img/container/2.svg'),
  require('./img/container/3.svg'),
  require('./img/container/4.svg'),
];

const LandingIcon = {
  appstore: require('./img/Landing/appstore.svg'),
  google: require('./img/Landing/google.svg'),
  Bg: require('./img/Landing/landing-bg.jpg'),
  App: require('./img/Landing/app-image.png'),
};

const ContactBg = require('./img/contact-bg.jpg');
const AboutBg = require('./img/about-bg.jpg');
const WhatsappBg = require('./img/wpback.jpg');

export const HowIcons = {
  container: require('./img/how/container.svg'),
  custom: require('./img/how/custom.svg'),
};

export const HowSteps = {
  final: require('./img/how/steps/final.jpg'),
  inv: require('./img/how/steps/inv.jpg'),
  req: require('./img/how/steps/req.jpg'),
  sign: require('./img/how/steps/sign.jpg'),
  search: require('./img/how/steps/search.jpg'),
};

const MemberIcons = {
  addMember: require('./img/addMember.svg'),
  editMember: require('./img/edit.svg'),
  deleteMember: require('./img/trash.svg'),
  verifyMember: require('./img/email.svg'),
  search: require('./img/search.svg'),
};

const AdminTopMenuIcons = {
  dashboard: require('./img/adminTopMenu/dashboard.svg'),
  smartSearch: require('./img/adminTopMenu/smart-search.svg'),
  inventory: require('./img/adminTopMenu/inventory.svg'),
  orders: require('./img/adminTopMenu/orders.svg'),
  request: require('./img/adminTopMenu/request.svg'),
  report: require('./img/adminTopMenu/report.svg'),
};

const TerminalsIcon = {
  EVERPORT: require('./img/terminals/EVERPORT.png'),
  FMS: require('./img/terminals/FMS.png'),
  LACT: require('./img/terminals/LACT.png'),
  PCT: require('./img/terminals/PCT.png'),
  PST: require('./img/terminals/PST.png'),
  Trapac: require('./img/terminals/Trapac.png'),
  WBCT: require('./img/terminals/WBCT.png'),
  WCC: require('./img/terminals/WCC.png'),
  YTI: require('./img/terminals/YTI.png'),
  APM: require('./img/terminals/APM.png'),
  LBCT: require('./img/terminals/LBCT.png'),
  TTI: require('./img/terminals/TTI.png'),
  ITS: require('./img/terminals/ITS.png'),
  MATSON: require('./img/terminals/MATSON.png'),
  SSA: require('./img/terminals/SSA.png'),
  STL: require('./img/terminals/STL.png'),
  PTS: require('./img/terminals/PTS.png'),
};

export {
  LoginIcons,
  HeaderIcons,
  SidebarIcons,
  ChatBarIcons,
  TerminalsIcon,
  Icons,
  DashboardIcons,
  ContainerIcons,
  LandingIcon,
  MemberIcons,
  AdminTopMenuIcons,
  ContactBg,
  AboutBg,
  WhatsappBg,
};
