import React, { useEffect, useState } from 'react';
import styles from './styles';
import Select from 'react-select';
import TMSCard from '@/components/IntegrationExport/TMSCard';
import LocalExcelCard from '@/components/IntegrationExport/LocalExcelCard';
import {
  adminChangeActiveExcelModeExport,
  adminGetExcelConfigsExport,
  adminGetSingleUser,
  adminSaveExcelConfigsExport,
  getAdminFinanceTrackingCompanies,
} from '@/actions';
import { useNotify } from 'react-admin';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import SSLTable from '@/components/IntegrationExport/SSLTable';
import MTOTable from '@/components/IntegrationExport/MTOTable';

import SizeTable from '@/components/IntegrationExport/SizeTable';
import EmailCard from '@/components/IntegrationExport/EmailCard';
import ColumnsTable from '@/components/IntegrationExport/ColumnsTable';
import CityTable from '@/components/IntegrationExport/CityTable';
import SeparationTable from '@/components/IntegrationExport/SeparationTable';

export default () => {
  const cls = styles();
  const [pageContent, setPageContent] = useState({
    label: null,
    vlue: null,
  });
  const [companies, setCompanies] = useState([]);
  const [activeTms, setActiveTms] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [tmsType, setTmsType] = useState(null);
  const [tmsExcelMode, setTmsExcelMode] = useState(null);
  const [tablesData, setTablesData] = useState({});
  const [loadTables, setLoadTables] = useState(false);
  const [tableCities, setTableCities] = useState([]);
  const [tableSeprations, setTableSeprations] = useState([]);
  const [state, setState] = useState({
    DATA: {},
    loading: false,
  });

  const notify = useNotify();

  const pageContentOptions = [
    {
      label: 'TMS',
      value: 1,
    },
    {
      label: 'Excel And Goole Sheet',
      value: 3,
    },
    {
      label: 'Email',
      value: 4,
    },
    {
      label: 'Application',
      value: 5,
    },
  ];

  const getActiveExcelLabel = (value) => {
    switch (value) {
      case 1:
        return 'TMS';
        break;
      case 2:
        return 'TMS';
        break;
      case 3:
        return 'Excel And Goole Sheet';
        break;
      case 4:
        return 'Email';
        break;
      case 5:
        return 'Application';
        break;
      default:
        break;
    }
  };

  const handleSelectCompany = (company) => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    localStorage.setItem('company', JSON.stringify(company));
    setSelectedCompany(company);
    setLoadTables(true);
    adminGetSingleUser(company.value)
      .then((data) => {
        setState({
          loading: false,
          DATA: data,
        });
        setPageContent({
          value: data.active_excel_mode_export,
          label: getActiveExcelLabel(data.active_excel_mode_export),
        });
        if (
          data.active_excel_mode_export === 1 ||
          data.active_excel_mode_export === 2
        ) {
          setTmsExcelMode({
            value: data.active_excel_mode_export === 1 ? 'FTP' : 'Email',
            label: data.active_excel_mode_export === 1 ? 'FTP' : 'Email',
          });
        }
        setActiveTms(data.enable_ftp_integration);
        setActiveTms(data.enable_ftp_integration);
        if (data.tms_type === 'manual' && company) {
          fetchTablesData(company.value);
        }
      })
      .catch((err) => {
        notify(err, 'warning');
        setState({
          DATA: {},
          loading: true,
        });
      });
  };

  const fetchTablesData = (company) => {
    setLoadTables(true);
    adminGetExcelConfigsExport(company || selectedCompany.value).then(
      (data) => {
        setLoadTables(false);
        setTablesData(data);
      }
    );
  };

  const handleSaveExcelConfig = () => {
    let cities = {};
    tableCities.forEach((item) => {
      if (item.user && item.smart) {
        cities[item.user] = item.smart.value;
      }
    });
    let apiData = {
      ssl: tablesData.ssl,
      mtos: tablesData.mtos,
      size_type: tablesData.size_type,
      columns: tablesData.columns,
      cities,
      separations: tableSeprations,
    };
    adminSaveExcelConfigsExport(apiData, selectedCompany.value).then((data) => {
      notify('update successfully');
    });
  };

  const handleSelectIntegrationType = (item) => {
    setPageContent(item);
  };

  const handleChangeActiveExcelMode = (excelMode) => {
    setLoadTables(true);
    adminChangeActiveExcelModeExport(selectedCompany.value, excelMode).then(
      (user) => {
        if (
          user.active_excel_mode_export === 3 ||
          user.active_excel_mode_export === 4 ||
          user.active_excel_mode_export === 5
        ) {
          setLoadTables(false);
          let adaptedCities = [];
          if (user.excel_config_export.cities) {
            Object.keys(user.excel_config_export.cities).forEach(
              (key, index) => {
                adaptedCities.push({
                  user: key,
                  smart: {
                    value: user.excel_config_export.cities[key],
                    label: user.excel_config_export.cities[key].city,
                  },
                });
              }
            );
          }
          setTableCities(adaptedCities);
          user.excel_config_export_data &&
            setTableSeprations(user.excel_config_export_data.separations);
          setTablesData({
            ...user.excel_config_export_data,
          });
        } else if (
          user.active_excel_mode_export === 1 ||
          user.active_excel_mode_export === 2
        ) {
          setLoadTables(false);
          let adaptedCities = [];
          if (user.tms_export_data.cities) {
            Object.keys(user.tms_export_data.cities).forEach((key, index) => {
              adaptedCities.push({
                user: key,
                smart: {
                  value: user.tms_export_data.cities[key],
                  label: user.tms_export_data.cities[key].city,
                },
              });
            });
          }
          setTableCities(adaptedCities);
          user.excel_config_export_data &&
            setTableSeprations(user.excel_config_export_data.separations);
          setTablesData({
            ...user.tms_export_data,
          });
          setTmsType({
            label: user.tms_export.name,
            value: user.tms_export.slug,
          });
        }
      }
    );
  };

  useEffect(() => {
    if (pageContent && pageContent.value) {
      setActiveTms(false);
      localStorage.setItem(
        'integration_export_type',
        JSON.stringify(pageContent)
      );
      handleChangeActiveExcelMode(pageContent.value);
    }
  }, [pageContent]);

  useEffect(() => {
    if (tmsExcelMode && tmsExcelMode.value) {
      let excelMode =
        tmsExcelMode.value === 'FTP'
          ? 1
          : tmsExcelMode.value === 'Email'
          ? 2
          : null;
      excelMode && handleChangeActiveExcelMode(excelMode);
    }
  }, [tmsExcelMode]);

  useEffect(() => {
    getAdminFinanceTrackingCompanies().then((data) => {
      let companyData = [];
      data.forEach((item, index) => {
        companyData.push({
          value: item.id,
          label: item.company,
        });
      });
      setCompanies(companyData);
    });
    let integration_export_type = localStorage.getItem(
      'integration_export_type'
    );
    if (integration_export_type) {
      setPageContent(JSON.parse(integration_export_type));
    }
    let company = localStorage.getItem('company');
    if (company) {
      handleSelectCompany(JSON.parse(company));
    }
  }, []);

  // useEffect(() => {
  //   let adaptedCities = [];
  //   // if (tablesData.cities) {
  //   //   Object.keys(tablesData).forEach((key, index) => {
  //   //     adaptedCities.push({
  //   //       user: key,
  //   //       smart: {
  //   //         value: tablesData.cities[key],
  //   //         label: tablesData.cities[key].city,
  //   //       },
  //   //     });
  //   //   });
  //   //   setTableCities(adaptedCities)
  //   // }
  // }, [tablesData]);

  return (
    <div className={cls.content}>
      <h2 className={cls.title}>Integration {'>'} Export</h2>
      <div className={cls.card}>
        <span className={cls.formDescription}>Select Company:</span>
        <div>
          <Select
            isClearable
            placeholder="Company"
            className={cls.select}
            defaultValue={selectedCompany}
            value={selectedCompany}
            onChange={(item) => {
              handleSelectCompany(item);
            }}
            options={companies}
          />
        </div>
      </div>
      {selectedCompany && (
        <div className={cls.card}>
          <span className={cls.formDescription}>Select Integration Type:</span>
          <div>
            <Select
              isClearable
              placeholder="Integration Type"
              className={cls.select}
              defaultValue={pageContent}
              value={pageContent}
              onChange={(item) => {
                handleSelectIntegrationType(item);
              }}
              options={pageContentOptions}
            />
          </div>
        </div>
      )}
      {(pageContent.value === 1 || pageContent.value === 2) && (
        <TMSCard
          notify={notify}
          setActiveTms={setActiveTms}
          activeTms={activeTms}
          selectedCompany={selectedCompany}
          state={state}
          handleSelectCompany={handleSelectCompany}
          tmsType={tmsType}
          setTmsType={setTmsType}
          tmsExcelMode={tmsExcelMode}
          setTmsExcelMode={setTmsExcelMode}
          fetchTablesData={fetchTablesData}
        />
      )}
      {pageContent.value === 3 && (
        <LocalExcelCard
          notify={notify}
          selectedCompany={selectedCompany}
          state={state}
          handleSelectCompany={handleSelectCompany}
        />
      )}
      {pageContent.value === 4 && (
        <EmailCard
          notify={notify}
          selectedCompany={selectedCompany}
          DATA={state.DATA}
          handleSelectCompany={handleSelectCompany}
        />
      )}
      <>
        {loadTables ? (
          <div className={cls.loaderContainer}>
            <CircularProgress className={cls.loader} />
          </div>
        ) : (
          <>
            {((pageContent &&
              tmsExcelMode &&
              pageContent.label === 'TMS' &&
              tmsExcelMode.value) ||
              pageContent.label !== 'TMS') && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <SSLTable
                      tablesData={tablesData}
                      setTablesData={setTablesData}
                      pageContent={pageContent}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <MTOTable
                      tablesData={tablesData}
                      setTablesData={setTablesData}
                      pageContent={pageContent}
                    />
                  </Grid>

                  <Grid className={cls.cityContainer} item xs={12} lg={6}>
                    <SizeTable
                      selectedCompany={selectedCompany}
                      tablesData={tablesData}
                      setTablesData={setTablesData}
                      pageContent={pageContent}
                    />
                    <CityTable
                      selectedCompany={selectedCompany}
                      tableCities={tableCities}
                      setTableCities={setTableCities}
                      pageContent={pageContent}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <ColumnsTable
                      tablesData={tablesData}
                      setTablesData={setTablesData}
                      pageContent={pageContent}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <SeparationTable
                      selectedCompany={selectedCompany}
                      tableSeprations={tableSeprations}
                      setTableSeprations={setTableSeprations}
                      pageContent={pageContent}
                    />
                  </Grid>
                </Grid>
                {pageContent.value !== 1 && pageContent.value !== 2 && (
                  <Button
                    className={cls.saveBtn}
                    onClick={() => handleSaveExcelConfig()}>
                    save
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};
