import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CloseRounded } from '@material-ui/icons';
import styles from './styles';
import closeIcon from '../../assets/img/closeIcon.svg';
import { Modal, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { useOutsideAlerter } from '@/hocs';
import { Tooltip, Grid, TextField, Button } from '@material-ui/core';
import clsx from 'clsx';
import { TextareaAutosize } from '@mui/material';
import Select from 'react-select';
import { FormControl, Radio, RadioGroup } from '@material-ui/core';

export default ({
  Close,
  DATA,
  Label,
  vessel_source,
  vessel_id,
  column_name,
  column_value,
  terminalsList,
  sslsList,
  open,
  handleChangeFieldsData,
  terminal_id,
  date,
  restriction_id,
  shift_column,
  shift_value,
  shift_message,
  ssl_key,
  indexContainer,
  idx,
}) => {
  const selectOp = useSelector((state) => state.reducer);
  const setForm = (it, val) => setFormData({ ...form, [it]: val });
  // const selectedSSlNew = selectOp.ssl.find((item) => item.label === DATA);
  const [selectedTerminal, setSelectedTerminal] = useState('All');
  const [selectedSsl, setSelectedSsl] = useState([]);
  const [terminalsSelectOptions, setTerminalsSelectOptions] = useState([]);
  const [sslsSelectOptions, setSslsSelectOptions] = useState([]);
  const [state, setState] = useState({
    inputType: isDate(DATA) === true ? 'date' : 'text',
  });

  const [form, setFormData] = React.useState({
    size: null,
    ssl: null,
    inputData: column_value,
    date: new Date(),
    count: '',
    loc: null,
    containers: [],
    port: '',
    terminal: '',
    etaDate: new Date(),
    etdDate: new Date(),
    erdDate: new Date(),
    cotoffDate: new Date(),
  });
  useEffect(() => {
    if (terminalsList.length > 0) {
      const options = terminalsList.map((terminal, index) => ({
        label: terminal.name,
        slug: terminal.slug,
        id: terminal.id,
        value: index + 1,
      }));

      // Set portSelectOptions state
      setTerminalsSelectOptions(options);
      setSelectedTerminal(options.find((item) => item.slug === DATA));
    }
  }, [terminalsList]); // Dependencies array with portsList

  useEffect(() => {
    if (sslsList.length > 0) {
      const options = sslsList.map((ssl, index) => ({
        label: ssl.name_full,
        slug: ssl.name,
        id: ssl.id,
        value: index + 1,
      }));

      // Set portSelectOptions state
      setSslsSelectOptions(options);
      console.log(
        'item.slug === DATA',
        options.find((item) => item.slug === DATA)
      );

      setSelectedSsl(options.find((item) => item.slug === DATA));
    }
  }, [sslsList]); // Dependencies array with portsList
  console.log('sslsList', sslsList);

  const cls = styles();
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    Close();
  });
  const [message_ssl, setMessage_ssl] = React.useState(DATA);

  const saveMessage = (message_ssl) => {
    const shift_valued =
      shift_value === 'DUAL'
        ? 2
        : shift_value === 1 ||
          shift_value === 0 ||
          shift_value === true ||
          shift_value === false
        ? shift_value
        : null;
    const column_values =
      message_ssl === 'scrape'
        ? 'scrape'
        : message_ssl === null
        ? null
        : form.inputData;
    handleChangeFieldsData(
      vessel_source,
      vessel_id,
      column_name,
      column_values,
      idx
    );
    // console.log(
    //   'after Api Print Data',
    //   vessel_source,
    //   vessel_id,
    //   column_name,
    //   column_values,
    //   idx
    // );

    Close();
  };

  function isDate(data) {
    // Check if the input is a string or a number
    if (typeof data === 'string' || typeof data === 'number') {
      const date = new Date(data);
      console.log('isDate', !isNaN(date.getTime()));

      // Check if the input is a valid date string and not just a number
      if (!isNaN(date.getTime())) {
        // Return true only if the original input is a date string
        return typeof data === 'string' && !/^\d+$/.test(data);
      }
    }
    return false;
  }

  return (
    <>
      <div>
        <Modal open={open}>
          <div
            id="app_modal"
            className={cls.modal}
            // onClick={
            //   (e) => console.log('hI')
            //   // e.target.id === 'app_modal' && setShowContainersModal(false)
            // }
            style={{ width: '100%', height: '100%' }}>
            <Box className={cls.modalBox}>
              <div
                // onClick={() => setShowContainersModal(false)}
                className={cls.closeModal}>
                <img alt="close_icon" src={closeIcon} />
              </div>
              <h3 className={cls.modalTitle}>Own Chassis</h3>
              <div className={cls.containersTableContainer}>
                {/* {gatEditContainersContent()} */}
              </div>
              <button
                className={cls.updateContainersBtn}
                // onClick={() => setShowContainersModal(false)}
              >
                Close
              </button>
            </Box>
          </div>
        </Modal>
      </div>
      <div className={cls.popup}>
        <div ref={wrapperRef} className={cls.popupCard}>
          {Label === 'ETA' ||
          Label === 'ATA' ||
          Label === 'ETD' ||
          Label === 'ATD' ||
          Label === 'EPD' ||
          Label === 'ERD Refer' ||
          Label === 'ERD Dry' ||
          Label === 'Cutoff Refer' ||
          Label === 'Cutoff Hazard' ||
          Label === 'Cutoff Dry' ||
          Label === 'Elgd' ? (
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={state?.inputType}
              className={cls.popupRadioBtns}
              onChange={(e) => {
                setState((prev) => {
                  return {
                    ...prev,
                    inputType: e.target?.value,
                  };
                });
              }}>
              <FormControlLabel
                value={'date'}
                control={<Radio />}
                label={'Date'}
              />
              <FormControlLabel
                value={'text'}
                control={<Radio />}
                label={'Text'}
              />
            </RadioGroup>
          ) : (
            <h3></h3>
          )}
          <Grid item xs={12} sm={12} lg={12}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadgePopup}>{Label}</span>
              {Label === 'Terminal' ? (
                <Select
                  className={clsx(cls.select)}
                  value={selectedTerminal}
                  onChange={(item) => {
                    setForm('inputData', item.id);
                    setSelectedTerminal(item);
                  }}
                  options={terminalsSelectOptions}
                />
              ) : Label === 'Vessel' ||
                Label === 'Inbound Voyage' ||
                Label === 'Outbound Voyage' ? (
                <TextField
                  type="text"
                  key={'a'}
                  defaultValue={DATA}
                  // placeholder={Label}
                  onChange={(e) => {
                    setForm('inputData', e.target.value);
                    // setVesselValue(e.target.value);
                  }}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ min: 0 }}
                />
              ) : Label === 'Line' ? (
                <Select
                  placeholder="SSL"
                  className={clsx(cls.select)}
                  value={selectedSsl}
                  onChange={(item) => {
                    setForm('inputData', item.id);
                    setSelectedSsl(item);
                  }}
                  options={sslsSelectOptions}
                />
              ) : Label === 'ETA' ||
                Label === 'ATA' ||
                Label === 'ETD' ||
                Label === 'ATD' ||
                Label === 'EPD' ||
                Label === 'ERD Refer' ||
                Label === 'ERD Dry' ||
                Label === 'Cutoff Refer' ||
                Label === 'Cutoff Hazard' ||
                Label === 'Cutoff Dry' ||
                Label === 'Elgd' ? (
                <>
                  {state.inputType === 'date' ? (
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      id="datetime-local"
                      type="datetime-local"
                      defaultValue={DATA}
                      className={clsx(cls.date)}
                      onChange={(item) => {
                        setForm('inputData', item.target.value);
                      }}
                    />
                  ) : (
                    <TextField
                      type="text"
                      key={'a'}
                      defaultValue={isDate(DATA) === true ? '' : DATA}
                      // placeholder={Label}
                      onChange={(e) => {
                        setForm('inputData', e.target.value);
                        // setVesselValue(e.target.value);
                      }}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ min: 0 }}
                    />
                  )}
                </>
              ) : (
                ''
              )}{' '}
            </div>
          </Grid>
          <span className={cls.msgPopupBtns}>
            <Button
              className={clsx(cls.saveBtn)}
              onClick={(e) => {
                saveMessage('save');
              }}>
              Save
            </Button>
            <Button
              className={clsx(cls.saveBtn)}
              onClick={(e) => {
                saveMessage(null);
              }}>
              Delete
            </Button>
            <Button
              className={clsx(cls.saveBtn)}
              onClick={(e) => {
                saveMessage('scrape');
              }}>
              Scrape
            </Button>
          </span>
        </div>
      </div>
    </>
  );
};
