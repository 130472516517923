import React, { useEffect, useState } from 'react';
import { Modal, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import styles from './styles';
import {
  disableAdminInventoryContainer,
  enableAdminInventoryContainer,
} from '@/actions';
import { useNotify } from 'react-admin';
import closeIcon from '../../assets/img/closeIcon.svg';
import { ListItemIcon } from '@material-ui/core';
import { PersonRounded } from '@material-ui/icons';

export default ({
  DATA = [],
  open,
  mode,
  whatsappPb,
  selectedRow,
  aiAnalysisRes,
  setShowContainersModal,
  // getInventoryList,
}) => {
  const cls = styles();
  const notify = useNotify();
  const [containers, setContainers] = useState(selectedRow.containers);

  const updateContainers = (index, name, value) => {
    setContainers((prev) => {
      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const handleEnableCongtainer = (containerId, index) => {
    enableAdminInventoryContainer(containerId).then((response) => {
      if (response.success) {
        notify('container updated');
        // getInventoryList();
        updateContainers(index, 'status', 1);
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleDisableContainer = (containerId, index) => {
    disableAdminInventoryContainer(containerId).then((response) => {
      if (response.success) {
        notify('container updated');
        // getInventoryList();
        updateContainers(index, 'status', 0);
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleChangeContainerStatus = (value, containerId, index) => {
    if (value === true) {
      handleEnableCongtainer(containerId, index);
    } else {
      handleDisableContainer(containerId, index);
    }
  };
  // const originalDate = "2024-04-20T04:31:52.000000Z";
  const formatUpdatedAtDate = (dateString) => {
    if (dateString === 'please contact your line') {
      return dateString;
    } else if (dateString === 'Invalid date') {
      return 'N/A';
    } else {
      const date = new Date(dateString);
      const formattedDate =
        new Date(date).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit',
        }) +
        ' ' +
        new Date(date).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
      return formattedDate;
    }
  };
  console.log('aiAnalysisRes', aiAnalysisRes);
  console.log('aiAnalysisRes', aiAnalysisRes);
  return (
    <div>
      <Modal open={open}>
        <div
          id="app_modal"
          className={cls.modal}
          onClick={(e) =>
            e.target.id === 'app_modal' && setShowContainersModal(false)
          }
          style={{ width: '100%', height: '100%' }}>
          <Box
            className={mode === 'exports' ? cls.modalBoxExport : cls.modalBox}>
            <div
              onClick={() => setShowContainersModal(false)}
              className={cls.closeModal}>
              <img alt="close_icon" src={closeIcon} />
            </div>
            {mode == 'containers' ? (
              <>
                <h3 className={cls.modalTitle}>Container Availability</h3>
                <div className={cls.containersTableContainer}>
                  <table className={cls.containerTable}>
                    <thead>
                      <tr>
                        <th style={{ flex: '0.3' }}>#</th>
                        <th style={{ flex: '1' }}>Container#</th>
                        <th style={{ flex: '1' }}>Chassis#</th>
                        <th style={{ flex: '1' }}>Type</th>
                        {/* <th style={{ flex: '0.3' }}>Status</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {DATA?.map((container, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ flex: '0.3' }}>{index + 1}</td>
                            <td style={{ flex: '1' }}>
                              <span>
                                {container.name ? container.name : 'N/A'}
                              </span>
                            </td>
                            <td style={{ flex: '1' }}>
                              <span>
                                {container.chases ? container.chases : 'N/A'}
                              </span>
                            </td>
                            <td style={{ flex: '1' }}>
                              <span>
                                {container.own_chases == '0'
                                  ? 'Pool'
                                  : container.own_chases == '1'
                                  ? 'Own'
                                  : ''}
                              </span>
                            </td>
                            {/* <td style={{ flex: '0.3' }}>
                          {container.status !== null && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    container.status === 1 ? true : false
                                  }
                                  className={cls.checkbox}
                                  onChange={(e) =>
                                    handleChangeContainerStatus(
                                      e.target.checked,
                                      container.id,
                                      index
                                    )
                                  }
                                />
                              }
                            />
                          )}
                        </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : mode == 'whatsapp' ? (
              <>
                <h3 className={cls.modalTitle}>WhatsApp Message</h3>
                <span className={cls.wpMessageContent}>
                  <ListItemIcon className={cls.iconPerson}>
                    <PersonRounded width="60px" />
                  </ListItemIcon>
                  <span className={cls.fromUserDetail}>
                    <span className={cls.whatsappMessage}>
                      {aiAnalysisRes.from_name}
                    </span>
                    <span className={cls.whatsappMessage}>
                      {aiAnalysisRes.from}
                    </span>
                  </span>
                </span>

                <div className={cls.WhatsappMessageDiv}>
                  <span className={cls.wpMessageContent}>
                    <span className={cls.whatsappMessage}>
                      {aiAnalysisRes.message}
                    </span>
                  </span>
                  <span className={cls.whatsappMessage}>
                    AI Interpreted Message :{' '}
                    {aiAnalysisRes.after_ai_parts[0].ai_message}
                  </span>

                  <span
                    style={{ fontSize: '12px', textAlign: 'right' }}
                    className={cls.whatsappMessage}>
                    {formatUpdatedAtDate(`${aiAnalysisRes.created_at}`)}
                  </span>
                </div>
              </>
            ) : mode === 'exports' ? (
              <>
                <h3 className={cls.modalTitle}>Booking Availability</h3>
                <div className={cls.containersTableContainer}>
                  <table className={cls.containerTable}>
                    <thead>
                      <tr>
                        <th style={{ flex: '0.2' }}>#</th>
                        <th style={{ flex: '1' }}>Booking#</th>
                        <th style={{ flex: '1' }}>BOL</th>
                        <th style={{ flex: '0.6' }}>Number</th>
                        <th style={{ flex: '1' }}>ERD</th>
                        <th style={{ flex: '1' }}>Cutoff</th>
                        <th style={{ flex: '1' }}>MTO</th>
                        <th style={{ flex: '1' }}>Term Apt</th>
                        {/* <th style={{ flex: '0.3' }}>Status</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {DATA?.map((booking, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ flex: '0.2' }}>{index + 1}</td>
                            <td style={{ flex: '1' }}>
                              <span>{booking.name ? booking.name : 'N/A'}</span>
                            </td>
                            <td style={{ flex: '1' }}>
                              <span>
                                {booking.additional?.bill_of_lading
                                  ? booking.additional?.bill_of_lading
                                  : 'N/A'}
                              </span>
                            </td>
                            <td style={{ flex: '0.6' }}>
                              <span>
                                {booking.count ? booking.count : 'N/A'}
                              </span>
                            </td>
                            <td style={{ flex: '1' }}>
                              <span>
                                {booking.additional?.erd
                                  ? formatUpdatedAtDate(
                                      `${booking.additional?.erd}`
                                    )
                                  : 'N/A'}
                              </span>
                            </td>
                            <td style={{ flex: '1' }}>
                              <span>
                                {booking.additional?.cutoff
                                  ? formatUpdatedAtDate(
                                      `${booking.additional?.cutoff}`
                                    )
                                  : 'N/A'}
                              </span>
                            </td>
                            <td style={{ flex: '1' }}>
                              <span>
                                {booking.additional?.mto
                                  ? booking.additional?.mto
                                  : 'N/A'}
                              </span>
                            </td>
                            <td style={{ flex: '1' }}>
                              <span>
                                {booking.additional?.appointment_date
                                  ? formatUpdatedAtDate(
                                      `${booking.additional?.appointment_date}`
                                    )
                                  : 'N/A'}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              ''
            )}
            <button
              className={cls.updateContainersBtn}
              onClick={() => setShowContainersModal(false)}>
              Close
            </button>
          </Box>
        </div>
      </Modal>
    </div>
  );
};
